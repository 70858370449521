<template>
  <div class="py-6 grey lighten-6">
    <!-- Y67T Filters -->
    <v-row class="pb-3">
      <v-col class="pt-4">
        <span class="filterLabel">Filter by:</span>
        <span class="pl-3">
          <ListViewFilter
            ref="statusFilter"
            label="Status"
            type="checkbox"
            :filter-options="statusFilters"
            @updatefilter="updateStatusFilter"
            @menuOpened="sendFilterAnalytics"
        /></span>
        <span class="pl-3">
          <ListViewFilter
            ref="receivedDateFilter"
            label="Received date"
            type="checkbox"
            :archive-type="archiveType"
            :filter-options="yearFilters"
            :show-default-filter="selectedReceivedDate"
            @updatefilter="updateYearFilter"
            @menuOpened="sendFilterAnalytics"
        /></span>
        <ClearFilters @click="clearAllFilters()" v-if="isFiltered" />
      </v-col>
      <v-col cols="auto">
        <AdsButton
          v-if="!isOesSupport && isERNAdmin"
          ref="downloadApplications"
          class="mr-0"
          :disabled="
            !Object.values(selectedIds).filter((v) => v === true).length
          "
          :aria-label="downloadBtnAriaLabel"
          icon="mdi-download-outline"
          button-text="Download application(s)"
          @click="openDownloadModal"
          data-testid="y67tPrimaryDownloadButton"
        />
      </v-col>
    </v-row>

    <v-card>
      <AdsDataTable
        :headers="headers"
        :items="applications"
        search-label="Find student"
        :search="internalSearch"
        sort-by="dateReceived"
        sort-desc
        must-sort
        :items-per-page="pagination.itemsPerPage"
        :footer-props="{
          'items-per-page-options': [10, 15, 50, -1]
        }"
        @click:row="openApplication"
        @current-items="setFilteredIds"
        :custom-filter="filterApplications"
        @update:page="updatePagination"
        @pagination="totalResults"
        :page.sync="currentPage"
        data-testid="y67tPrimaryEoiList"
      >
        <template #no-data>
          <NoResultsFound
            :archive-type="archiveType"
            :filtered="isFiltered || Boolean(internalSearch)"
          />
        </template>
        <template #top>
          <v-row class="row-line">
            <v-col>
              <v-text-field
                class="searchInput"
                v-model="internalSearch"
                prepend-inner-icon="mdi-magnify"
                label="Find student"
                single-line
                hide-details
                clearable
              />
            </v-col>
            <v-col cols="auto" class="results-text">
              <span>
                {{ resultsText(pagination.itemsLength) }}
                <a
                  tabindex="0"
                  class="select-all"
                  v-if="selectCheckCount"
                  @keyup.enter="selectEveryIdToggle(selectedItemsCount)"
                  @click="selectEveryIdToggle(selectedItemsCount)"
                >
                  {{
                    selectedItemsCount === pagination.itemsLength
                      ? 'Clear selection'
                      : `Select all ${pagination.itemsLength}`
                  }}
                </a>
              </span>
            </v-col>
          </v-row>
        </template>
        <template #[`header.selectAll`]>
          <v-checkbox
            :value="allSelected"
            hide-details
            aria-label="Add all applications to PDF download."
            class="checkbox mx-3 pa-0"
            @click.prevent="toggleSelectAll"
            data-testid="y67tToggleSelectAll"
          />
        </template>
        <template #[`item.selectAll`]="{ item }">
          <OesCheckbox
            v-model="selectedIds[item.eoiID]"
            hide-details
            :aria-label="`Add application for ${item.name} to PDF download.`"
            class="ma-0 pa-0"
            @click.stop
            data-testid="y67tSelectApplicationCheckbox"
          />
        </template>
        <template #[`item.dateReceived`]="{ item }">
          {{ item.dateReceived | moment('DD MMM YYYY') }}
        </template>
        <template #[`item.name`]="{ item }">
          <a
            :href="`/#/${getOpenApplicationHref(item)}`"
            :aria-label="`${item.name}. Open application details.`"
            :ref="`itemfocusRef${item.index}`"
          >
            {{ item.name }}
          </a>
        </template>
        <template #[`item.localHighSchool`]="{ item }">
          {{ abbreviateSchoolName(item.localHighSchool) }}
        </template>
        <template #[`item.ooaHighSchoolsCount`]="{ item }">
          {{
            item.ooaHighSchoolsCount !== null ? item.ooaHighSchoolsCount : ''
          }}
        </template>
        <template #[`item.applicationStatus`]="{ item }">
          <Chip
            small
            v-bind="getStatusChipDetails(item)"
            class="ApplicationStatusChips"
          />
        </template>
      </AdsDataTable>
    </v-card>

    <Y67TDownloadDocuments
      v-if="showDownloadModal"
      :applications="selectedIdsForDownload"
      :school-code="schoolCode"
      :application-type="APPLICATION_TYPE.Y67T"
      @close="closeDownloadModal"
    />
  </div>
</template>

<script>
import Y67TDownloadDocuments from '@/components/applicationList/Y67TDownloadDocuments.vue'
import ListViewFilter from '@/components/app/ListViewFilter.vue'
import NoResultsFound from '@/components/app/NoResultsFound.vue'
import ClearFilters from './ClearFilters.vue'
import { mapGetters } from 'vuex'
import OesCheckbox from '@/components/app/OesCheckbox'
import { searchFilter, searchFilterForStringOnly } from '@/helpers/searchHelper'
import { Chip, AdsButton, AdsDataTable } from '@nswdoe/doe-ui-core'
import _ from 'lodash'
import {
  APPLICATION_TYPE,
  STREAM,
  Y67T_STATUS,
  Y67T_STATUS_CHIPS,
  USER_GROUP,
  ARCHIVE_TYPES
} from '@/constants'

const headers = [
  {
    value: 'selectAll',
    align: 'center',
    class: 'select-all',
    sortable: false
  },
  {
    text: 'Received',
    value: 'dateReceived',
    class: 'date-received',
    align: 'start'
  },
  {
    text: 'SRN',
    value: 'SRN',
    class: 'srn',
    align: 'start'
  },
  {
    text: 'Student name',
    value: 'name',
    class: 'name',
    align: 'start'
  },
  {
    text: 'Designated high school',
    value: 'localHighSchool',
    class: 'designated-high-school',
    align: 'start'
  },
  {
    text: 'No. of OoA choices',
    value: 'ooaHighSchoolsCount',
    class: 'no-ooa-choices',
    align: 'center'
  },
  {
    text: 'Status',
    value: 'applicationStatus',
    class: 'status',
    align: 'start',
    width: '175'
  }
]

const currentYear = new Date().getFullYear().toString()

export default {
  name: 'Y67TPrimaryDataTable',
  components: {
    Y67TDownloadDocuments,
    Chip,
    ListViewFilter,
    OesCheckbox,
    AdsButton,
    AdsDataTable,
    ClearFilters,
    NoResultsFound
  },
  data() {
    return {
      headers,
      selectedIds: {},
      showDownloadModal: false,
      selectedStatus: [],
      APPLICATION_TYPE,
      selectedReceivedDate: [currentYear],
      selectedItemsCount: 0,
      currentPage: 1,
      selectCheckCount: false,
      idPerPage: [],
      filteredIds: 0,
      pagination: { itemsLength: 0, itemsPerPage: 50 },
      tabName: 'PRIMARYEOILIST',
      archiveType: ARCHIVE_TYPES.Y67T_EOIS.type
    }
  },
  activated() {
    // refresh the list in case the status changed in the details window
    this.$store.dispatch('getY67TApplications')
  },
  watch: {
    schoolCode() {
      // clear selected applications for download pdf on change of school switcher
      this.selectedIds = {}
      this.idPerPage = []
      // clear selected filters on change of school switcher
      this.selectedStatus = []
      this.selectedReceivedDate = [currentYear]
    },
    selectedItemsCount() {
      const checkedOnes = _.pickBy(this.selectedIds, (value) => {
        return value
      })

      const currentPageIds = this.visibleEoiIds.reduce((selected, appId) => {
        selected[appId] = true
        return selected
      }, {})

      this.filteredIds = Object.keys(checkedOnes).filter((i) => {
        return Object.keys(currentPageIds).includes(i)
      })

      this.idPerPage[this.currentPage - 1] =
        this.filteredIds.length === this.pagination.itemsPerPage ||
        this.filteredIds.length === this.pagination.itemsLength ||
        this.filteredIds.length ===
          this.pagination.pageStop - this.pagination.pageStart
          ? true
          : false

      this.selectCheckCount = this.idPerPage.includes(true)
    }
  },
  methods: {
    totalResults(pagination) {
      this.pagination = pagination
    },
    resultsText(itemsLength) {
      this.selectedItemsCount = Object.values(this.selectedIds).filter(
        (value) => value === true
      ).length
      return this.selectCheckCount
        ? this.selectedItemsCount + ' of ' + itemsLength + ' selected.'
        : itemsLength === 1
        ? itemsLength + ' result'
        : itemsLength + ' results'
    },
    abbreviateSchoolName(item) {
      if (item.includes('Secondary College')) {
        return item.replace('Secondary College', 'SC')
      }
      if (item.includes('High School')) {
        return item.replace('High School', 'HS')
      }
      return item ? item : ''
    },
    getOpenApplicationHref(item) {
      return `application/${item.schoolCode}/${item.eoiID}`
    },
    updatePagination() {
      document.querySelector('tr td:first-child .v-input input').focus()
    },
    openApplication(item) {
      this.$router.push(`/${this.getOpenApplicationHref(item)}`)
    },
    setFilteredIds(items) {
      const ids = items.map((item) => item.eoiID)
      this.$store.dispatch('set', ['filteredApplicationIds', ids])
    },
    getStatusChipDetails(item) {
      return Y67T_STATUS_CHIPS[item.applicationStatus]
    },
    toggleSelectAll() {
      if (!this.allSelected) {
        const oldIds = this.selectedIds
        const newIds = this.visibleEoiIds.reduce((selected, appId) => {
          selected[appId] = true
          return selected
        }, {})
        this.selectedIds = { ...oldIds, ...newIds }
      } else {
        const removeIds = this.visibleEoiIds.reduce((selected, appId) => {
          selected[appId] = true
          return selected
        }, {})
        let oldIds = this.selectedIds
        let arrayRem = Object.keys(removeIds)
        this.selectedIds = Object.keys(oldIds)
          .filter((key) => !arrayRem.includes(key))
          .reduce((obj, key) => {
            return Object.assign(obj, {
              [key]: oldIds[key]
            })
          }, {})
      }
    },
    selectEveryIdToggle(item) {
      const ids = this.applications.map((item) => item.eoiID)
      if (item !== this.pagination.itemsLength || !this.allSelected) {
        this.idPerPage = Array(this.pagination.pageCount).fill(true)
        this.selectedIds = ids.reduce((selected, appId) => {
          selected[appId] = true
          return selected
        }, {})
      } else {
        this.idPerPage = []
        this.selectedIds = {}
      }
    },
    openDownloadModal() {
      this.showDownloadModal = true
      this.$gtm.trackEvent({
        category: 'pdfApplication',
        action: 'Download',
        label: 'Primary list view',
        value: Object.keys(this.selectedIds).length,
        stream: 'Y67T'
      })
    },
    closeDownloadModal() {
      this.showDownloadModal = false
      const downloadApplications = this.$refs.downloadApplications
        ? this.$refs.downloadApplications.$el
        : null
      if (downloadApplications) {
        setTimeout(() => downloadApplications.focus(), 0)
      }
    },
    updateStatusFilter(selectedOptions) {
      this.selectedStatus = selectedOptions
      // clear selected applications for download pdf on change of status filter
      this.selectedIds = {}
      this.idPerPage = []
      this.currentPage = 1
    },
    filterApplications(value, search, item) {
      this.currentPage = 1
      return searchFilterForStringOnly(value, search, item)
    },
    sendFilterAnalytics(opened) {
      // send analytics data when each filter is closed
      if (!opened) {
        const statusData = this.selectedStatus.length
          ? JSON.stringify(this.sortStatuses(this.selectedStatus))
          : null

        const yearData = this.selectedReceivedDate.length
          ? JSON.stringify(this.selectedReceivedDate)
          : null

        const consolidatedData = `${statusData},${yearData}`.replaceAll('"', '')
        this.$gtm.trackEvent({
          category: 'applicationFilter',
          action: STREAM.Y67T_PRIMARY,
          label: consolidatedData
        })
      }
    },
    sortStatuses(statuses) {
      const sortByStatus = Object.values(Y67T_STATUS)
      return statuses.sort(function (a, b) {
        return sortByStatus.indexOf(a) - sortByStatus.indexOf(b)
      })
    },
    updateYearFilter(selectedOptions) {
      this.selectedReceivedDate = selectedOptions
      this.$emit('receivedDatefilter', selectedOptions)
      // clear selected applications for download pdf on change of status filter
      this.selectedIds = {}
      this.idPerPage = []
      this.currentPage = 1
    },
    clearAllFilters() {
      // clear all filtered values
      this.$store.commit('setY67tSearchFilter', {
        tabName: this.tabName,
        value: ''
      })
      this.$refs.statusFilter.clearFilter()
      this.$refs.receivedDateFilter.clearFilter()
    }
  },
  computed: {
    ...mapGetters({
      schoolCode: 'selectedSchoolCode',
      y67tApplications: 'y67tApplications',
      y67tSearchFilter: 'y67tSearchFilter',
      filteredApplicationIds: 'filteredApplicationIds'
    }),
    isOesSupport() {
      return this.$store.state.userGroup === USER_GROUP.OES_SUPPORT
    },
    isERNAdmin() {
      return this.$store.state.userGroup === USER_GROUP.ERN_ADMIN
    },
    internalSearch: {
      get() {
        return this.y67tSearchFilter(this.tabName) || ''
      },
      set(value) {
        this.selectedIds = {}
        this.idPerPage = []
        this.$store.commit('setY67tSearchFilter', {
          tabName: this.tabName,
          value: value
        })
      }
    },
    isFiltered() {
      return (
        Boolean(this.selectedStatus.length) ||
        Boolean(this.selectedReceivedDate.length)
      )
    },
    totalApplicationsDisplayed() {
      return this.applications.length
    },
    visibleEoiIds() {
      return this.filteredApplicationIds
    },
    allSelected: {
      get() {
        const allIds = [...this.visibleEoiIds]
        return (
          Object.keys(this.selectedIds).length &&
          allIds.every((id) => this.selectedIds[id] === true)
        )
      },
      set() {}
    },
    selectedIdsForDownload() {
      const keys = Object.keys(this.selectedIds)
      let selectedIds = []
      for (let k of keys) {
        if (this.selectedIds[k] === true) {
          const app = this.y67tApplications(this.schoolCode).filter(
            (a) => a.eoiID === k
          )
          if (app.length > 0) {
            selectedIds.push({
              applicationID: app[0].eoiID,
              studentFirstName: app[0].student.firstName,
              studentFamilyName: app[0].student.familyName,
              supportingDocuments: app[0].supportingDocuments,
              isDigitizeEOIConsiderationDocUploaded:
                app[0].isDigitizeEOIConsiderationDocUploaded
            })
          }
        }
      }
      return selectedIds
    },
    applications() {
      const applications = this.y67tApplications(this.schoolCode).filter(
        (a) => a?.preferenceNo !== -3
      )
      const applicationsMapped = applications.map((a) => {
        // flatten application data
        const app = { ...a, ...a.application }
        app.SRN = app.student?.SRN ? app.student.SRN : ''
        app.localHighSchool = app.localHighSchool?.schoolName
          ? app.localHighSchool.schoolName
          : ''

        return app
      })
      return applicationsMapped.filter((a) => {
        let Name =
            (this.y67tSearchFilter(this.tabName) || '') !== ''
              ? searchFilter(
                  [
                    a.application.student.familyName,
                    a.application.student.firstName
                  ],
                  this.y67tSearchFilter(this.tabName)
                )
              : true,
          Status = this.selectedStatus.length
            ? this.selectedStatus.includes(a.applicationStatus)
            : a.applicationStatus !== 'AutoDraft',
          ReceivedDate = this.selectedReceivedDate.length
            ? this.selectedReceivedDate.includes(
                new Date(a.dateReceived).getFullYear().toString()
              )
            : true
        return Name && Status && ReceivedDate
      })
    },
    statusFilters() {
      const applications = this.y67tApplications(this.schoolCode)
      let applicationStatuses = [
        ...new Set(applications.map((app) => app.applicationStatus))
      ]
      const index = applicationStatuses.indexOf('AutoDraft')
      if (index > -1) {
        applicationStatuses.splice(index, 1)
      }
      return this.sortStatuses(applicationStatuses)
    },
    yearFilters() {
      const applications = this.y67tApplications(this.schoolCode)
      const applicationReceivedYear = [
        ...new Set(
          applications.map((app) =>
            new Date(app.dateReceived).getFullYear().toString()
          )
        )
      ]
      // push cuurent year in the filter if no applications for the current year are prest in the list
      if (!applicationReceivedYear.includes(currentYear)) {
        applicationReceivedYear.push(currentYear)
      }
      return applicationReceivedYear.reverse().sort(function (a, b) {
        return b - a
      })
    },
    downloadBtnAriaLabel() {
      return Object.keys(this.selectedIds).length > 1
        ? 'Download applications'
        : 'Download application'
    }
  },
  mounted() {
    let value = localStorage.getItem('y67tSearch')
    if (value) {
      this.$store.commit('setY67tSearchFilter', {
        tabName: this.tabName,
        value: value
      })
      localStorage.removeItem('y67tSearch')
    }
  },
  beforeDestroy() {
    this.$store.commit('setY67tSearchFilter', { tabName: 'RESET' })
  }
}
</script>

<style scoped lang="scss">
/**
 * TODO: Attention VUE 3 developers
 * Delete v-input--selection-controls class styles as part of Vue 3 / ADS 3 upgrade.
 */
::v-deep .v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.select-all {
  text-decoration: underline;
}

::v-deep .ApplicationStatusChips {
  width: 94%;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  padding: 0px 2px;
}

.v-card__actions {
  padding: 1rem 3rem;
  background: $grey-6;
}

::v-deep .v-ripple__container {
  width: 0 !important;
}

::v-deep .v-input.searchTxtField {
  background-color: transparent;
  input {
    border: 0;
    outline: none;
    background-color: transparent;
  }
  input::placeholder {
    color: $color-placeholder;
  }
  input:-ms-input-placeholder,
  input::-ms-input-placeholder {
    color: $color-placeholder;
  }
  //overridding clear button styles after updating to vuetify 3.2.8
  .v-input__icon--clear button {
    padding: 0;
    border: none;
  }
  .v-input__icon--clear .theme--light.v-icon:before {
    color: white;
    background-color: $color-text-light;
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
    padding: 2px;
  }
  .v-input__icon.v-input__icon--append .theme--light.v-icon {
    color: $color-secondary;
  }
}
.alert {
  color: $color-red;
}
// data table
::v-deep .v-data-table {
  padding: 0;
  border: 0;
  table {
    table-layout: fixed;
    .v-data-table__empty-wrapper {
      background-color: white !important; // Prevents hover highlight on "No data available" row
      td {
        padding: 62px 0 !important; // Adds extra tall row height to "No data available" row
        color: $ads-dark-60;
      }
    }
  }
  thead.v-data-table-header {
    th[scope='col'] {
      color: $color-primary;
      font-size: 16px;
      font-weight: normal;
      padding: 20px 8px;
      vertical-align: top;
      .v-data-table-header__icon {
        color: $color-primary-lighten-1;
        margin-left: 3px;
      }
      &.active {
        font-weight: bold;
        color: $color-primary;
        .v-data-table-header__icon {
          color: $color-primary;
        }
      }

      //pending applications
      &.date-received {
        width: 110px;
      }
      &.name {
        overflow-wrap: normal;
        word-wrap: normal;
        width: 100px;
      }
      &.srn {
        width: 110px;
      }
      &.status {
        width: 150px;
      }
      &.action {
        width: 82px;
        padding-right: 20px;
      }

      //ooa applications
      &.select-all {
        width: 60px;
      }
    }
  }

  div.v-data-table__wrapper table tbody {
    tr:not(:last-child) td {
      border-bottom: 1px solid white;
      padding: 25px 8px;
    }
    tr:last-child td {
      border-bottom: 1px solid white;
      padding: 10px;
    }
    td {
      font-size: 14px;
      // overriding vuetify's data table styling
      border-bottom: 0 !important;
    }
  }
}

@include desktop {
  .ApplicationList {
    padding: 0 3rem;
    min-height: 100%;
  }

  ::v-deep .v-data-table {
    td {
      cursor: default;
    }

    &.pending thead.v-data-table-header th.sortable.date-received {
      padding-left: 20px;
    }

    thead.v-data-table-header th.sortable {
      padding: 20px 10px;
      &.date-received {
        width: 122px;
      }
      &.name {
        overflow-wrap: normal;
        word-wrap: normal;
        width: auto;
        min-width: 100px;
      }
      &.status {
        width: 150px;
      }
      &.action {
        padding-right: 20px;
        width: 102px;
      }
    }

    div.v-data-table__wrapper table tbody {
      tr:not(:last-child) td {
        padding: 20px 10px;
      }
      tr:last-child td {
        padding: 20px 10px;
      }
      tr td:first-child {
        padding-left: 20px;
      }
      tr td.status-processed {
        padding-right: 20px;
      }
    }
    .v-btn--icon.v-size--default {
      height: 32px;
      width: 32px;
    }
  }
}
#app.v-application
  button.v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
  opacity: 0;
}
body
  button:not(
    .v-expansion-panel-header,
    .no-styling,
    .v-btn--fab,
    .v-icon,
    .v-picker button,
    .v-btn--disabled,
    .stepper button
  ),
body input[type='button'] {
  margin: 0px;
}
.loadMoreBar {
  text-align: center;
  background-color: $grey-6;
  padding: 1rem;
}
.loadMoreCount {
  position: absolute;
  right: 1rem;
  line-height: 45px;
}
.filterLabel {
  color: $color-placeholder;
}
.chip-label {
  min-width: 90px;
  font-weight: bold;
}
.search-results-number {
  float: right;
  white-space: nowrap;
}
.data-table-header {
  align: right;
}
.row-line {
  border-bottom: 1px solid #e0e0e0;
  margin: 0px 5px 10px 0px;
  padding: 0px;
  width: 100%;
}
.results-text {
  margin: 25px 30px 0 0 !important;
}

::v-deep .mdi-magnify {
  margin-top: 3px !important;
}
::v-deep .v-data-table.dataTable .searchInput {
  padding: 16px 20px !important;
}
::v-deep
  .dataTable
  table
  thead:not(.v-data-table-header-mobile)
  th:first-child {
  padding-left: 10px !important;
}
::v-deep
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ) {
  background-color: $ads-dark-60 !important;
  color: $ads-white !important;
  border: 3px solid transparent;
  .v-icon {
    color: $ads-white !important;
  }
}

.checkbox {
  margin-top: 0.65rem !important;
}
</style>
