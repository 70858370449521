<template>
  <component
    :is="card ? 'v-card' : 'div'"
    class="school-finder"
    :class="{ 'school-finder--card': card }"
  >
    <div class="school-finder__header">
      <!--  TITLE  -->
      <label class="school-finder__title" for="school-finder">
        {{ title }}
      </label>
      <!--  MORE INFO BUTTON -->
      <AdsButton
        v-if="showMoreInfoButton"
        class="school-finder__more-info"
        button-text="More info"
        icon="mdi-help-circle-outline"
        tertiary
        @click="handleMoreInfo"
      />
    </div>
    <!--  INFO TEXT  -->
    <p class="school-finder__description">
      <slot name="description">
        Search for a school and select
        <strong>“Add school”</strong> to add them to their list of nominated
        schools below. Use the arrows to arrange their nominated schools in
        order of preference
      </slot>
    </p>
    <!--  SEARCH  -->
    <div class="school-finder__search-wrapper">
      <SchoolFinder
        id="school-finder"
        ref="schoolFinder"
        class="school-finder__search"
        placeholder="Search for a school"
        :search-input.sync="schoolSearchInput"
        aria-describedby="school-finder-error-message"
        :outlined="false"
        :append-icon="errorIcon"
        :aria-invalid="!isValid"
        :error="!isValid"
        :hide-details="true"
        :clearable="false"
        :menu-props="{ maxHeight: 250 }"
        :min-length-for-search="minLengthForSearch"
        :suggestions-callback="getSchoolSuggestions"
        item-text="displayName"
        @update:search-input="handleSearchInput"
        @update:school="handleSchoolSelected"
      />
      <AdsButton
        ref="schoolFinderButton"
        class="btn-search school-finder__search-button"
        button-text="Add School"
        icon="mdi-plus-circle-outline"
        :disabled="isSchoolFinderButtonDisabled"
        x-large
        @click="handleAddSchool"
      />
    </div>
    <!--  ERROR MESSAGES  -->
    <div id="school-finder-error-message" role="alert">
      <v-expand-transition>
        <template v-if="message">
          <p v-if="typeof message === 'string'" class="error--text">
            {{ message }}
          </p>
          <ErrorBox
            v-else-if="message.heading || message.body"
            :class="message.messageClass"
            :heading="message.heading"
            :include-action-button="showMoreInfoButton && !!message.moreInfo"
            @click="message.moreInfo ? message.moreInfo() : null"
          >
            <span v-html="message.body" />
          </ErrorBox>
        </template>
      </v-expand-transition>
    </div>
    <!--  SCHOOL LIST  -->
    <v-expand-transition>
      <div v-if="showPreferenceList" class="nominated-schools">
        <PreferenceList
          :items="internalNominatedSchools"
          item-text="schoolName"
          item-key="schoolCode"
          :defer-remove="true"
          @update:items="setSelectedSchools"
          @moveUp="handleMoveUp"
          @remove="handleRemove"
        />
      </div>
    </v-expand-transition>
    <div role="alert" aria-live="assertive" class="sr-only">
      {{ screenReaderText }}
    </div>
  </component>
</template>

<script>
import { mapGetters } from 'vuex'
import utils from '@/mixins/utils'
import SchoolFinder from '@/components/form/OesSchoolFinder'
import PreferenceList from '@/components/application/y67t/paperEoI/Form/PreferenceList'
import ErrorBox from '@/components/application/y67t/paperEoI/Form/ErrorBox'
import { AdsButton } from '@nswdoe/doe-ui-core'
import { CATCHMENT_LEVEL, SCHOOL_SELECTIVENESS, SCHOOL_CAPS } from '@/constants'

const MESSAGE_TYPES = {
  ERROR: 'error',
  WARNING: 'warning'
}

const SPECIALITY = {
  JUNIOR: 'Junior'
}

const EVENTS = {
  UPDATE_SCHOOLS: 'update:nominatedSchools',
  MORE_INFO: 'moreInfo',
  MORE_INFO_SPEC: 'moreInfo:specialist'
}

const SCHOOL_EXCEPTION_SCENARIOS = {
  OVER_CAP: {
    refDataKey: 'overCapacity',
    type: MESSAGE_TYPES.WARNING
  },
  SSP: {
    refDataKey: 'ssp',
    type: MESSAGE_TYPES.ERROR
  },
  FULL_SEL: {
    refDataKey: 'fullySelective',
    type: MESSAGE_TYPES.ERROR
  },
  PARTIAL_SEL: {
    refDataKey: 'partiallySelective',
    type: MESSAGE_TYPES.WARNING
  },
  SUPP_CLASS: {
    refDataKey: 'supportClasses',
    type: MESSAGE_TYPES.WARNING
  },
  SPECIALIST: {
    refDataKey: 'specialist',
    type: MESSAGE_TYPES.WARNING
  },
  LOCAL_SINGLE: {
    refDataKey: 'local',
    type: MESSAGE_TYPES.ERROR
  },
  LOCAL_MULTIPLE: {
    refDataKey: 'localMultiple',
    type: MESSAGE_TYPES.ERROR
  }
}

const MAX_NOMINATED_SCHOOLS = 3

export default {
  name: 'AdditionalSchoolFinder',
  components: {
    SchoolFinder,
    PreferenceList,
    ErrorBox,
    AdsButton
  },
  mixins: [utils],
  props: {
    title: {
      type: String,
      default: 'Nominate out-of-area schools:'
    },
    nominatedSchools: {
      type: Array,
      default: () => []
    },
    localSchools: {
      type: Array,
      default: () => []
    },
    card: {
      type: Boolean,
      default: true
    },
    showMoreInfoButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      minLengthForSearch: 3,
      schoolSearchInput: null,
      selectedSchool: null,
      dataNominatedSchools: [...this.nominatedSchools],
      screenReaderText: null,
      preferenceListCallback: null,
      removeSchool: null
    }
  },
  computed: {
    ...mapGetters([
      'highSchools',
      'pilotSchools',
      'additionalSchoolMessages',
      'schoolCustomisations',
      'oesProperties'
    ]),
    internalNominatedSchools: {
      get() {
        return this.dataNominatedSchools
      },
      set(value) {
        this.dataNominatedSchools = value
        this.$emit(EVENTS.UPDATE_SCHOOLS, value)
      }
    },
    isSchoolFinderButtonDisabled() {
      return !this.selectedSchool || this.isMaxSchoolsAdded || !this.isValid
    },
    showPreferenceList() {
      return this.isSomeSchoolsAdded
    },
    isMaxSchoolsAdded() {
      return this.internalNominatedSchools.length >= MAX_NOMINATED_SCHOOLS
    },
    isSomeSchoolsAdded() {
      return this.internalNominatedSchools.length > 0
    },
    isValid() {
      return (
        !this.message ||
        (this.message.type && this.message.type !== MESSAGE_TYPES.ERROR)
      )
    },
    errorIcon() {
      return this.isValid ? '' : 'mdi-alert-circle-outline'
    },
    // **********************
    // *** ERROR MESSAGES ***
    // **********************
    maxSchoolMessage() {
      return this.schoolSearchInput && this.isMaxSchoolsAdded
        ? 'To search for more schools, first remove an item from the list'
        : null
    },
    localMessage() {
      const scenarioConfig =
        this.localSchools.length > 1
          ? SCHOOL_EXCEPTION_SCENARIOS.LOCAL_MULTIPLE
          : SCHOOL_EXCEPTION_SCENARIOS.LOCAL_SINGLE

      return this.getExceptionMessageForSchool(
        this.isLocalSchool,
        scenarioConfig
      )
    },
    sspMessage() {
      return this.getExceptionMessageForSchool(
        this.isSspSchool,
        SCHOOL_EXCEPTION_SCENARIOS.SSP
      )
    },
    fullySelectiveMessage() {
      return this.getExceptionMessageForSchool(
        this.isFullySelectiveSchool,
        SCHOOL_EXCEPTION_SCENARIOS.FULL_SEL
      )
    },
    partiallySelectiveMessage() {
      return this.getExceptionMessageForSchool(
        this.isPartiallySelectiveSchool,
        SCHOOL_EXCEPTION_SCENARIOS.PARTIAL_SEL
      )
    },
    overCapMessage() {
      return this.getExceptionMessageForSchool(
        this.isOverCapacitySchool,
        SCHOOL_EXCEPTION_SCENARIOS.OVER_CAP
      )
    },
    specialistMessage() {
      const baseMessage = this.getExceptionMessageForSchool(
        this.isSpecialistSchool,
        SCHOOL_EXCEPTION_SCENARIOS.SPECIALIST
      )
      return baseMessage
        ? {
            ...baseMessage,
            moreInfo: () => this.$emit(EVENTS.MORE_INFO_SPEC)
          }
        : null
    },
    supportClassesMessage() {
      return this.getExceptionMessageForSchool(
        this.isSupportClassesSchool,
        SCHOOL_EXCEPTION_SCENARIOS.SUPP_CLASS
      )
    },
    message() {
      return (
        this.maxSchoolMessage ||
        this.localMessage ||
        this.sspMessage ||
        this.fullySelectiveMessage ||
        this.overCapMessage ||
        this.partiallySelectiveMessage ||
        this.supportClassesMessage ||
        this.specialistMessage
      )
    },
    schoolName() {
      return this.removeSchool && this.removeSchool.schoolName
    }
  },
  watch: {
    nominatedSchools(newValue) {
      this.dataNominatedSchools = newValue
    }
  },
  created() {
    if (!this.highSchools || this.highSchools.length === 0) {
      this.$store.dispatch('getHighSchools')
    }
    this.$store.dispatch('getPilotSchools')
  },
  methods: {
    setSRText(text) {
      this.screenReaderText = null
      setTimeout(() => {
        this.screenReaderText = text
      }, 300)
    },
    handleMoveUp() {
      this.setSRText('List updated')
    },
    handleRemove({ callback, item }) {
      this.removeSchool = item
      this.preferenceListCallback = callback
      this.removeOoaSchool()
    },
    handleMoreInfo() {
      this.$emit(EVENTS.MORE_INFO)
    },
    // ******************************
    // *** SCHOOL FINDER HANDLERS ***
    // ******************************
    getSchoolSuggestions(searchInput) {
      if (
        !this.isMaxSchoolsAdded &&
        this.highSchools &&
        this.highSchools.data
      ) {
        const suggestedHighSchools = this.highSchools.data.filter(
          (school) =>
            school.schoolName
              .toLowerCase()
              .includes(searchInput.toLowerCase()) &&
            !this.isSchoolAlreadyAdded(school) &&
            !this.isHideSchoolFromOoaChoices(school)
        )
        suggestedHighSchools.forEach((school) => {
          const schoolData = school
          if (school.suburb !== 'null' && school.suburb !== '') {
            const suburb = school.suburb.trim()
            schoolData.displayName = `${school.schoolName} (${suburb})`
          } else {
            schoolData.displayName = school.schoolName
          }
        })
        return suggestedHighSchools
      }
      return []
    },
    handleSearchInput(input) {
      if (this.selectedSchool && this.selectedSchool.displayName !== input) {
        this.selectedSchool = null
      }
    },
    handleAddSchool() {
      if (this.isValid && this.canAddSchool(this.selectedSchool)) {
        // Added school to list
        this.internalNominatedSchools = [
          ...this.internalNominatedSchools,
          this.selectedSchool
        ]
        this.setSRText('Added to list')
        // Clear input
        this.selectedSchool = null
        this.schoolSearchInput = null
      }
    },
    // ********************************
    // *** PREFERENCE LIST HANDLING ***
    // ********************************
    setSelectedSchools(schools) {
      this.internalNominatedSchools = [...schools]
    },
    async handleSchoolSelected(school) {
      await this.$store.dispatch('getSchoolCustomisation', school.schoolCode)
      this.selectedSchool = school
      let schoolType
      if (this.isSspSchool(school)) {
        schoolType = 'School with Specific Purpose'
      } else if (this.isFullySelectiveSchool(school)) {
        schoolType = 'Fully selective school'
      } else if (this.isPartiallySelectiveSchool(school)) {
        schoolType = 'Partially selective school'
      } else if (this.isSupportClassesSchool(school)) {
        schoolType = 'School with support classes'
      } else if (this.isSpecialistSchool(school)) {
        schoolType = 'Specialist school'
      } else {
        schoolType = null
      }
      if (schoolType) {
        // Google Analytics
        this.$gtm.trackEvent({
          event: 'interaction',
          category: 'School selection',
          action: 'Select school',
          label: schoolType
        })
      }
    },
    isSchoolAlreadyAdded(school) {
      return this.internalNominatedSchools.some(
        (nominatedSchool) => nominatedSchool.schoolCode === school.schoolCode
      )
    },
    isHideSchoolFromOoaChoices(school) {
      const hideSchoolsInOOASelection =
        this.oesProperties?.y67application?.hideSchoolsInOOASelection || []
      return hideSchoolsInOOASelection.includes(school.schoolCode)
    },
    canAddSchool(school) {
      return !this.isMaxSchoolsAdded && !this.isSchoolAlreadyAdded(school)
    },
    // ******************************
    // *** ERROR MESSAGE HANDLING ***
    // ******************************
    generateMessageConfig({ refDataKey, type }) {
      const { heading, body } = this.additionalSchoolMessages
        ? this.additionalSchoolMessages[refDataKey] || {}
        : {}
      return {
        heading: heading ? this.selectedSchool.schoolName + heading : null,
        body,
        messageClass: `message${type ? `--${type}` : ''}`,
        type
      }
    },
    // If the scenario predicate returns true for selected school, then
    // return the message config, else return null
    getExceptionMessageForSchool(scenarioPredicate, scenarioConfig) {
      return scenarioPredicate(this.selectedSchool)
        ? this.generateMessageConfig(scenarioConfig)
        : null
    },
    isSspSchool(school) {
      return school && school.catchmentLevel === CATCHMENT_LEVEL.SSP
    },
    isSupportClassesSchool(school) {
      return school && school.supportClass
    },
    isLocalSchool(school) {
      return !!(
        school &&
        school.schoolCode &&
        this.localSchools.find(
          (localSchool) => localSchool.school_code == school.schoolCode
        )
      )
    },
    isSpecialistSchool(school) {
      // Junior school is not a specialist school and should not display special school message
      return !!school?.speciality && school.speciality !== SPECIALITY.JUNIOR
    },
    isPartiallySelectiveSchool(school) {
      return school && school.selective === SCHOOL_SELECTIVENESS.PARTIALLY
    },
    isFullySelectiveSchool(school) {
      return school && school.selective === SCHOOL_SELECTIVENESS.FULLY
    },
    isOverCapacitySchool(school) {
      const schoolCode = school?.schoolCode
      const schoolCustomisation = this.schoolCustomisations?.[schoolCode]
      const pilotSchool = this.pilotSchools.find(
        (element) => element.schoolCode === schoolCode
      )
      if (schoolCustomisation?.ooa?.capacity?.code === SCHOOL_CAPS.OVER) {
        return true
      }
      if (!schoolCustomisation && pilotSchool?.capStatus === SCHOOL_CAPS.OVER) {
        return true
      }
      return false
    },
    removeOoaSchool() {
      this.setSRText(`Removed ${this.schoolName} from nominated schools`)
      // Call preference list callback and clear it after
      this.preferenceListCallback()
      this.preferenceListCallback = null
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1;
  color: $ads-navy;
  margin-bottom: 0.8rem;
}

::v-deep a {
  font-weight: 700;
}

p {
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5;
  color: $ads-navy;
  margin-bottom: 0.625rem;

  &:last-child {
    margin-bottom: 0;
  }
}
.school-finder {
  &--card {
    padding: 1.5rem 1.5rem 2rem 1.5rem;

    ::v-deep .preference-list li {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__title {
    font-size: 1.25rem;
    color: $ads-navy;
    font-weight: bold;
  }

  &__more-info {
    font-weight: bold !important;
    font-size: 1rem;
  }

  &__description {
    font-size: 1rem;
    font-weight: 500;
    color: $ads-dark;
  }

  &__search-wrapper {
    display: flex;
  }

  &__search {
    flex: 1;
    margin-right: 1rem;

    & ::v-deep input {
      font-size: 1.125rem;
    }

    ::v-deep .v-input__slot {
      padding-bottom: 0.5rem;
    }

    ::v-deep .v-input__icon--prepend-inner {
      margin-right: 0.5rem;
    }

    & ::v-deep .error--text input {
      color: $ads-error-red;
    }
  }

  &__search-button.btn-search.v-btn {
    margin-top: 0.3rem;
    padding: 0 1rem;
  }
  &__search-button.btn-search.v-btn.v-btn--disabled {
    border: 3px solid transparent;
  }
}

.more-info__icon {
  display: inline;
  font-size: 1.5rem;
  text-decoration: none;
  margin-right: 0.5rem;
}

.search-button__icon {
  margin-right: 0.5rem;
}

.nominated-schools {
  // These are tricks to make the transition smoother
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
  padding-top: 2rem;

  &__title {
    font-size: 1.125rem;
    line-height: 1.5;
    margin-bottom: 0;
  }

  &__description {
    font-size: 1rem;
    color: $ads-dark-60;
  }
}

// Override vuetify flipping the icon when input is focused
::v-deep .v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none;
}

.error-box {
  margin-top: 2rem;
}

.error--text {
  font-weight: normal;
  font-size: 1rem;
}

.message--error ::v-deep .error-box__icon {
  color: $ads-red;
}

.message--warning ::v-deep .error-box__icon {
  color: $ads-warning-orange;
}

.expand-transition-enter-active,
.expand-transition-leave-active {
  transition-property: all;
}

.expand-transition-leave-to {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
    .v-btn--text
  ).primary:not(.app-settings__save-button) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
