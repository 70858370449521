import Vue from 'vue'
import API from '@/store/apiUtils'
import FIELD from '@/helpers/fieldHelper'
import {
  CATCHMENT_LEVEL,
  Y67T_STATUS,
  Y67T_EDITABLE_STATUSES,
  STREAM,
  SCHOOL_SUBTYPE,
  Y67T_STATUS_MAPPING,
  Y67T_EVENT_ACTIONS
} from '@/constants'
import CONFLICT from '@/helpers/conflictHelper'
import UTILS from '@/store/utils'
import router from '@/router'

const actualYearToDBYear = (allDBYearsForSchool, actualYear) => {
  const sortedYears = [...allDBYearsForSchool].sort()
  const maxYear = sortedYears[sortedYears.length - 1]
  const minYear = sortedYears[0] || sortedYears[1] // Minimum non-zero year
  if (sortedYears.includes(actualYear)) {
    return actualYear
  }
  if (actualYear > maxYear) {
    return sortedYears.includes(0) ? 0 : maxYear
  }
  // Implicitly actual year < minYear
  return minYear
}

const isInvalidObj = (application) =>
  !application ||
  typeof application !== 'object' ||
  Object.keys(application).length === 0

// This is to force to update BE status as UI displays different
// For details: https://nsw-education.atlassian.net/browse/OE-132
// This function is to handle both array(list view) and object(details view) of the application
const mapApplicationStatus = (studentApplications) => {
  if (isInvalidObj(studentApplications)) {
    return studentApplications
  }

  const STATUS_KEYS = ['applicationStatus', 'registrationStatus', 'appStatus']
  const mapStatus = (application) => {
    if (isInvalidObj(application)) {
      return application
    }

    if (Array.isArray(application)) {
      // Recursively map statuses in nested arrays
      return application.map(mapStatus)
    }
    const updatedApplication = { ...application } // Create a copy to avoid mutating the original

    Object.entries(updatedApplication).forEach(([key, value]) => {
      if (STATUS_KEYS.includes(key) && Y67T_STATUS_MAPPING[value]) {
        updatedApplication[key] = Y67T_STATUS_MAPPING[value]
      } else if (typeof value === 'object') {
        // Recursively map statuses in nested objects or arrays
        updatedApplication[key] = mapApplicationStatus(value)
      }
    })

    return updatedApplication
  }

  return Array.isArray(studentApplications)
    ? studentApplications.map(mapStatus)
    : mapStatus(studentApplications)
}

const y67tApplicationsModule = {
  state: {
    y67tApplications: {},
    ernAddress: null,
    isAddressConflict: null,
    showConfirmAddress: false,
    readyToSendErn: false,
    showSrnMatchSearch: false,
    noLocalSchool: false,
    isAddressMatched: false, // to send 'ernAddressRecordNo' to tracking sheet if address is matched
    focusToSendErnBtn: false,
    y67tSearchFilter: {},
    statusHeaderCount: {}
  },
  getters: {
    y67tApplications: (state) => (schoolId) => {
      return state.y67tApplications[schoolId] || []
    },
    y67tSearchFilter: (state) => (tabName) => {
      return state.y67tSearchFilter[tabName] || ''
    },
    ernAddress: (state) => state.ernAddress,
    isAddressConflict: (state) => state.isAddressConflict,
    showConfirmAddress: (state) => state.showConfirmAddress,
    readyToSendErn: (state) => state.readyToSendErn,
    noLocalSchool: (state) => state.noLocalSchool,
    showSrnMatchSearch: (state) => state.showSrnMatchSearch,
    isAddressMatched: (state) => state.isAddressMatched,
    focusToSendErnBtn: (state) => state.focusToSendErnBtn,
    statusHeaderCount: (state) => state.statusHeaderCount,
    isY67TApplicationReadOnly: (_, getters) => {
      const application = getters.application
      const applicationID = application.applicationID
        ? application.applicationID.includes('Y67T')
        : false
      return (
        application &&
        applicationID &&
        Y67T_EDITABLE_STATUSES.indexOf(application.applicationStatus) === -1
      )
    }
  },
  mutations: {
    setY67TApplications(
      state,
      {
        schoolId,
        applications,
        unlinkedCoreLocalApplications,
        unlinkedCoreOOAApplications,
        duplicateLocalOoaAppsForSRN,
        statusHeaderCount
      }
    ) {
      if (!state.y67tApplications[schoolId]) {
        // init the object
        // eslint-disable-next-line import/no-named-as-default-member
        Vue.set(state.y67tApplications, schoolId, [])
      }

      // map the data once here to avoid mapping on a computed prop in the view
      const applicationsMapped = applications.map((a) => {
        // flatten application data
        const app = { ...a, ...a.application }
        app.name = app.student.familyName + ', ' + app.student.firstName
        return app
      })

      applicationsMapped.unlinkedCoreLocalApplications =
        unlinkedCoreLocalApplications
      applicationsMapped.unlinkedCoreOOAApplications =
        unlinkedCoreOOAApplications
      applicationsMapped.duplicateLocalOoaAppsForSRN =
        duplicateLocalOoaAppsForSRN || []
      applicationsMapped.statusHeaderCount = statusHeaderCount
      // eslint-disable-next-line import/no-named-as-default-member
      Vue.set(state.y67tApplications, schoolId, applicationsMapped)
    },
    setY67tSearchFilter(state, { tabName, value }) {
      if (!state.y67tSearchFilter[tabName]) {
        // init the object
        // eslint-disable-next-line import/no-named-as-default-member
        Vue.set(state.y67tSearchFilter, tabName, [])
      }
      // reset the object
      if (tabName === 'RESET') {
        state.y67tSearchFilter = {}
        return
      }
      // eslint-disable-next-line import/no-named-as-default-member
      Vue.set(state.y67tSearchFilter, tabName, value)
    },
    setErnAddress(state, address) {
      state.ernAddress = address
    },
    setIsAddressConflict(state, isConflict) {
      state.isAddressConflict = isConflict
    },
    setShowConfirmAddress(state, showConfirmModal) {
      state.showConfirmAddress = showConfirmModal
    },
    setReadyToSendErn(state, isReady) {
      state.readyToSendErn = isReady
    },
    setNoLocalSchool(state, hasNone) {
      state.noLocalSchool = hasNone
    },
    setShowSrnMatchSearch(state, showModal) {
      state.showSrnMatchSearch = showModal
    },
    setIsAddressMatched(state, isMatched) {
      state.isAddressMatched = isMatched
    },
    setFocusToSendErnBtn(state, setFocus) {
      state.focusToSendErnBtn = setFocus
    },
    setStatusHeaderCount(state, statusHeaderCount) {
      state.statusHeaderCount = statusHeaderCount
    }
  },
  actions: {
    getY67TApplications({ commit, getters }) {
      const school = getters.selectedSchool
      const currentStream = getters.currentStream
      let centralSchoolParam = ''
      if (
        currentStream === STREAM.Y67T_PRIMARY &&
        school.catchmentLevel === CATCHMENT_LEVEL.CENTRAL
      ) {
        centralSchoolParam = '?schoolType=centralPrimarySchool'
      }

      if (!school) {
        return { studentApplications: [] }
      }

      const promises = []

      promises.push(
        API.get(
          `${process.env.VUE_APP_API_PI}/v1/y67t/eoi/submitted/${school.schoolCode}${centralSchoolParam}`,
          false,
          { schoolCode: school.schoolCode }
        ).then((res) => {
          return res.data
        })
      )
      Promise.all(promises).then((responses) => {
        if (responses && responses.length > 0) {
          const dataFromResp = responses[0]
          if (dataFromResp) {
            const statusHeaderCount = dataFromResp?.meta?.count
            const applications = dataFromResp.studentApplications
            const unlinkedCoreLocalApplications =
              dataFromResp.unlinkedCoreLocalApplications
            const unlinkedCoreOOAApplications =
              dataFromResp.unlinkedCoreOOAApplications
            const duplicateLocalOoaAppsForSRN =
              dataFromResp.duplicateLocalOoaAppsForSRN
            commit('setStatusHeaderCount', statusHeaderCount)
            commit('setY67TApplications', {
              schoolId: school.schoolCode,
              applications: mapApplicationStatus(applications),
              unlinkedCoreLocalApplications: unlinkedCoreLocalApplications,
              unlinkedCoreOOAApplications: unlinkedCoreOOAApplications,
              duplicateLocalOoaAppsForSRN: duplicateLocalOoaAppsForSRN
            })
          }
        }
      })
    },
    getY67TSrnMatches({ getters }, options) {
      // options
      // - onComplete (callback to run when data has been retrieved)
      // - schYear (the application scholastic year, for y67t this is always "Year 6")
      options = options || {}
      let maxResults = 20

      let student = getters.application.student
      let params = {
        surName: student.familyName,
        givenName: student.firstName,
        otherName: student.otherName,
        gender: student.genderCode,
        dateofBirth: student.dateOfBirth,
        schoolName: getters.application.currentPrimarySchool.schoolName,
        schYear: options.schYear
      }
      API.get(`/students?` + getParamString(), true).then((matches) => {
        if (options.onComplete) {
          options.onComplete(convertParentsDisplayNameToArray(matches.data))
        }
      })

      function getParamString() {
        var parts = []
        Object.keys(params).forEach((key) => {
          if (params[key]) {
            parts.push(`${key}=${params[key]}`)
          }
        })
        return parts.join('&') + '&maxRecords=' + maxResults
      }

      // TODO: See if we can make this a generic function used for Core & Y67T matching.
      function convertParentsDisplayNameToArray(srnMatches) {
        // Parent display names currently arrive as a concatenated string, e.g.:
        // "Mother: Jones,  Helen Ann; Father: Jones,  Warwick Ian"

        // We convert this to a parent names array and remove the relationship
        srnMatches.forEach((match) => {
          var parents = []
          if (match.parentsDisplayName) {
            match.parentsDisplayName.split(';').forEach((parent) => {
              var parentWithoutRelationship = parent.split(': ')[1]
              parents.push(parentWithoutRelationship)
            })
          }
          match.parents = parents
        })
        return srnMatches
      }
    },
    linkSrnToEoI({ getters, dispatch }, srn) {
      let application = getters.application
      const school = getters.selectedSchool
      const params =
        school.catchmentLevel === CATCHMENT_LEVEL.CENTRAL
          ? {
              SRN: srn,
              schoolType: 'centralPrimarySchool'
            }
          : {
              SRN: srn
            }

      API.put(
        `${process.env.VUE_APP_API_PI}/v1/y67t/eoi/submitted/${application.currentPrimarySchool.schoolCode}/${application.applicationID}`,
        params,
        false,
        { schoolCode: application.currentPrimarySchool.schoolCode }
      ).then((response) => {
        // Not replaced the whole application with the response because the application is already converted to OES format for the detail view display.
        // only need to update SRN in the application.
        dispatch('set', ['application.updatedBy', response.data.body.updatedBy])
        dispatch('set', [
          'application.lastUpdatedDateTime',
          response.data.body.lastUpdatedDateTime
        ])
        dispatch('set', [
          'application.student',
          response.data.body.application.student
        ])
        dispatch('set', [
          'application.applicationStatus',
          Y67T_STATUS.IN_PROGRESS
        ])
        dispatch('set', ['application.otherEOIs', response.data.body.otherEOIs])
        dispatch('set', [
          'application.comment',
          response.data.body.application.comment
        ])
      })
    },
    getErnAddress(store, srn) {
      return API.get(
        `${process.env.VUE_APP_ROOT_API}/student/${srn}/address`
      ).then(({ data }) => data.residentialAddress)
    },
    updateErnAddress(store, application) {
      const srn = application.student.SRN
      const residentialAddressERN = {
        addressLine1: application.residentialAddress.addressLine1,
        suburbName: application.residentialAddress.suburbName,
        stateCode: application.residentialAddress.stateCode,
        postCode: application.residentialAddress.postCode,
        countryCode: application.residentialAddress.countryCode,
        validationFlag: 'V',
        startDate: application.dateReceived,
        endDate: '2999-12-31', //harcoded as of now (need this for ERN tracking)
        ernAddressRecordNo: null
      }
      API.post(
        `${process.env.VUE_APP_ROOT_API}/student/${application.student.SRN}/address`,
        residentialAddressERN
      ).then(() => {
        store.dispatch('getErnAddress', srn).then((response) => {
          store.commit('setErnAddress', response)
          const residentialAddress = {
            addressLine1: application.residentialAddress.addressLine1,
            suburbName: application.residentialAddress.suburbName,
            stateCode: application.residentialAddress.stateCode,
            postCode: application.residentialAddress.postCode,
            countryCode: application.residentialAddress.countryCode,
            validationFlag: 'V',
            startDate: store.getters.ernAddress.startDate,
            endDate: store.getters.ernAddress.endDate,
            addressId: application.residentialAddress.addressId,
            latitude: application.residentialAddress.latitude,
            longitude: application.residentialAddress.longitude,
            ernAddressRecordNo: store.getters.ernAddress.ernAddressRecordNo
          }
          const school = store.getters.selectedSchool
          const params =
            school.catchmentLevel === CATCHMENT_LEVEL.CENTRAL
              ? {
                  residentialAddress: residentialAddress,
                  schoolType: 'centralPrimarySchool'
                }
              : {
                  residentialAddress: residentialAddress
                }
          API.put(
            `${process.env.VUE_APP_API_PI}/v1/y67t/eoi/submitted/${application.currentPrimarySchool.schoolCode}/${application.eoiID}`,
            params,
            false,
            { schoolCode: application.currentPrimarySchool.schoolCode }
          ).then((response) => {
            store.commit('set', [
              'application.lastUpdatedDateTime',
              response.data.body.lastUpdatedDateTime
            ])
            store.commit('set', [
              'application.updatedBy',
              response.data.body.updatedBy
            ])
            store.commit('set', [
              'application.comment',
              response.data.body.application.comment
            ])
          })
        })
      })
      // .catch((error) => console.log(error))
    },
    getY67TApplication(store, [id, schoolCode]) {
      const school = store.getters.selectedSchool
      const currentStream = store.getters.currentStream
      let centralSchoolParam = ''
      if (
        currentStream === STREAM.Y67T_PRIMARY &&
        school.catchmentLevel === CATCHMENT_LEVEL.CENTRAL
      ) {
        centralSchoolParam = '?schoolType=centralPrimarySchool'
      }
      let apiUrl = `${process.env.VUE_APP_API_PI}/v1/y67t/eoi/submitted/${schoolCode}/${id}${centralSchoolParam}`
      const apiHeaders = { schoolCode: schoolCode }
      if (currentStream === STREAM.ARCHIVE) {
        const applicationType = router.currentRoute.params.type
        const isPrimary = router.currentRoute.params?.primary
        apiUrl = `${
          process.env.VUE_APP_API_PI
        }/v1/archived/application/${schoolCode}/${id}?applicationType=${applicationType}${
          isPrimary ? '&isPrimary=true' : ''
        }`
      }

      FIELD.setStore(store)

      return new Promise((done) => {
        store.commit('set', ['isEditing', false])
        store.commit('set', ['ernRecord', null])
        store.commit('set', ['application', null])
        store.commit('setFocusToSendErnBtn', false)

        store.dispatch('showSpinner')

        // Gets application record...
        API.get(apiUrl, true, apiHeaders).then((response) => {
          const responseData = response.data.body
          const srn = responseData.application?.student?.SRN
          const isPrimary =
            store.getters.selectedSchool &&
            store.getters.selectedSchool.catchmentLevel ===
              CATCHMENT_LEVEL.PRIMARY

          // API Mis-design: Deeper level, different keys
          // API Mis-design: `application` instead of `oesApplication`
          store.commit('set', ['application', responseData.application])
          store.commit('set', [
            'application.lastUpdatedDateTime',
            responseData.lastUpdatedDateTime
          ])
          store.commit('set', [
            'application.updateEmailSent',
            responseData.updateEmailSent
          ])
          store.commit('set', [
            'application.emailSentCoreAppSubmittedForLHS',
            responseData.emailSentCoreAppSubmittedForLHS
          ])
          store.commit('set', ['application.updatedBy', responseData.updatedBy])
          store.commit('set', ['application.otherEOIs', responseData.otherEOIs])

          const getErnAddress =
            srn && !isPrimary ? store.dispatch('getErnAddress', srn) : null

          let getReferenceData = store.dispatch('getReferenceData')
          // Retrieve OoA high schools ref data for criteria

          let getConsiderations =
            store.getters.application.ooaHighSchools &&
            store.getters.application.ooaHighSchools.some(({ y67t }) => y67t)
              ? store.dispatch('getGlobalRefData')
              : []
          let getSchoolOoaSettings = store.dispatch(
            'getSchoolOoaSettings',
            schoolCode
          )

          Promise.all([
            getReferenceData,
            getConsiderations,
            getErnAddress,
            getSchoolOoaSettings
          ]).then((data) => {
            store.commit('set', ['referenceData', data[0]])
            store.commit('set', ['application.ernAddress', data[2]])
            store.dispatch(
              'displayApplication',
              mapApplicationStatus(responseData)
            )
            store.dispatch('hideSpinner')
            done()
          })
        })
      })
    },
    // withdraw By Primary School Y67TApplication
    withdrawByPSY67TApplication({ getters, dispatch }, applicationStatus) {
      const { applicationID, schoolCode } = getters.application
      dispatch('set', ['application.applicationStatus', applicationStatus])
      let payload = {
        action: 'Withdrawn By PS'
      }
      const school = getters.selectedSchool
      if (school.catchmentLevel === CATCHMENT_LEVEL.CENTRAL) {
        payload.schoolType = 'centralPrimarySchool'
      }
      return API.put(
        `${process.env.VUE_APP_API_PI}/v1/y67t/eoi/tracking/${schoolCode}/${applicationID}`,
        payload,
        false,
        { schoolCode }
      )
    },
    withdrawY67TApplication({ dispatch }) {
      return dispatch('withdrawByPSY67TApplication', Y67T_STATUS.WITHDRAWN)
    },
    // Set Status (Offered/Withdrawn/Declined/Not Accepted/Offer Expired) Y67TApplication By High School
    setStatusY67TApplication({ getters, dispatch }, payload) {
      const { applicationID, schoolCode } = getters.application
      const actionStatus =
        payload.status === 'Withdrawn' ? 'Withdrawn By HS' : payload.status
      const params = {
        action: actionStatus,
        comment: {
          value: payload.comment,
          supportingDocuments: payload.supportingDocuments
        }
      }
      if (actionStatus === Y67T_STATUS.OFFERED && payload?.considerations) {
        params.considerations = payload.considerations
      }
      if (actionStatus === Y67T_STATUS.DECLINED) {
        params.declinedReason = payload.declinedReason
      }
      return API.put(
        `${process.env.VUE_APP_API_PI}/v1/y67t/eoi/tracking/${schoolCode}/${applicationID}`,
        params,
        false,
        { schoolCode }
      )
        .then((response) => {
          dispatch('set', ['application.applicationStatus', payload.status])
          return response
        })
        .catch((error) => {
          const httpCode = error.response.status
          if ([403, 404, 409, 422].indexOf(httpCode) === -1) {
            dispatch('showMessageBox', {
              icon: 'priority_high',
              html: `<h2>Unable to complete request</h2>${API.getErrorMessage(
                error
              )}`
            })
          }
        })
    },
    // Y67T Bulk Offer
    bulkOfferY67T({ getters, dispatch }, payload) {
      const schoolCode = getters.selectedSchool?.schoolCode
      let comment = payload.comment
      let bulkOfferId = payload.bulkOfferId
      let eoiIds = payload.eoiIds
      let attachments = []
      payload.attachments.forEach((file) => {
        attachments.push(file.name)
      })
      return API.put(
        `${process.env.VUE_APP_API_PI}/v1/y67t/eoi/tracking/bulk/${schoolCode}`,
        {
          action: 'Offered',
          comment: comment,
          bulkOfferId: bulkOfferId,
          eoiIds: eoiIds,
          attachments: attachments
        },
        false,
        { schoolCode }
      )
        .then((response) => {
          return response
        })
        .catch((error) => {
          const httpCode = error.response.status
          if ([403, 404, 409, 422].indexOf(httpCode) === -1) {
            dispatch('showMessageBox', {
              icon: 'priority_high',
              html: `<h2>Unable to complete request</h2>${API.getErrorMessage(
                error
              )}`
            })
          }
        })
    },
    getAddressForSRN(store, srn) {
      const isPnrAdvice =
        store.getters.application.adviceType &&
        store.getters.application.adviceType === 'PNR'
      API.get(`/student/${srn}/address`, true).then((resp) => {
        // eslint-disable-next-line
        store.commit('setErnAddress', resp.data.residentialAddress)
        if (isPnrAdvice) {
          store.dispatch('getLocalHighSchoolForErnAddress', [
            resp.data.residentialAddress,
            srn
          ])
        } else {
          store.dispatch('checkAddressConflict')
        }
      })
    },
    checkAddressConflict(store) {
      const address = store.getters.application
        ? store.getters.application.residentialAddress
        : null
      const ernAddress = store.getters.ernAddress
      let eoiAddressString = [
        CONFLICT.normaliseAddress(address.addressLine1),
        address.suburbName,
        address.stateCode,
        address.postCode,
        address.countryCode
      ].join(' ')
      eoiAddressString = CONFLICT.formatWithoutAbbreviation(eoiAddressString)
      let ernAddressString = [
        CONFLICT.normaliseAddress(ernAddress.addressLine1),
        ernAddress.addressLine2,
        ernAddress.suburbName,
        ernAddress.stateCode,
        ernAddress.postCode,
        ernAddress.countryCode
      ].join(' ')
      ernAddressString = CONFLICT.formatWithoutAbbreviation(ernAddressString)
      // Call NSW Point API to get the addressId for ERN address
      const params = {
        address: ernAddressString,
        maxNumberOfResults: 5,
        addressType: 'physical'
      }
      return API.get(
        `${process.env.VUE_APP_API_PI}/nswpoint/v2/api/predictive1`,
        true,
        {
          'x-preflight': 'force',
          noDefaultHeader: true
        },
        params
      )
        .then((resp) => {
          if (resp && resp.data) {
            const matchAddId = resp.data.find(
              (addr) => addr.id === address.addressId
            )
            // if address id matches with EOI address, don't show conflict
            if (matchAddId) {
              store.commit('setIsAddressConflict', false)
              store.commit('setReadyToSendErn', true)
              store.commit('setIsAddressMatched', true)
            } else {
              store.dispatch('matchAddress', [
                eoiAddressString,
                ernAddressString
              ])
            }
          } else {
            store.dispatch('matchAddress', [eoiAddressString, ernAddressString])
          }
        })
        .catch(() => {
          store.dispatch('matchAddress', [eoiAddressString, ernAddressString])
        })
    },
    matchAddress(store, [eoiAddr, ernAddr]) {
      store.commit('setIsAddressConflict', eoiAddr !== ernAddr)
      if (eoiAddr === ernAddr) {
        store.commit('setReadyToSendErn', true)
        store.commit('setIsAddressMatched', true)
      }
    },
    sendToTracking(store, eoiID) {
      const application = store.getters.application
      const isAddressMatched = store.getters.isAddressMatched
      let date = application.dateReceived
      date = date.substr(0, date.indexOf('T'))
      const residentialAddress = {
        addressLine1: application.residentialAddress.addressLine1,
        suburbName: application.residentialAddress.suburbName,
        stateCode: application.residentialAddress.stateCode,
        postCode: application.residentialAddress.postCode,
        countryCode: application.residentialAddress.countryCode,
        validationFlag: 'V',
        startDate: isAddressMatched ? store.getters.ernAddress.startDate : date,
        endDate: isAddressMatched
          ? store.getters.ernAddress.endDate
          : '2999-12-31', //harcoded as of now (need this for ERN tracking)
        addressId: application.residentialAddress.addressId,
        latitude: application.residentialAddress.latitude,
        longitude: application.residentialAddress.longitude,
        ernAddressRecordNo: isAddressMatched
          ? store.getters.ernAddress.ernAddressRecordNo
          : null
      }
      const eoiDetails = {
        residentialAddress: residentialAddress
      }
      const schoolCode = application.schoolCode
      const school = store.getters.selectedSchool
      let payload = {
        action: Y67T_EVENT_ACTIONS.SEND_TO_TRACKING,
        eoiDetails: eoiDetails
      }

      if (school.catchmentLevel === CATCHMENT_LEVEL.CENTRAL) {
        payload.schoolType = 'centralPrimarySchool'
      }
      return (
        API.put(
          `${process.env.VUE_APP_API_PI}/v1/y67t/eoi/tracking/${schoolCode}/${eoiID}`,
          payload,
          false,
          { schoolCode }
        )
          .then(() => {
            // store.dispatch('getApplication', [eoiID, application.schoolCode])
          })
          //  Display message box if status code is not 200
          .catch((error) => {
            store.dispatch('showMessageBox', {
              icon: 'priority_high',
              html: `<h2>Unable to complete request</h2>${
                error?.response?.data?.body?.description || ''
              }`
            })
          })
      )
    },

    async saveY67TApplication(store) {
      const { applicationID, schoolCode } = store.getters.application
      const school = store.getters.selectedSchool
      const currentStream = store.getters.currentStream
      if (store.getters.isSpinner) {
        return // Prevents saving while a save is already in progress
      }
      const putData = UTILS.clone(store.rootState.application)
      const params =
        currentStream === STREAM.Y67T_PRIMARY &&
        school.catchmentLevel === CATCHMENT_LEVEL.CENTRAL
          ? {
              student: putData.student,
              parentCarers: putData.parentCarers,
              schoolType: 'centralPrimarySchool'
            }
          : {
              student: putData.student,
              parentCarers: putData.parentCarers
            }

      Vue.gtm.trackEvent({
        category: 'saveApplication',
        action: STREAM.Y67T_PRIMARY,
        label: putData.schoolName
      })

      return API.put(
        `${process.env.VUE_APP_API_PI}/v1/y67t/eoi/submitted/${schoolCode}/${applicationID}`,
        params,
        false,
        { schoolCode }
      )
        .then((response) => {
          store.commit('set', [
            'application.updatedBy',
            response.data.body.updatedBy
          ])
          store.commit('set', [
            'application.lastUpdatedDateTime',
            response.data.body.lastUpdatedDateTime
          ])
          store.commit('set', ['isEditing', false])
        })
        .catch(() => {
          store.commit('set', ['isEditing', false])
        })
    },
    getLocalHighSchoolForErnAddress(store, [ernAddr, srn]) {
      let residentialAddress = {}
      residentialAddress.addressLine1 = ernAddr.addressLine1

      if (!UTILS.isEmpty(ernAddr.addressLine2)) {
        residentialAddress.addressLine1 =
          residentialAddress.addressLine1 + ' ' + ernAddr.addressLine2
      }

      residentialAddress.countryCode = ernAddr.countryCode
      residentialAddress.postCode = ernAddr.postCode
      residentialAddress.stateCode = ernAddr.stateCode
      residentialAddress.suburbName = ernAddr.suburbName
      residentialAddress.startDate = ernAddr.startDate
      let ernAddressString = [
        CONFLICT.normaliseAddress(residentialAddress.addressLine1),
        residentialAddress.suburbName,
        residentialAddress.stateCode,
        residentialAddress.postCode,
        residentialAddress.countryCode
      ].join(' ')
      ernAddressString = CONFLICT.formatWithoutAbbreviation(ernAddressString)
      // Call NSW Point API to get the addressId for ERN address
      const params = {
        address: ernAddressString,
        maxNumberOfResults: 5,
        addressType: 'physical'
      }
      return API.get(
        `${process.env.VUE_APP_API_PI}/nswpoint/v2/api/predictive1`,
        true,
        {
          'x-preflight': 'force',
          noDefaultHeader: true
        },
        params
      ).then((resp) => {
        if (resp && resp.data && resp.data.length > 0) {
          // get the 0th record from the suggested list
          const address = resp.data[0]
          residentialAddress.addressId = address.id
          return API.post(
            `${process.env.VUE_APP_API_PI}/nswpoint/v2/api/predictive2`,
            { id: address.id },
            false,
            { 'x-preflight': 'force', noDefaultHeader: true }
          ).then((resp) => {
            const geo = resp.data.data.geo
            const long = geo.geometry.coordinates[0]
            const lat = geo.geometry.coordinates[1]
            residentialAddress.longitude = long
            residentialAddress.latitude = lat
            store.dispatch('getSchoolsForAddress', [
              long,
              lat,
              residentialAddress,
              srn
            ])
          })
        } else {
          store.dispatch('savePnrApplication', [srn, residentialAddress, null])
        }
      })
    },
    getSchoolsForAddress(store, [long, lat, residentialAddress, srn]) {
      const applicationData = store.getters.application
      const endPoints = store.getters.oesProperties.endpoints
      const schoolFinderURLs = endPoints?.schoolFinder?.urls || []
      const headers = {
        noDefaultHeader: true
      }
      const sqlQuery = `SELECT s.school_code, s.school_name, s.school_email, s.street, s.town_suburb, s.school_subtype, s.postcode, s.longitude, s.latitude, s.gender, phone, website, c.calendar_year, (EXTRACT(YEAR FROM now()) + 1) as next_year, ARRAY_AGG(DISTINCT all_catch.calendar_year) as all_calendar_years FROM dec_schools_2020 as s JOIN catchments_2020 as c USING (school_code) JOIN catchments_2020 AS all_catch USING (school_code,catchment_level) WHERE  c.catchment_level = 'secondary' AND ST_CONTAINS(c.the_geom, ST_SetSRID(ST_Point(${long},${lat}), 4326)) GROUP BY  s.school_code, s.school_name, s.school_email, s.street, s.town_suburb,s.postcode, s.longitude, s.latitude, phone, website,s.school_subtype, c.calendar_year, s.gender;`
      return API.get(
        `${schoolFinderURLs[0]}?q=${encodeURIComponent(sqlQuery)}`,
        true,
        headers
      ).then((resp) => {
        let localHighSchool
        if (resp.data.rows && resp.data.rows.length > 0) {
          const data = resp.data.rows
          let intendedHighSchool = resp.data.rows[0]
          // check if the address returns multiple highschool records
          // Filter out senior campus schools and then and then filter out schools that do not match students gender select first school based on student gender if no gender specific school then select first coed school in array
          if (resp.data.rows.length > 1) {
            const nextYear = data?.[0]?.next_year
            let localHighSchoolsAfterFilter = data.filter((schoolCatchment) => {
              const nextYearAsDBYear = actualYearToDBYear(
                schoolCatchment.all_calendar_years,
                nextYear
              )
              return (
                schoolCatchment.calendar_year === nextYearAsDBYear &&
                SCHOOL_SUBTYPE.includes(schoolCatchment.school_subtype)
              )
            })

            const studentGender =
              applicationData.student.genderCode === 'F' ? 'girls' : 'boys'

            const recommendedHighschools = localHighSchoolsAfterFilter
              .filter(
                (school) =>
                  school.gender === studentGender || school.gender === 'coed'
              )
              .sort((a, b) => {
                if (
                  a.school_name.includes('Girls') ||
                  a.school_name.includes('Boys')
                ) {
                  return -1
                } else if (
                  b.school_name.includes('Girls') ||
                  b.school_name.includes('Boys')
                ) {
                  return 1
                } else {
                  return 0
                }
              })
            intendedHighSchool = recommendedHighschools[0]
          }
          localHighSchool = {
            schoolCode: intendedHighSchool.school_code.toString(),
            schoolName: intendedHighSchool.school_name
          }
        }
        store.dispatch('savePnrApplication', [
          srn,
          residentialAddress,
          localHighSchool
        ])
      })
    },
    savePnrApplication(store, [srn, residentialAddress, localHighSchool]) {
      const applicationData = store.getters.application
      const school = store.getters.selectedSchool
      let params = localHighSchool
        ? {
            SRN: srn,
            residentialAddress: residentialAddress,
            localHighSchool: localHighSchool
          }
        : {
            SRN: srn,
            residentialAddress: residentialAddress,
            noLocalHighSchool: true
          }
      if (school.catchmentLevel === CATCHMENT_LEVEL.CENTRAL) {
        params.schoolType = 'centralPrimarySchool'
      }
      API.put(
        `${process.env.VUE_APP_API_PI}/v1/y67t/eoi/submitted/${applicationData.currentPrimarySchool.schoolCode}/${applicationData.applicationID}`,
        params,
        false,
        { schoolCode: applicationData.currentPrimarySchool.schoolCode }
      ).then((response) => {
        // Not replaced the whole application with the response because the application is already converted to OES format for the detail view display.
        // only need to update SRN in the application.
        const data = response.data.body.application
        data.alertResolutions = applicationData.alertResolutions
        data.alerts = applicationData.alerts
        data.updatedBy = response.data.body.updatedBy
        data.lastUpdatedDateTime = response.data.body.lastUpdatedDateTime
        data.applicationID = data.eoiID
        data.otherEOIs = response.data.body.otherEOIs
        store.commit('set', ['application', data])
        store.commit('setIsAddressConflict', false)
        localHighSchool !== null
          ? store.commit('setReadyToSendErn', true)
          : store.commit('setNoLocalSchool', true)
        store.commit('setIsAddressMatched', true)
      })
    }
  }
}

export default y67tApplicationsModule
