var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-10 py-6 grey lighten-6"},[_c('v-row',{staticClass:"pb-3"},[_c('v-col',{staticClass:"pt-4"},[_c('span',{staticClass:"filterLabel"},[_vm._v("Filter by:")]),_c('span',{staticClass:"pl-3"},[_c('ListViewFilter',{ref:"offerStatusFilter",attrs:{"label":"Offer status","type":"checkbox","filter-options":_vm.offerStatusFilters},on:{"updatefilter":_vm.updateOfferStatusFilter,"menuOpened":_vm.sendFilterAnalytics}})],1),_c('span',{staticClass:"pl-4"},[_c('ListViewFilter',{ref:"applicationStatusFilter",attrs:{"label":"Enrolment application status","type":"checkbox","width":"300px","filter-options":_vm.applicationStatusFilters},on:{"updatefilter":_vm.updateApplicationStatusFilter,"menuOpened":_vm.sendFilterAnalytics}})],1),(_vm.isFiltered)?_c('ClearFilters',{on:{"click":function($event){return _vm.clearAllFilters()}}}):_vm._e()],1)],1),_c('v-card',[_c('AdsDataTable',{ref:"shsOffersList",attrs:{"headers":_vm.headers,"items":_vm.cOffers,"search-label":"Find student","search":_vm.internalSearch,"sort-by":"applicationStatusSort","sort-desc":false,"must-sort":"","items-per-page":_vm.pagination.itemsPerPage,"footer-props":{
        'items-per-page-options': [10, 15, 50, -1]
      },"custom-filter":_vm.filterUpdates,"page":_vm.currentPage,"data-testid":"shsOffersList"},on:{"update:page":[_vm.updatePagination,function($event){_vm.currentPage=$event}],"pagination":_vm.totalResults,"current-items":_vm.setFilteredIds},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('NoResultsFound',{attrs:{"filtered":_vm.isFiltered || Boolean(_vm.internalSearch),"archive-type":_vm.ARCHIVE_TYPES.SHS.type}})]},proxy:true},{key:"top",fn:function(){return [_c('v-row',{staticClass:"row-line"},[_c('v-col',[_c('v-text-field',{staticClass:"searchInput",attrs:{"prepend-inner-icon":"mdi-magnify","label":"Find student","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.internalSearch),callback:function ($$v) {_vm.internalSearch=$$v},expression:"internalSearch"}})],1),_c('v-col',{staticClass:"results-text d-flex align-end pr-5",attrs:{"cols":"auto"}},[_c('span',[_vm._v(" "+_vm._s(_vm.resultsText(_vm.pagination.itemsLength))+" ")])])],1)]},proxy:true},{key:"item",fn:function(props){return [_c('tr',{staticClass:"grey--text text--lighten-1",on:{"click":function($event){return _vm.openOffer(props.item)}}},[_c('td',{staticClass:"text-xs-right"},[_c('router-link',{attrs:{"to":_vm.generateRoute(props.item),"aria-label":`${
                props.item[_vm.DT_PROPERTIES.NAME]
              }. Open offer details.`}},[_vm._v(" "+_vm._s(props.item[_vm.DT_PROPERTIES.NAME])+" ")])],1),_c('td',{staticClass:"text-sm-center text-md-left"},[_c('scholastic-year-cell',{attrs:{"year":props.item[_vm.DT_PROPERTIES.SCHOLASTIC_YEAR]}})],1),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(props.item[_vm.DT_PROPERTIES.START_DATE])+" ")]),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(props.item[_vm.DT_PROPERTIES.OFFER_STATUS])+" ")]),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(_vm._f("moment")(props.item[_vm.DT_PROPERTIES.ENROLMENT_ISSUED_DATE],'DD MMM YYYY'))+" ")]),_c('td',{staticClass:"text-xs-right"},[_c('Chip',_vm._b({staticClass:"text-center font-weight-bold",attrs:{"small":""}},'Chip',{
                ..._vm.getStatusChipDetails(
                  _vm.getOfferAndAppStatus(
                    props.item[_vm.DT_PROPERTIES['APPLICATION_STATUS_SORT']]
                  )
                )
              },false))],1),_c('td',{staticClass:"text-center alert"},[(
                props.item[_vm.DT_PROPERTIES.APPLICATION_STATUS]
                  .toUpperCase()
                  .includes('DANGER')
              )?_c('span',[_c('v-icon',{attrs:{"color":"red","size":"30","title":"Action required"}},[_vm._v(" mdi-alert-circle ")]),_c('span',{staticClass:"sr-only"},[_vm._v("Action required")])],1):_vm._e()])])]}}])},[_vm._l((_vm.headers),function(headerData){return _c('template',{slot:`header.${headerData.value}`},[_c('button',{key:headerData.value,staticClass:"no-styling"},[_vm._v(" "+_vm._s(headerData.text)+" ")])])})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }