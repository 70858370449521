<!--
  Provides the template for the application title and summary details at the top of
  the application.
-->
<template>
  <div class="statusContainer">
    <div
      v-if="!isCompletedStatus && !isArchived"
      class="d-flex align-center flex-wrap py-6"
      data-testid="changeStatusAndButtons"
    >
      <StatusSwitcher class="mr-3" />
      <v-spacer />
      <div class="d-flex flex-wrap">
        <AdsButton
          class="mr-3"
          secondary
          @click="openChangeStatusModal(OOA_STATUS.WITHDRAWN)"
          data-testid="withdrawn-btn"
          :disabled="isEditing"
          outlined
          icon="mdi-delete-outline"
          button-text="Withdraw"
        />
        <AdsButton
          class="mr-3 warning-btn"
          color="red"
          primary
          @click="openChangeStatusModal(OOA_STATUS.DECLINED)"
          data-testid="declined-btn"
          :disabled="isEditing"
          icon="mdi-close-circle-outline"
          button-text="Decline"
          outlined
        />
        <AdsButton
          class="primary-btn"
          @click="openChangeStatusModal(OOA_STATUS.OFFERED)"
          data-testid="offered-btn"
          :disabled="isEditing"
          icon="mdi-school-outline"
          button-text="Offer"
        />
      </div>
    </div>
    <div v-if="isTemporaryResident" class="py-3">
      <Alert
        in-page
        type="warning"
        :text="`${studentName} is a temporary resident`"
        background-colour
        data-testid="temporaryResidentAlert"
      />
    </div>
    <div v-if="isCompletedStatus && !isArchived" class="py-3">
      <Alert
        in-page
        :type="statusProps.type"
        :icon="statusProps.icon"
        :text="statusProps.text"
        :subtext="statusProps.subtext"
        :class="statusProps.cssClass"
        :action-text="allowChangeOutcome ? 'CHANGE STATUS' : ''"
        :action-handler="changeStatusClick"
        data-testid="applicationStatusAlert"
      />
    </div>
    <div class="pb-6">
      <AdsExpansionPanel
        :items="historyPanels"
        v-model="openPanels"
        data-testid="ooaHistoryExpansionPanel"
      >
        <template slot="contentHistory">
          <History
            v-if="schoolCode && applicationId"
            id="ooaHistory"
            :school-code="schoolCode"
            :application-id="applicationId"
          />
        </template>
      </AdsExpansionPanel>
    </div>

    <ChangeStatusModal
      v-if="showChangeStatusModal"
      v-model="showChangeStatusModal"
      persistent
      :current-status="applicationStatus"
      :change-status="changeStatus"
      :student-name="studentName"
      :parent-carer-name="contactName"
      @confirm="confirmStatusChange"
    />
  </div>
</template>

<script>
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import StatusSwitcher from '@/components/application/StatusSwitcher.vue'
import History from '@/components/application/ooa/History'
import ChangeStatusModal from '@/components/application/ooa/ChangeStatusModal'
import {
  OOA_STATUS,
  OOA_STATUS_CHANGED_ICONS,
  OOA_OFFERED_STATUSES,
  STREAM
} from '@/constants'
import { AdsExpansionPanel, AdsButton, Alert } from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'

const STATUS_PROPS = {
  // Offered statuses have the same props
  ...OOA_OFFERED_STATUSES.reduce((acc, s) => {
    acc[s] = {
      type: 'info',
      icon: 'lock_outline',
      text: 'This application is currently under offer.'
    }
    return acc
  }, {}),
  [OOA_STATUS.NOT_ACCEPTED]: {
    type: 'info',
    icon: 'lock_outline',
    text: 'The parent/carer has chosen not to accept this offer.',
    subtext: 'No further action can be taken at this time.',
    cssClass: 'greyBorderAndIcon'
  },
  [OOA_STATUS.DECLINED]: {
    type: 'error',
    icon: 'lock_outline',
    text: 'This application has been declined.'
  },
  [OOA_STATUS.DECLINED_POST_APPEAL]: {
    type: 'error',
    icon: 'lock_outline',
    text: 'This application has been declined post appeal.'
  },
  [OOA_STATUS.ACCEPTED]: {
    type: 'success',
    icon: 'send',
    text: 'This offer has been accepted and a full application has been submitted.'
  },
  [OOA_STATUS.OFFER_EXPIRED]: {
    type: 'info',
    icon: 'lock_outline',
    text: 'This application offer has expired.',
    subtext: 'No further action can be taken at this time.',
    cssClass: 'greyBorderAndIcon'
  },
  [OOA_STATUS.WITHDRAWN]: {
    type: 'info',
    icon: 'lock_outline',
    text: 'This application has been withdrawn.',
    subtext: 'No further action can be taken at this time.',
    cssClass: 'greyBorderAndIcon'
  }
}

export default {
  name: 'OoaSummary',
  mixins: [fieldHelperMixin],
  components: {
    StatusSwitcher,
    History,
    ChangeStatusModal,
    AdsExpansionPanel,
    AdsButton,
    Alert
  },
  props: {
    schoolCode: {
      type: String
    }
  },
  data() {
    return {
      openPanels: [],
      showChangeStatusModal: false,
      OOA_STATUS,
      changeStatus: null
    }
  },
  computed: {
    ...mapGetters(['currentStream']),
    state() {
      return this.$store.state
    },
    isCompletedStatus() {
      const completedStatuses = [
        OOA_STATUS.WITHDRAWN,
        OOA_STATUS.DECLINED,
        OOA_STATUS.ACCEPTED,
        OOA_STATUS.NOT_ACCEPTED,
        OOA_STATUS.OFFER_EXPIRED,
        OOA_STATUS.DECLINED_POST_APPEAL,
        ...OOA_OFFERED_STATUSES
      ]
      return completedStatuses.includes(this.applicationStatus)
    },

    allowChangeOutcome() {
      const allowedStatuses = [
        ...OOA_OFFERED_STATUSES,
        OOA_STATUS.DECLINED,
        OOA_STATUS.DECLINED_POST_APPEAL
      ]
      return allowedStatuses.includes(this.applicationStatus)
    },
    contact() {
      return this.getValue('parentCarers').find(
        (parent) => parent.parentCarerCompletingApp
      )
    },
    contactName() {
      const contact = this.contact
      if (contact) {
        return `${contact.parentCarerTitle} ${contact.parentCarerGivenName} ${contact.parentCarerFamilyName}`
      }
      return ''
    },
    applicationId() {
      return this.getValue('applicationID')
    },
    applicationStatus() {
      return this.$store.state.application.applicationStatus
    },
    studentName() {
      const names = [
        this.getValue('student.firstName'),
        this.getValue('student.familyName')
      ]
      return this.formatNames(names)
    },
    historyPanels() {
      return [
        {
          id: 'History',
          title: 'Application History',
          icon: 'history'
        }
      ]
    },
    statusProps() {
      return STATUS_PROPS[this.applicationStatus]
    },
    isArchived() {
      return this.currentStream === STREAM.ARCHIVE
    },
    isTemporaryResident() {
      return this.$store.state.application.residencyStatus === 'TMP'
    }
  },
  methods: {
    formatNames(names) {
      return names
        .map((n) => (n ? n.trim() : ''))
        .filter((n) => n)
        .join(' ')
    },
    contactDetails(type) {
      var contactDetails = (this.contact && this.contact.contactDetails) || []
      var phone = contactDetails.find((item) => item.contactType === type) || {}
      return phone.contactValue
    },
    openChangeStatusModal(status) {
      this.changeStatus = status
      this.showChangeStatusModal = true
    },
    changeStatusClick() {
      this.showChangeStatusModal = true
      this.$gtm.trackEvent({
        category: STREAM.OUT_OF_AREA,
        action: 'changeStatusClick'
      })
    },
    confirmStatusChange({
      status,
      comment,
      sendEmail,
      considerations,
      declinedReason,
      changeOutcome,
      documents
    }) {
      // extract only timestamp/filename.ext from the full object key of schoolCode/applicationId/timestamp/filename.ext
      const newDocuments = documents
        ? documents.map((d) => d.key.split('/').slice(-2).join('/'))
        : []

      this.$store
        .dispatch('saveApplicationStatus', {
          applicationStatus: status,
          comment,
          sendEmail,
          considerations,
          declinedReason,
          changeOutcome,
          newDocuments
        })
        .then(() => {
          this.$router.push({ path: '/' })
          let displayStatus = status

          if (status === OOA_STATUS.DECLINED_POST_APPEAL) {
            displayStatus = OOA_STATUS.DECLINED
          }

          let snackbarHtml = `<b>${this.studentName}'s</b> application is now set to <b>"${displayStatus}"</b>.`
          if (sendEmail || status === OOA_STATUS.WITHDRAWN) {
            snackbarHtml += ` An email has been sent.`
          }

          this.$store.dispatch('showSnackbar', {
            display: true,
            html: snackbarHtml,
            icon:
              OOA_STATUS_CHANGED_ICONS[status] || 'mdi-checkbox-marked-circle'
          })
        })
    }
  }
}
</script>

<style scoped lang="scss">
#app.v-application button.v-btn:not(.v-btn--round) {
  &.warning-btn {
    color: #ffffff;
    &:focus {
      box-shadow: 0 0 0 4px $color-red-border;
    }
  }

  &.primary {
    &:focus {
      box-shadow: 0 0 0 4px $color-focus;
    }
  }
}
#app.v-application button.v-btn:not(.v-btn--round).review-btn {
  color: black;
}
#app.v-application button.v-btn:not(.v-btn--round).withdraw-btn {
  height: 41px;
}
.statusContainer {
  background: $ads-light-10;
}
#app.v-application .v-btn--outlined:focus {
  border-width: 2px;
}
.greyBorderAndIcon {
  ::v-deep .v-alert__border {
    background-color: $color-placeholder !important;
    border-color: $color-placeholder !important;
  }
  ::v-deep .v-icon {
    color: $color-placeholder !important;
  }
}
</style>
