<template>
  <v-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :max-width="dialogWidth"
    :persistent="isPersistent"
    ref="legendModal"
  >
    <v-card :role="role" class="legendModal" @keyup.esc="$emit('close')">
      <v-btn
        class="modal__close-button float-right"
        :ripple="false"
        icon
        aria-label="close"
        @click="$emit('close')"
        ref="closeButton"
        data-testid="modal-close-button"
      >
        <v-icon> close </v-icon>
      </v-btn>
      <div class="header heading d-flex align-left">
        <span class="legend-heading">Legend</span>
      </div>
      <div class="body">
        <div
          class="legend-item"
          v-for="({ color, icon, text }, index) in legendStatusIcons"
          :key="index"
        >
          <v-icon :style="{ color }">{{ icon }}</v-icon>
          <p class="legend-list-text">{{ text }}</p>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LegendModal',
  props: {
    isPersistent: {
      type: Boolean,
      default: false
    },
    dialogWidth: {
      type: Number,
      default: 744
    },
    footer: {
      type: Boolean,
      default: true
    },
    role: {
      type: String,
      default: 'alert'
    },
    legendStatusIcons: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped lang="scss">
.legend-list-text {
  display: inline;
  margin-left: 10px;
  color: $ads-dark;
  font-size: 16px;
}

.legend-heading {
  color: $ads-navy;
}

.legend-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.legendModal {
  padding: 2rem;
  .theme--light.button.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
      .v-btn--text
    ).primary:not(.app-settings__save-button) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .modal__close-button {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    border: none;

    &:focus {
      box-shadow: 0 0 0 2px $ads-navy;
    }
  }
  .modal__close-button.float-right.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
    color: $grey-darken1 !important;
    &:focus {
      color: $color-primary !important;
    }
  }
  .heading {
    display: flex;
    font-weight: 700;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.3333rem;
  }

  p {
    line-height: 1.5;
    margin-bottom: 0;
  }

  .body {
    margin: 1.5rem 0;
  }
}
</style>
