<template>
  <v-dialog
    max-width="630"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
    :key="dialogKey"
  >
    <v-form ref="changeStatusY67tForm" v-model="y67tStatusChangeFormValid">
      <v-card class="change-status-modal">
        <v-btn
          class="close-button"
          icon
          aria-label="close"
          @click="close"
          @keydown.esc="close"
        >
          <v-icon> close </v-icon>
        </v-btn>
        <div class="header">
          <h1 role="alert" class="heading" data-testid="heading">
            <span v-if="isOffered"
              >Make an offer of enrolment to {{ this.studentName }}?</span
            >
            <div v-if="isWithdrawn || isDeclined">
              <span class="withdraw-decline-heading">{{
                this.status.slice(0, -1)
              }}</span>
              application for {{ this.studentName }}?
            </div>
            <div v-if="isChange">
              Change the status of this application for {{ this.studentName }}?
            </div>
          </h1>
        </div>
        <AdsSelect
          v-if="isChange"
          data-testid="selectOptions"
          v-model="selectedStatus"
          :items="items"
          :menu-props="{ bottom: true, offsetY: true }"
          class="mt-10"
          outlined
          :rules="[(val) => !!val || 'Required.']"
          label="Change status to"
          placeholder="Please select..."
          persistent-placeholder
          hint="Required."
          persistent-hint
        />
        <div>
          <Alert
            v-if="isWithdrawn"
            data-testid="withdrawn"
            in-page
            type="warning"
            alert-class="offer-note"
            :html-text="`<span class='subtext'>The expression of interest will be withdrawn from all remaining OoA high schools and will be assigned to <b>${localHighSchoolName}.</b></span>`"
          />
          <p class="my-5" v-if="!isChange">
            The status of the application will be set to
            <strong>&#8220;{{ this.status }}&#8221;</strong>.
          </p>
          <Alert
            v-if="overCap && isOffered && outOfAreaSchool"
            data-testid="overCapAlert"
            in-page
            type="warning"
            alert-class="offer-note"
            html-text="<span class='subtext'><b>Note:</b> DEL approval is required before offering a place as your </br> school is currently over cap.</span>"
          />
          <div
            class="email-template-container"
            data-testid="emailContainer"
            v-if="notChange || (isChange && !!selectedStatus)"
          >
            <p v-if="notChange">
              <span v-if="isWithdrawn"
                >Please ensure you have spoken with the parent/carer(s) before
                withdrawing.</span
              >
              Parent/carer <strong>{{ this.parentName }}</strong> will be
              emailed with<span v-if="isOffered">
                instructions for accepting the offer and proceeding with a full
                enrolment application.</span
              >
              <span v-if="isWithdrawn || isDeclined">
                a confirmation that their application has been
                {{ this.status.toLowerCase() }}.</span
              >
            </p>
            <p v-if="isChange && !!selectedStatus">
              The parent/carer of <strong>{{ this.studentName }}</strong> will
              be emailed with a confirmation that their
              <span v-if="isNotAccepted"
                >application has not been accepted.</span
              >
              <span v-if="isOfferExpired">
                offer has been expired. Changing this offer should only be done
                consistently with your school's processes and with the
                authorisation of the principal.
              </span>
            </p>
            <div>
              <a :href="this.y67tEmailLink" target="_blank"
                >View email template</a
              >
            </div>
          </div>
          <v-divider class="divider" />
          <div
            data-testid="capture-criteria"
            v-if="isOffered && outOfAreaSchool"
          >
            <p class="my-7">
              Which criteria supported you to make your decision about this
              out-of-area application?
            </p>

            <v-select
              v-model="considerations.primary"
              :items="primaryCriteriaList"
              item-text="value"
              item-value="code"
              label="Primary criteria *"
              placeholder="Select criteria..."
              :outlined="true"
              class="mt-3"
              persistent-placeholder
              :rules="[(val) => !!val || 'Required.']"
              max-width="556px"
              hint="Required."
              persistent-hint
              :menu-props="{
                contentClass: 'v-select--wrap-list-items'
              }"
            >
              <template #item="data">
                <v-list-item-title
                  :class="`${data.item.isBold ? 'font-weight-bold' : ''}`"
                >
                  {{ data.item.value }}
                </v-list-item-title>
              </template>
            </v-select>

            <v-select
              v-model="considerations.secondary"
              :items="secondaryCriteriaList"
              item-text="value"
              item-value="code"
              label="Secondary criteria"
              placeholder="Select criteria..."
              outlined
              class="mt-3"
              persistent-placeholder
              :disabled="!considerations.primary"
              hint="Select a primary criteria first."
              persistent-hint
              :menu-props="{
                contentClass: 'v-select--wrap-list-items'
              }"
            >
              <template #item="data">
                <v-list-item-title
                  :class="`${data.item.isBold ? 'font-weight-bold' : ''}`"
                >
                  {{ data.item.value }}
                </v-list-item-title>
              </template>
            </v-select>

            <v-select
              v-model="considerations.tertiary"
              :items="tertiaryCriteriaList"
              item-text="value"
              item-value="code"
              label="Tertiary criteria"
              placeholder="Select criteria..."
              :outlined="true"
              class="mt-3"
              persistent-placeholder
              :disabled="!considerations.secondary"
              hint="Select a secondary criteria first."
              persistent-hint
              :menu-props="{
                contentClass: 'v-select--wrap-list-items'
              }"
            >
              <template #item="data">
                <v-list-item-title
                  :class="`${data.item.isBold ? 'font-weight-bold' : ''}`"
                >
                  {{ data.item.value }}
                </v-list-item-title>
              </template>
            </v-select>
          </div>
          <div data-testid="capture-reason" v-if="isDeclined">
            <p class="my-7">Why was this out-of-area application declined?</p>

            <v-select
              v-model="declinedReason"
              :items="activeDeclinedReasons"
              item-text="value"
              item-value="code"
              label="Reason *"
              placeholder="Select reason..."
              :outlined="true"
              class="mt-3"
              persistent-placeholder
              :rules="[(val) => !!val || 'Required.']"
              max-width="556px"
              hint="Required."
              persistent-hint
              :menu-props="{
                contentClass: 'v-select--wrap-list-items'
              }"
            />
          </div>
          <AdsTextArea
            v-model="staffComment"
            class="mt-3"
            :label="`Comments (school use only${
              hasSelectedOtherConsiderations ? ') *' : ' - optional)'
            }`"
            placeholder="You can write the reason for your decision here. This information will not be shared with the parent/carer."
            rows="5"
            maxlength="200"
            counter="200"
            persistent-placeholder
            :rules="
              hasSelectedOtherConsiderations
                ? [(val) => !!val || 'Required.']
                : []
            "
          />
          <SupportingDocumentUploadY67T
            v-model="files"
            @dateStamp="dateStamp"
            ref="supportingDocumentUploady67t"
            :is-busy.sync="isFileOperationInProgress"
            :hs-tracking-action="true"
          />
        </div>
        <div class="actions">
          <AdsButton
            :class="buttonStyle.color"
            :button-text="buttonStyle.text"
            @click="setAppStatus"
            :disabled="isFileOperationInProgress || !y67tStatusChangeFormValid"
            data-testid="action-btn"
          />
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { AdsButton, AdsTextArea, AdsSelect, Alert } from '@nswdoe/doe-ui-core'
import SupportingDocumentUploadY67T from '@/components/application/y67t/SupportingDocumentUploadY67T'
import { Y67T_STATUS, OOA_CRITERIA } from '@/constants'
import { mapGetters } from 'vuex'
import UTILS from '@/store/utils'
export default {
  name: 'SetStatusModalY67T',
  components: {
    AdsButton,
    AdsTextArea,
    AdsSelect,
    SupportingDocumentUploadY67T,
    Alert
  },
  props: {
    studentName: {
      type: String
    },
    parentName: {
      type: String
    },
    status: {
      type: String
    },
    isChange: {
      type: Boolean
    }
  },
  data() {
    return {
      staffComment: '',
      selectedStatus: '',
      formatChange: '',
      files: [],
      isFileOperationInProgress: false,
      date: '',
      dialogKey: '',
      y67tStatusChangeFormValid: true,
      considerations: {
        primary: null,
        secondary: null,
        tertiary: null
      },
      declinedReason: null,
      Y67T_STATUS
    }
  },
  computed: {
    ...mapGetters([
      'y67tEmailLink',
      'application',
      'selectedSchool',
      'overCap',
      'schoolOoaSettings',
      'activeDeclinedReasons'
    ]),
    application() {
      return this.$store.getters.application || {}
    },
    isOffered() {
      return this.status === Y67T_STATUS.OFFERED
    },
    isWithdrawn() {
      return this.status === Y67T_STATUS.WITHDRAWN
    },
    isDeclined() {
      return this.status === Y67T_STATUS.DECLINED
    },
    isNotAccepted() {
      return this.selectedStatus.includes(Y67T_STATUS.NOT_ACCEPTED)
    },
    isOfferExpired() {
      return this.selectedStatus.includes(Y67T_STATUS.OFFER_EXPIRED)
    },
    notChange() {
      return (
        this.status === Y67T_STATUS.DECLINED ||
        this.status === Y67T_STATUS.WITHDRAWN ||
        this.status === Y67T_STATUS.OFFERED
      )
    },
    dynamicStatus() {
      return this.status ? this.status : this.formatChange
    },
    localHighSchoolName() {
      return this.application?.localHighSchool?.schoolName || ''
    },
    outOfAreaSchool() {
      return this.selectedSchool.schoolName !== this.localHighSchoolName
    },
    icon() {
      return {
        name: this.isOffered
          ? 'mdi-check-circle'
          : this.isDeclined
          ? 'mdi-cancel'
          : this.isWithdrawn || this.isChange
          ? 'mdi-delete-outline'
          : '',
        color: this.isOffered
          ? 'success'
          : this.isDeclined
          ? 'error'
          : this.isWithdrawn || this.isChange
          ? 'dark70'
          : ''
      }
    },
    buttonStyle() {
      return {
        color: this.isOffered
          ? 'button success'
          : this.isDeclined || this.isWithdrawn
          ? 'button red'
          : this.isChange
          ? 'button'
          : '',
        text: this.isOffered
          ? 'Make offer of enrolment'
          : this.isDeclined
          ? 'Confirm decline of enrolment'
          : this.isWithdrawn
          ? 'Confirm application withdrawal'
          : this.isChange
          ? 'Confirm status change'
          : ''
      }
    },
    items() {
      if (
        this.application.localHighSchool.schoolCode ===
        this.selectedSchool.schoolCode
      ) {
        return [`${Y67T_STATUS.NOT_ACCEPTED} (by parent)`]
      } else {
        return [
          `${Y67T_STATUS.NOT_ACCEPTED} (by parent)`,
          `${Y67T_STATUS.OFFER_EXPIRED} (by school)`
        ]
      }
    },
    schoolSettings() {
      return this.schoolOoaSettings(this.selectedSchool.schoolCode)
    },
    otherConsiderations() {
      return this.schoolSettings?.enableOtherCriteria
    },
    hasSelectedOtherConsiderations() {
      return Object.values(this.considerations).includes('OTH')
    },
    schoolCriteriaList() {
      const selectedSchoolCriteria =
        this.application?.ooaHighSchools?.find(
          (o) => o.schoolCode === this.selectedSchool.schoolCode
        ) || {}
      const applicationCriteria =
        selectedSchoolCriteria?.criteria?.map((c) => c.code) || []
      // Mapping MED to LAW and siblings to SIB start
      if (selectedSchoolCriteria?.siblings?.length > 0) {
        applicationCriteria.push(OOA_CRITERIA.SIB)
      }
      const parentSelectedCriteria = applicationCriteria?.map((code) =>
        code == OOA_CRITERIA.MED ? OOA_CRITERIA.LAW : code
      )
      const schoolCriteria = this.schoolSettings?.criteria.map((c) => ({
        code: c.code,
        value: c.value,
        disabled: false
      }))
      const hasOtherConsiderations =
        !!selectedSchoolCriteria?.otherConsiderations
      const criteriaList = []
      criteriaList.push({
        code: 'CSBPC',
        header: 'Criteria selected by the parent/carer'
      })
      if (parentSelectedCriteria.length > 0) {
        criteriaList.push(
          ...schoolCriteria
            .filter((d) => parentSelectedCriteria.includes(d.code))
            .map((finalResult) => {
              return {
                ...finalResult,
                isBold: true
              }
            })
        )
      }
      if (hasOtherConsiderations && this.otherConsiderations) {
        criteriaList.push({
          code: 'OTH',
          value: 'Other considerations',
          disabled: false,
          isBold: true
        })
      }
      if (
        parentSelectedCriteria.length === 0 &&
        !(hasOtherConsiderations && this.otherConsiderations)
      ) {
        criteriaList.push({
          code: 'NONE',
          value: '(None)',
          disabled: true
        })
      }
      criteriaList.push({
        code: 'AOC',
        header: 'All other criteria'
      })
      criteriaList.push(
        ...schoolCriteria.filter(
          (d) => !parentSelectedCriteria.includes(d.code)
        )
      )
      criteriaList.push({
        code: 'SBC',
        value: 'School is under buffer or cap and has capacity',
        disabled: false
      })
      if (!hasOtherConsiderations && this.otherConsiderations) {
        criteriaList.push({
          code: 'OTH',
          value: 'Other considerations',
          disabled: false
        })
      }
      return criteriaList
    },
    primaryCriteriaList() {
      return this.schoolCriteriaList.map((c) => ({
        ...c,
        disabled:
          c.disabled ||
          c.code === this.considerations.secondary ||
          c.code === this.considerations.tertiary
      }))
    },
    secondaryCriteriaList() {
      return this.schoolCriteriaList.map((c) => ({
        ...c,
        disabled:
          c.disabled ||
          c.code === this.considerations.primary ||
          c.code === this.considerations.tertiary
      }))
    },
    tertiaryCriteriaList() {
      return this.schoolCriteriaList.map((c) => ({
        ...c,
        disabled:
          c.disabled ||
          c.code === this.considerations.primary ||
          c.code === this.considerations.secondary
      }))
    }
  },
  methods: {
    dateStamp(value) {
      this.date = value
    },
    setAppStatus() {
      this.$emit('input', false)
      this.$emit('focus', this.status ? this.status : 'changeStatus')
      const supportingDocuments = this.files.map((file) => {
        return this.date + '/' + file.name
      })
      const params = {
        status: this.dynamicStatus,
        comment: UTILS.sanitize(this.staffComment),
        supportingDocuments: supportingDocuments
      }
      if (this.isOffered && this.outOfAreaSchool) {
        params.considerations = this.considerations
      }
      if (this.isDeclined) {
        params.declinedReason = this.declinedReason
      }
      this.$store
        .dispatch('setStatusY67TApplication', params)
        .then((response) => {
          this.$router.push({ path: '/' })
          const studentName = `${this.application.student.firstName} ${this.application.student.familyName}`
          let message
          if (response.data.body.isEmailSentForHSAction) {
            message = `<strong>${studentName}'s</strong> application is now set to <strong>&#8220;${this.dynamicStatus}&#8221;</strong>. Email sent to parent.`
          } else {
            message = `<strong>${studentName}'s</strong> application is now set to <strong>&#8220;${this.dynamicStatus}&#8221;</strong>.`
          }
          this.$store.dispatch('getNumbersTracker')
          this.$store.dispatch('showSnackbar', {
            display: true,
            html: message,
            icon: this.icon
          })
        })
    },
    close() {
      this.selectedStatus = ''
      this.formatChange = ''
      this.staffComment = ''
      this.considerations = {}
      this.declinedReason = null
      if (this.files) {
        this.files = []
        this.$refs.supportingDocumentUploady67t?.deleteAll()
      }
      for (
        let i = this.$refs.supportingDocumentUploady67t?._data.alerts?.length;
        i >= 0;
        i--
      ) {
        this.$refs?.supportingDocumentUploady67t?.closeError(i)
      }
      this.$emit('input', false)
      this.$emit('focus', this.status)
      // force rerender of dialog so comment box is reset
      this.dialogKey = Math.random()
    }
  },
  watch: {
    selectedStatus() {
      this.formatChange = this.selectedStatus
        .split(' ')
        .slice(0, -2)
        .join(' ')
        .trim()
    },
    focusButton() {
      requestAnimationFrame(() => {
        this.$refs[this.focusButton]?.$el.focus()
        this.$emit('focusReset')
      })
    }
  },
  mounted() {
    this.$store.dispatch('getOverCap', this.selectedSchool?.schoolCode)
  }
}
</script>

<style scoped lang="scss">
.change-status-modal {
  padding: 2rem;
  .theme--light.button.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
      .v-btn--text
    ).primary:not(.app-settings__save-button) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .close-button {
    position: absolute;
    top: 0.15rem;
    right: 0.15rem;
    border: none;
    color: $grey-darken1;
    &:focus {
      color: $color-primary;
      box-shadow: 0 0 0 2px $color-primary;
    }
  }
  .withdraw-decline-heading {
    color: $ads-red;
  }
  ::v-deep {
    .theme--light.v-btn.v-btn--disabled:not(.v-btn--text):not(
        .v-btn--outlined
      ) {
      background-color: $ads-dark-60 !important;
      color: $ads-white !important;
      border: none;
    }
    textarea {
      padding: 10px 3px;
    }
    .subtext {
      font-weight: 500 !important;
    }
    .offer-note {
      margin: 20px 0px;
      line-height: 1.5;
      background-color: rgba(255, 206, 153, 0.4) !important;
      box-shadow: none !important;
      border: 2px solid #fff;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }
    .offer-note:focus {
      border: 2px solid $ads-primary-blue;
      border-radius: 4px;
    }
    .v-alert__icon.v-icon {
      margin: 7px 16px 0;
      font-size: 30px;
    }
  }
  .heading {
    font-size: 20px;
    color: $ads-navy;
    line-height: 1.4 !important;
  }
  .email-template-container {
    background-color: $ads-light-10;
    line-height: 1.5;
    padding: 16px;
    border-radius: 4px;
    a {
      text-decoration: underline;
      color: $ads-dark;
      font-weight: bold;
    }
    a:focus {
      outline: $ads-dark 2px solid;
      border-radius: 2px;
    }
  }
  .divider {
    margin: 28px 0px;
    color: $ads-light-40;
    border-width: 1px;
  }
  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    .theme--light.v-btn {
      padding: 20px 40px !important;
    }
  }
}
::v-deep {
  .v-select:not(.ads-select) {
    & .v-input__control input[readonly] {
      position: unset !important;
      top: unset !important;
    }
  }
}
::v-deep .theme--light.v-input--is-disabled input::placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
}
</style>
