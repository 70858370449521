<template>
  <v-btn
    v-if="!!statusHeaderCountItem"
    block
    depressed
    height="78"
    class="statusBtn"
    :color="adsWhite"
    data-testid="status-btn"
  >
    <v-row no-gutters align="center">
      <v-col cols="2">
        <v-icon :color="statusHeaderCountItem.color" size="40">{{
          statusHeaderCountItem.icon || ''
        }}</v-icon>
      </v-col>
      <v-col cols="4">
        <div class="headerCountNumber" data-testid="header-count-number">
          {{ statusHeaderCountItem.count || 0 }}
        </div>
      </v-col>
      <v-col cols="6">
        <div
          v-for="(text, index) in statusHeaderCountItem.text"
          :key="index"
          class="statusText"
          data-testid="status-text"
        >
          {{ text || '' }}
        </div>
      </v-col>
    </v-row>
  </v-btn>
</template>

<script>
import { ADS_Colors } from '@nswdoe/doe-ui-core'

export default {
  name: 'StatusHeaderCountItem',
  props: {
    statusHeaderCountItem: {
      type: Object
    }
  },
  data() {
    return {
      adsWhite: ADS_Colors.White
    }
  }
}
</script>

<style lang="scss" scoped>
.statusBtn {
  max-width: 290px;
}

.statusText {
  font-size: 14px;
  color: $ads-grey-02;
  text-align: left;
  font-weight: 400;
}

.headerCountNumber {
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 2.5rem;
  text-align: center;
  color: $ads-grey-01;
}

::v-deep .statusBtn .v-btn__content {
  justify-content: flex-start;
}

.statusBtn {
  &:focus {
    box-shadow: 0 0 0 2px $ads-navy !important;
  }
}
</style>
