<!--
  Displays content for the Actions panel (left of the application)
-->
<template>
  <div>
    <div class="ApplicationAlerts">
      <h2>Actions</h2>
    </div>
    <div v-if="application && !isActioned && !isSecondarySchool">
      <div
        v-if="!application.student.SRN"
        data-testid="check-for-srn"
        class="alerts"
      >
        <v-icon color="#CE0037" class="material-icons pr-3">error</v-icon>
        <span v-if="!isEditing">
          <button
            @click="checkSRN"
            class="alert unstyled"
            type="button"
            data-testid="check-existing-SRN"
            aria-label="Check for an existing SRN"
          >
            Check for an existing SRN
          </button>
        </span>
        <span v-else> Check for an existing SRN </span>
      </div>
      <div
        v-if="application.student.SRN && isAddressConflict"
        data-testid="address-conflict"
        class="alerts"
      >
        <v-icon color="#CE0037" class="material-icons pr-3">error</v-icon>
        <span
          >There is an address mismatch.
          <span v-if="!isEditing">
            <button
              @click="resolveConflict"
              class="alert unstyled"
              data-testid="resolve-conflict"
              aria-label="Resolve correct address"
            >
              Resolve correct address
            </button>
          </span>
          <span v-else> Resolve correct address </span>
        </span>
      </div>
      <div
        v-if="isReadyToSendErn"
        data-testid="ready-to-send-ern"
        class="alerts"
      >
        <span class="iconLinked iconCircleBackground mr-3">
          <v-icon color="white">check</v-icon>
        </span>
        <span class="pt-3">
          <strong>Ready to send to HS</strong>
          <span class="sr-only">Ready to send to HS</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Y67T_STATUS, STREAM } from '@/constants'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'

export default {
  name: 'Y67TAlerts',
  mixins: [fieldHelperMixin],
  computed: {
    ...mapGetters([
      'isAddressConflict',
      'selectedSchool',
      'readyToSendErn',
      'isEditing',
      'currentStream'
    ]),
    isActioned() {
      if (this.application) {
        return (
          !this.isSecondarySchool &&
          [
            Y67T_STATUS.WITHDRAWN,
            Y67T_STATUS.SENT_TO_HS,
            Y67T_STATUS.NOT_REQUIRED
          ].includes(this.application.applicationStatus)
        )
      }
      return false
    },
    isSecondarySchool() {
      return this.currentStream === STREAM.Y67T_SECONDARY
    },
    isReadyToSendErn() {
      return (
        this.application.student.SRN &&
        this.readyToSendErn &&
        this.isAddressConflict === false
      )
    }
  },
  methods: {
    checkSRN() {
      this.$store.commit('setShowSrnMatchSearch', true)
    },
    resolveConflict() {
      this.$store.commit('setShowConfirmAddress', true)
    }
  }
}
</script>

<style scoped lang="scss">
.ApplicationAlerts {
  padding: 1.5rem 0 1rem;
  background-color: white;
  h2 {
    padding-bottom: 0.5rem;
    margin: 0;
    border-bottom: 1px solid #a0a5ae;
    color: $color-dark70;
  }
}
.alerts {
  color: $color-primary;
  display: flex;
  padding: 1rem 0;
  .alert {
    text-decoration: underline;
  }
}
.iconLinked {
  background-color: $color-success;
}
</style>
