<!--
  Provides the template for the application last modified date/time and username details before the
  sections containing form fields.
-->
<template>
  <div>
    <div aria-live="polite" class="sr-only">
      <p v-if="lastModifiedBy && !newStatusWithParentUpdate">
        Last updated by {{ lastModifiedBy }} on
        {{ lastModified | moment('DD MMM YYYY hh:mm:ss A') }}
        <span v-if="emailSent">Email sent to parent.</span>
      </p>
      <p v-else></p>
    </div>
    <div
      v-if="lastModifiedBy && !newStatusWithParentUpdate"
      class="ApplicationModifiedByInfo"
      data-testid="modify-by-info"
    >
      <template v-if="parentDeclinedOffer">
        Parent declined offer on {{ lastModified | moment('DD MMM YYYY') }}
      </template>
      <template v-else-if="parentAcceptedOffer">
        Full application submitted on {{ lastModified | moment('DD MMM YYYY') }}
      </template>
      <template v-else>
        Last updated by <strong>{{ lastModifiedBy }}</strong> on
        <strong>{{ lastModified | moment('DD MMM YYYY hh:mm:ss A') }}</strong
        >.
        <!-- For ooa -->
        <span v-if="parentNotifiedByEmail"> Parent notified by email.</span>
        <!-- For year 6/7 -->
        <span v-if="emailSent" data-testid="sent-email">
          Email sent to parent.</span
        >
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApplicationModifiedByInfo',
  props: {
    lastModifiedBy: {
      type: String,
      default: ''
    },
    lastModified: {
      type: String,
      default: ''
    },
    emailSent: {
      type: Boolean,
      default: false
    },
    parentDeclinedOffer: {
      type: Boolean,
      default: false
    },
    parentAcceptedOffer: {
      type: Boolean,
      default: false
    },
    parentNotifiedByEmail: {
      type: Boolean,
      default: false
    },
    newStatusWithParentUpdate: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.ApplicationModifiedByInfo {
  margin-right: 1rem;
  color: $color-text-body;
  font-size: 0.8rem;
  text-align: right;
}
</style>
