<template>
  <div class="py-6 grey lighten-6">
    <!-- Y67T Filters -->
    <v-row class="pb-3" justify="space-between">
      <v-col>
        <AdsSelect
          label=""
          v-model="selectedDate"
          :items="yearOptions"
          @change="dateCount"
          id="date-filter"
          outlined
          hide-details
          aria-label="Calendar year select filter"
          persistent-placeholder
        />
      </v-col>
      <v-col cols="auto">
        <AdsButton
          ref="legendButton"
          class="mr-0"
          secondary
          @click="openLegendModal"
          button-text="View legend"
          icon="mdi-eye-outline"
          data-testid="y67tLegendButton"
        />
      </v-col>
      <v-col cols="auto" v-if="!isOesSupport && isERNAdmin">
        <AdsButton
          class="mr-0"
          ref="exportButton"
          :disabled="
            !Object.values(selectedIds).filter((v) => v === true).length
          "
          @click="openExportModal"
          button-text="Export list as CSV"
          icon="list_alt"
          data-testid="y67tExportButton"
        />
      </v-col>
    </v-row>
    <v-card>
      <AdsDataTable
        :headers="headers"
        :items="applications"
        search-label="Find student"
        :search="internalSearch"
        sort-by="name"
        sort-asc
        must-sort
        :items-per-page="pagination.itemsPerPage"
        :footer-props="{
          'items-per-page-options': [10, 15, 50, -1]
        }"
        :no-results-found="'No results found. Try removing some filters or clearing the keyword search.'"
        :no-data-text="'No results found. Try removing some filters or clearing the keyword search.'"
        @current-items="setFilteredIds"
        show-expand
        :expanded.sync="expandAll"
        item-key="eoiID"
        :custom-filter="filterApplications"
        @update:page="updatePagination"
        @pagination="totalResults"
        :page.sync="currentPage"
        data-testid="y67tTTrackingSheetList"
      >
        <template #[`header.data-table-expand`]>
          <span tabindex="-1"></span>
        </template>
        <template #top>
          <v-row class="row-line">
            <v-col>
              <v-text-field
                class="searchInput"
                v-model="internalSearch"
                prepend-inner-icon="mdi-magnify"
                label="Find student"
                single-line
                hide-details
                clearable
              />
            </v-col>
            <v-col cols="auto" class="results-text">
              <span>
                {{ resultsText(pagination.itemsLength) }}
                <a
                  tabindex="0"
                  class="select-all"
                  v-if="selectCheckCount"
                  @keyup.enter="selectEveryIdToggle(selectedItemsCount)"
                  @click="selectEveryIdToggle(selectedItemsCount)"
                >
                  {{
                    selectedItemsCount === pagination.itemsLength
                      ? 'Clear selection'
                      : `Select all ${pagination.itemsLength}`
                  }}
                </a>
              </span>
            </v-col>
            <v-col cols="auto" class="text-center">
              <AdsButton
                class="collapse-all-button"
                v-if="toggleExpandCollapse && enableButton"
                tertiary
                icon="unfold_less"
                button-text="Collapse all"
                @click="expandCollapse('collapse')"
              />
              <AdsButton
                class="collapse-all-button"
                v-else
                :disabled="!enableButton"
                tertiary
                icon="unfold_more"
                button-text="Expand all"
                @click="expandCollapse('expand')"
              />
            </v-col>
          </v-row>
        </template>
        <template #[`header.selectAll`]>
          <v-checkbox
            :value="allSelected"
            hide-details
            aria-label="Add all applications to export list as CSV."
            class="my-0 mx-3 pa-0"
            @click.prevent="toggleSelectAll"
            data-testid="y67tToggleSelectAll"
          />
        </template>
        <template #[`item.selectAll`]="{ item }">
          <OesCheckbox
            v-model="selectedIds[item.eoiID]"
            hide-details
            :aria-label="`Add application for ${item.name} to export list as CSV.`"
            class="ma-0 pa-0 tracking-sheet"
            @click.stop
            data-testid="y67tSelectApplicationCheckbox"
          />
        </template>
        <template #[`item.name`]="{ item }">
          <span tabindex="0" class="student-name focus-ring">
            {{ item.name }}
          </span>
        </template>
        <template #[`item.localHighSchool`]="{ item }">
          <div class="text-wrap-inline">
            <v-icon :class="statusBasedIconClass(item.localHighSchool)">{{
              statusBasedIcon(item.localHighSchool)
            }}</v-icon>
            <div
              :title="fullSchoolName(item.localHighSchool)"
              class="column-text"
              :class="sameSchoolClass(item.localHighSchool)"
            >
              {{
                trackingBreakpoint
                  ? shortSchoolName(item.localHighSchool)
                  : abbreviateSchoolName(item.localHighSchool)
              }}
            </div>
          </div>
        </template>
        <template #[`item.ooaHighSchools`]="{ item }">
          <div class="text-wrap-inline">
            <v-icon :class="statusBasedIconClass(item.ooaHighSchools)">{{
              statusBasedIcon(item.ooaHighSchools)
            }}</v-icon>
            <div
              :title="fullSchoolName(item.ooaHighSchools)"
              class="column-text"
              :class="sameSchoolClass(item.ooaHighSchools)"
            >
              {{
                trackingBreakpoint
                  ? shortSchoolName(item.ooaHighSchools)
                  : abbreviateSchoolName(item.ooaHighSchools)
              }}
            </div>
          </div>
        </template>
        <template #[`item.walkupsMain`]="{ item }">
          <div class="text-wrap-inline">
            <v-icon :class="statusBasedIconClass(item.walkupsMain)">{{
              statusBasedIcon(item.walkupsMain)
            }}</v-icon>
            <div
              :title="fullSchoolName(item.walkupsMain)"
              class="column-text"
              :class="sameSchoolClass(item.walkupsMain)"
            >
              {{
                trackingBreakpoint
                  ? shortSchoolName(item.walkupsMain)
                  : abbreviateSchoolName(item.walkupsMain)
              }}
            </div>
          </div>
        </template>
        <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
          <div v-if="item.walkups.length > 1">
            <button
              @click.stop="expand(!isExpanded), singleExpand(item)"
              :aria-label="[
                isExpanded ? 'Click to Collapse' : 'Click to Expand'
              ]"
              type="button"
              class="v-icon v-data-table__expand-icon v-icon--link mdi"
              :class="[isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down']"
            />
          </div>
        </template>
        <template #expanded-item="{ item }">
          <td colspan="5">&nbsp;</td>
          <td colspan="3">
            <ul class="walkups-list" v-if="item.walkupsAll.length > 1">
              <li
                class="walkup-list-item"
                v-for="(walkup, index) in item.walkupsAll"
                :key="index"
              >
                <div>
                  <div class="float-left">
                    <v-icon :class="statusBasedIconClass(walkup)">{{
                      statusBasedIcon(walkup)
                    }}</v-icon>
                  </div>
                  <div
                    :title="fullSchoolName(walkup)"
                    class="float-left walkup-description"
                    :class="sameSchoolClass(walkup)"
                    v-html="shortSchoolName(walkup)"
                  />
                </div>
              </li>
            </ul>
          </td>
        </template>
      </AdsDataTable>
    </v-card>
    <LegendModal
      v-model="showLegendModal"
      @close="closeLegendModal"
      :dialog-width="400"
      :legend-status-icons="legendStatusIcons"
    />
    <ExportListAsCsv
      v-show="showExportModal"
      :applications="selectedIdsForExport"
      :file-name="csvFileName"
      @close="closeExportModal"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { searchFilter, searchFilterForStringOnly } from '@/helpers/searchHelper'
import moment from 'moment'
import OesCheckbox from '@/components/app/OesCheckbox'
import { AdsButton, AdsDataTable, AdsSelect } from '@nswdoe/doe-ui-core'
import LegendModal from '@/components/app/legendModal.vue'
import ExportListAsCsv from '@/components/applicationList/ExportListAsCsv.vue'
import {
  APPLICATION_TYPE,
  Y67T_STATUS,
  Y67T_STATUS_ICONS,
  USER_GROUP,
  Y67T_LEGEND_STATUS
} from '@/constants'
import _ from 'lodash'

const currentYear = new Date().getFullYear().toString()
const currentDate = moment().format('DD_MMM_YYYY')

export default {
  name: 'Y67TTrackingSheetList',
  components: {
    OesCheckbox,
    AdsButton,
    AdsDataTable,
    AdsSelect,
    LegendModal,
    ExportListAsCsv
  },
  data() {
    return {
      expandAll: [],
      expandOrCollapse: 'collapse',
      toggleExpandCollapse: false,
      selectedIds: {},
      enableButton: true,
      APPLICATION_TYPE,
      showLegendModal: false,
      showExportModal: false,
      selectedDate: '',
      selectedItemsCount: 0,
      currentPage: 1,
      selectCheckCount: false,
      idPerPage: [],
      filteredIds: 0,
      pagination: { itemsLength: 0, itemsPerPage: 50 },
      tabName: 'TRACKING',
      legendStatusIcons: Y67T_LEGEND_STATUS
    }
  },
  created() {
    this.selectedDate =
      this.yearOptions.find((i) => i === currentYear + ' Calendar year') ||
      this.yearOptions[0]
  },
  watch: {
    schoolCode() {
      // clear selected applications for download pdf on change of school switcher
      this.selectedIds = {}
      this.idPerPage = []
      // clear selected filters on change of school switcher
      this.expandAll = []
      this.expandOrCollapse = ''
      this.selectedReceivedYear = [currentYear]
    },
    selectedItemsCount() {
      const checkedOnes = _.pickBy(this.selectedIds, (value) => {
        return value
      })

      const currentPageIds = this.visibleEoiIds.reduce((selected, appId) => {
        selected[appId] = true
        return selected
      }, {})

      this.filteredIds = Object.keys(checkedOnes).filter((i) => {
        return Object.keys(currentPageIds).includes(i)
      })

      this.idPerPage[this.currentPage - 1] =
        this.filteredIds.length === this.pagination.itemsPerPage ||
        this.filteredIds.length === this.pagination.itemsLength ||
        this.filteredIds.length ===
          this.pagination.pageStop - this.pagination.pageStart
          ? true
          : false

      this.selectCheckCount = this.idPerPage.includes(true)
    }
  },
  methods: {
    totalResults(pagination) {
      this.pagination = pagination
    },
    resultsText(itemsLength) {
      this.selectedItemsCount = Object.values(this.selectedIds).filter(
        (value) => value === true
      ).length
      return this.selectCheckCount
        ? this.selectedItemsCount + ' of ' + itemsLength + ' selected.'
        : itemsLength === 1
        ? itemsLength + ' result'
        : itemsLength + ' results'
    },
    dateCount(selected) {
      const getYear = selected?.split(' ')[0]
      this.$emit('receivedDatefilter', getYear)
      // clear selected applications on change of date filter
      this.selectedIds = {}
      this.idPerPage = []
      this.currentPage = 1
    },
    matchesReceivedYearFilter(application) {
      const getYear = this.selectedDate?.split(' ')[0]
      return (
        !this.selectedDate.length ||
        moment(application.dateReceived).year().toString() === getYear
      )
    },
    closeLegendModal() {
      this.showLegendModal = false
      requestAnimationFrame(() => {
        this.$refs.legendButton.$el.focus()
      })
    },
    openLegendModal() {
      this.showLegendModal = true
    },
    expandCollapse(type) {
      this.expandOrCollapse = type
      if (this.expandOrCollapse == 'expand') {
        this.getExpandCollapseApps()
        this.toggleExpandCollapse = true
      } else {
        this.expandAll = []
        this.toggleExpandCollapse = false
      }
    },
    getExpandCollapseApps() {
      this.expandAll = this.applications.filter((a) => {
        return a.walkups?.length > 1
      })
    },
    singleExpand(item) {
      const indexExpandAll = this.expandAll.findIndex(
        (i) => i.eoiID === item.eoiID
      )
      if (indexExpandAll > -1) {
        this.expandAll.splice(indexExpandAll, 1)
      } else {
        this.expandAll.push(item)
      }
    },
    updatePagination() {
      this.$nextTick(() => {
        document
          .querySelector('tr td:first-child .v-input.tracking-sheet input')
          .focus()
      })
    },
    setFilteredIds(items) {
      const ids = items.map((item) => item.eoiID)
      const walkups = items.filter((a) => {
        return a.walkups.length > 1
      }).length
      walkups > 0 ? (this.enableButton = true) : (this.enableButton = false)
      this.$store.dispatch('set', ['filteredTrackingSheetIds', ids])
    },
    closeExportModal() {
      this.showExportModal = false
    },
    openExportModal() {
      this.showExportModal = true
      this.$gtm.trackEvent({
        category: 'csvApplication',
        action: 'Export',
        label: 'High school tracking list view',
        value: Object.keys(this.selectedIds).length,
        stream: 'Y67T'
      })
    },
    toggleSelectAll() {
      if (!this.allSelected) {
        const oldIds = this.selectedIds
        const newIds = this.visibleEoiIds.reduce((selected, appId) => {
          selected[appId] = true
          return selected
        }, {})
        this.selectedIds = { ...oldIds, ...newIds }
      } else {
        const removeIds = this.visibleEoiIds.reduce((selected, appId) => {
          selected[appId] = true
          return selected
        }, {})
        let oldIds = this.selectedIds
        let arrayRem = Object.keys(removeIds)
        this.selectedIds = Object.keys(oldIds)
          .filter((key) => !arrayRem.includes(key))
          .reduce((obj, key) => {
            return Object.assign(obj, {
              [key]: oldIds[key]
            })
          }, {})
      }
    },
    selectEveryIdToggle(item) {
      const ids = this.applications.map((item) => item.eoiID)
      if (item !== this.pagination.itemsLength || !this.allSelected) {
        this.idPerPage = Array(this.pagination.pageCount).fill(true)
        this.selectedIds = ids.reduce((selected, appId) => {
          selected[appId] = true
          return selected
        }, {})
      } else {
        this.idPerPage = []
        this.selectedIds = {}
      }
    },
    // clear selected applications for download pdf on change of status filter
    matchesSearchFilter(application) {
      return (
        !this.y67tSearchFilter(this.tabName) ||
        searchFilter(
          [
            application.application.student.familyName,
            application.application.student.firstName
          ],
          this.y67tSearchFilter(this.tabName)
        )
      )
    },
    filterApplications(value, search, item) {
      this.currentPage = 1
      return searchFilterForStringOnly(value, search, item)
    },
    abbreviateSchoolName(round) {
      let schoolName = round.split('|')[0]
      if (schoolName.includes('Secondary College')) {
        return schoolName.replace('Secondary College', 'SC')
      }
      if (schoolName.includes('High School')) {
        return schoolName.replace('High School', 'HS')
      }
      return schoolName ? schoolName : ''
    },
    sameSchoolClass(round) {
      const statusClasses = []
      if (round.split('|')[1]) {
        round.split('|')[1]
          ? statusClasses.push(
              'status-' + round.split('|')[1].replace(/ /g, '-').toLowerCase()
            )
          : ''
      }
      return statusClasses.join(' ')
    },
    statusBasedIcon(round) {
      return round ? Y67T_STATUS_ICONS[round.split('|')[1]] : ''
    },
    statusBasedIconClass(round) {
      const statusClasses = []
      if (round) {
        round.split('|')[1]
          ? statusClasses.push(
              'status-icon-' +
                round.split('|')[1].replace(/ /g, '-').toLowerCase()
            )
          : ''
      }
      return statusClasses.join(' ')
    },
    shortSchoolName(round) {
      let schoolName = round.split('|')[0]
      if (schoolName.length > 30) {
        if (schoolName.includes('Secondary College')) {
          return (
            schoolName.replace('Secondary College', 'SC').substring(0, 30) +
            '...'
          )
        }
        if (schoolName.includes('High School')) {
          return (
            schoolName.replace('High School', 'HS').substring(0, 30) + '...'
          )
        }
        return schoolName.substring(0, 30) + '...'
      } else {
        return this.abbreviateSchoolName(round)
      }
    },
    fullSchoolName(round) {
      return round ? round.split('|')[0] : ''
    }
  },
  computed: {
    ...mapGetters({
      schoolCode: 'selectedSchoolCode',
      y67tApplications: 'y67tApplications',
      y67tSearchFilter: 'y67tSearchFilter',
      filteredTrackingSheetIds: 'filteredTrackingSheetIds',
      noLocalSchool: 'noLocalSchool'
    }),
    trackingBreakpoint() {
      return this.$vuetify.breakpoint.width <= 1712
    },
    isOesSupport() {
      return this.$store.state.userGroup === USER_GROUP.OES_SUPPORT
    },
    isERNAdmin() {
      return this.$store.state.userGroup === USER_GROUP.ERN_ADMIN
    },
    internalSearch: {
      get() {
        return this.y67tSearchFilter(this.tabName) || ''
      },
      set(value) {
        this.selectedIds = {}
        this.idPerPage = []
        this.$store.commit('setY67tSearchFilter', {
          tabName: this.tabName,
          value: value
        })
      }
    },
    updatedApps() {
      return this.y67tApplications(this.schoolCode)
    },
    selectedSchool() {
      return this.$store.state.selectedSchool.schoolName
    },
    visibleEoiIds() {
      return this.filteredTrackingSheetIds
    },
    allSelected: {
      get() {
        const allIds = [...this.visibleEoiIds]
        return (
          Object.keys(this.selectedIds).length &&
          allIds.every((id) => this.selectedIds[id] === true)
        )
      },
      set() {}
    },
    selectedIdsForExport() {
      const keys = Object.keys(this.selectedIds)
      let selectedEOIs = []
      selectedEOIs.push(
        ['Sensitive - personal and health information'],
        [''],
        [
          'SRN',
          'Family Name',
          'First Name',
          'Gender',
          'Designated HS',
          'Status',
          'OoA high school (In progress)',
          'Status',
          'Walkup high school(s)',
          'Intended high school'
        ]
      )
      for (let k of keys) {
        if (this.selectedIds[k] === true) {
          const app = this.y67tApplications(this.schoolCode).filter(
            (a) => a.eoiID === k
          )
          if (app.length > 0) {
            //OOA schools filter based on Active, Offered and Submitted statuses
            let ooaHighSchools =
              app[0]?.ooaHighSchools?.length > 0
                ? app[0].ooaHighSchools.filter(
                    (o) =>
                      o.registrationStatus === Y67T_STATUS.ACTIVE ||
                      o.registrationStatus === Y67T_STATUS.OFFERED ||
                      o.registrationStatus === Y67T_STATUS.SUBMITTED
                  )
                : []
            let ooaHighSchool = ''
            if (ooaHighSchools?.length > 0) {
              ooaHighSchool =
                ooaHighSchools[0].schoolName +
                '|' +
                ooaHighSchools[0].registrationStatus
            }
            //Walkups filter based on Active, Offered and Submitted statuses
            let walkups = app[0]?.walkups
              ? app[0].walkups.filter(
                  (w) =>
                    w.registrationStatus === Y67T_STATUS.ACTIVE ||
                    w.registrationStatus === Y67T_STATUS.OFFERED ||
                    w.registrationStatus === Y67T_STATUS.SUBMITTED ||
                    w.registrationStatus === Y67T_STATUS.SUBMITTED_BY_PAPER ||
                    w.registrationStatus === Y67T_STATUS.SENT_TO_ERN ||
                    w.registrationStatus === Y67T_STATUS.WITHDRAWN ||
                    w.registrationStatus === Y67T_STATUS.DECLINED
                )
              : []
            walkups = walkups ? _.orderBy(walkups, 'schoolName') : []
            //Walkups details for CSV
            let walkupSchools = ''
            if (walkups?.length) {
              walkupSchools = walkups.map((w) => w.schoolName)
              walkupSchools = walkupSchools.sort().join('; ')
            }

            selectedEOIs.push([
              app[0].student.SRN,
              app[0].student.familyName,
              app[0].student.firstName,
              app[0].student.genderCode,
              app[0]?.localHighSchool?.schoolName,
              app[0]?.localHighSchool?.registrationStatus,
              ooaHighSchool.split('|')[0],
              ooaHighSchool.split('|')[1],
              walkupSchools,
              app[0]?.intendedHighSchool?.schoolName
            ])
          }
        }
      }
      return selectedEOIs
    },
    headers() {
      return [
        {
          value: 'selectAll',
          align: 'center',
          class: 'select-all',
          sortable: false
        },
        {
          text: 'Student name',
          value: 'name',
          class: 'name',
          align: 'start'
        },
        {
          text: 'SRN',
          value: 'SRN',
          class: 'srn',
          align: 'start'
        },
        {
          text: 'Designated high school',
          value: 'localHighSchool',
          class: 'designated-high-school',
          align: 'start'
        },
        {
          text: 'OoA high school (In progress)',
          value: 'ooaHighSchools',
          class: 'ooa-high-schools',
          align: 'start'
        },
        {
          text: 'Walkup high school(s)',
          value: 'walkupsMain',
          class: 'walkup',
          align: 'start'
        },
        {
          text: 'Intended high school',
          value: 'intendedHighSchool',
          class: 'intended-high-school',
          align: 'start'
        },
        {
          text: '',
          value: 'data-table-expand',
          class: 'data-table-expand',
          sortable: false,
          align: 'start'
        }
      ]
    },
    applications() {
      const applications = this.y67tApplications(this.schoolCode)
      let studentList = applications.filter(
        (app) =>
          app.applicationStatus === Y67T_STATUS.SENT_TO_HS ||
          (app.applicationStatus === Y67T_STATUS.NOT_REQUIRED &&
            app?.localHighSchool?.schoolName?.length > 0)
      )
      const applicationsMapped = studentList.map((a) => {
        // flatten application data
        const app = { ...a, ...a.application }
        app.SRN = app?.student?.SRN ? app?.student?.SRN : ''
        app.localHighSchool = app?.localHighSchool?.schoolName
          ? app.localHighSchool.schoolName +
            '|' +
            app.localHighSchool.registrationStatus
          : ''
        //OOA schools filter based on Active, Offered and Submitted statuses
        app.ooaHighSchools = app.ooaHighSchools
          ? app.ooaHighSchools.filter(
              (o) =>
                o.registrationStatus === Y67T_STATUS.ACTIVE ||
                o.registrationStatus === Y67T_STATUS.OFFERED ||
                o.registrationStatus === Y67T_STATUS.SUBMITTED
            )
          : []
        app.ooaHighSchools =
          app.ooaHighSchools?.length > 0
            ? app.ooaHighSchools[0].schoolName +
              '|' +
              app.ooaHighSchools[0].registrationStatus
            : ''
        //Walkups filter based on Active, Offered and Submitted statuses
        app.walkupsAll = []
        app.walkups = app.walkups
          ? app.walkups.filter(
              (w) =>
                w.registrationStatus === Y67T_STATUS.ACTIVE ||
                w.registrationStatus === Y67T_STATUS.OFFERED ||
                w.registrationStatus === Y67T_STATUS.SUBMITTED ||
                w.registrationStatus === Y67T_STATUS.SUBMITTED_BY_PAPER ||
                w.registrationStatus === Y67T_STATUS.SENT_TO_ERN ||
                w.registrationStatus === Y67T_STATUS.WITHDRAWN ||
                w.registrationStatus === Y67T_STATUS.DECLINED
            )
          : []
        app.walkups = app.walkups ? _.orderBy(app.walkups, 'schoolName') : []
        app.walkups?.forEach((walkup) => {
          app.walkupsAll.push(
            walkup.schoolName + '|' + walkup.registrationStatus
          )
        })
        app.walkupsMain =
          app.walkupsAll?.length > 0
            ? app.walkupsAll.length > 1
              ? 'Multiple'
              : app.walkupsAll[0]
            : ''
        //Intended highschool
        app.intendedHighSchool = app?.intendedHighSchool?.schoolName
          ? app.intendedHighSchool.schoolName
          : ''
        return app
      })

      return applicationsMapped.filter(
        (application) =>
          this.matchesSearchFilter(application) &&
          this.matchesReceivedYearFilter(application)
      )
    },
    yearOptions() {
      const getDates = []
      this.y67tApplications(this.schoolCode).filter((app) => {
        return !getDates.includes(moment(app.dateReceived).year().toString())
          ? getDates.push(moment(app.dateReceived).year().toString())
          : ''
      })
      return getDates
        .sort((a, b) => {
          return b - a
        })
        .map((d) => {
          return d + ' Calendar year'
        })
    },
    csvFileName() {
      return `year 6-7 EOI list_${currentDate}.csv`
    }
  },
  updated() {
    if (this.noLocalSchool) {
      this.$emit('refresh', true)
      this.$store.commit('setNoLocalSchool', false)
    }
  }
}
</script>

<style scoped lang="scss">
/**
 * TODO: Attention VUE 3 developers
 * Delete v-input--selection-controls class styles as part of Vue 3 / ADS 3 upgrade.
 */
::v-deep .v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.select-all {
  text-decoration: underline;
}
::v-deep .dataTable.v-data-table .v-data-table__wrapper > table > tbody {
  vertical-align: top !important;
}
.column-text {
  display: inline;
  margin-left: 3px;
}
.text-wrap-inline {
  padding-left: 1.5em;
  text-indent: -1.95em;
}
::v-deep .headerBtn .v-btn__content {
  white-space: normal !important;
  text-align: left;
  flex: initial;
}
::v-deep .v-btn:not(.v-btn--round).v-size--small {
  height: auto !important;
}
.focus-ring:focus {
  outline: 2px solid $ads-navy;
  border-radius: 3px;
}
.results-text {
  margin: 25px 30px 0 0 !important;
}
.collapse-all-button {
  margin: 13px 20px 0 0 !important;
}
::v-deep .v-ripple__container {
  width: 0 !important;
}
::v-deep .ApplicationStatusChips {
  width: 9.0625rem;
  font-weight: 700;
  .v-chip__content span,
  .v-chip__content {
    width: 100%;
    text-align: center;
  }
}
.theme--light.v-chip {
  border: 1px solid $grey-darken1;
}
.v-card__actions {
  padding: 1rem 3rem;
  background: $grey-6;
}

::v-deep .v-input.searchTxtField {
  background-color: transparent;
  input {
    border: 0;
    outline: none;
    background-color: transparent;
  }
  input::placeholder {
    color: $color-placeholder;
  }
  input:-ms-input-placeholder,
  input::-ms-input-placeholder {
    color: $color-placeholder;
  }
  //overridding clear button styles after updating to vuetify 3.2.8
  .v-input__icon--clear button {
    padding: 0;
    border: none;
  }
  .v-input__icon--clear .theme--light.v-icon:before {
    color: white;
    background-color: $color-text-light;
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
    padding: 2px;
  }
  .v-input__icon.v-input__icon--append .theme--light.v-icon {
    color: $color-secondary;
  }
}
.alert {
  color: $color-red;
}
#date-filter {
  width: 260px;
  background-color: white;
}
// data table
::v-deep .v-data-table {
  padding: 0;
  border: 0;
  .v-data-table__expand-icon {
    color: $grey-darken1;
    margin-left: -5px;
  }
  //focus styles for expand
  .v-data-table__expand-icon:focus {
    border-radius: 50%;
    color: $ads-navy;
    outline: 3px solid $ads-navy !important;
    border: none !important;
  }
  .v-data-table__expand-icon--active {
    color: $ads-navy;
    outline: none !important;
  }
  .theme--light.v-icon:focus::after {
    opacity: 0;
  }
  .v-data-table__wrapper {
    overflow-x: hidden !important;
  }
  table {
    table-layout: fixed;
    .v-data-table__empty-wrapper {
      background-color: white !important; // Prevents hover highlight on "No data available" row
      td {
        padding: 62px 0 !important; // Adds extra tall row height to "No data available" row
        color: $ads-dark-60;
      }
    }
  }
  thead.v-data-table-header {
    th[scope='col'] {
      color: $color-primary;
      font-size: 16px;
      font-weight: normal;
      padding: 20px 8px;
      vertical-align: top;
      .v-data-table-header__icon {
        color: $color-primary-lighten-1;
        margin-left: 3px;
      }
      &.active {
        font-weight: bold;
        color: $color-primary;
        .v-data-table-header__icon {
          color: $color-primary;
        }
      }
      &.select-all {
        width: 60px;
      }
      &.name {
        overflow-wrap: normal;
        word-wrap: normal;
        width: 90px;
      }
      &.srn {
        width: 110px;
      }
      &.select-all {
        width: 60px;
      }
    }
  }

  div.v-data-table__wrapper table tbody {
    tr:not(:last-child) td {
      border-bottom: 1px solid white;
      padding: 25px 8px;
    }
    tr:last-child td {
      border-bottom: 1px solid white;
      padding: 10px;
    }
    td {
      font-size: 14px;
      // overriding vuetify's data table styling
      border-bottom: 0 !important;
    }
  }
}

@include desktop {
  .ApplicationList {
    padding: 0 3rem;
    min-height: 100%;
  }

  ::v-deep .v-data-table {
    td {
      cursor: default;
    }
    tbody tr.v-data-table__expanded__content {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      vertical-align: top;
    }
    &.pending thead.v-data-table-header th.sortable.date-received {
      padding-left: 20px;
    }

    thead.v-data-table-header th.sortable {
      padding: 20px 10px;
      &.date-received {
        width: 122px;
      }
      &.name {
        overflow-wrap: normal;
        word-wrap: normal;
        width: auto;
        min-width: 120px;
      }
    }

    div.v-data-table__wrapper table tbody {
      tr:not(:last-child) td {
        padding: 20px 10px;
      }
      tr:last-child td {
        padding: 20px 10px;
      }
      tr td:first-child {
        padding-left: 20px;
      }
      tr td.status-processed {
        padding-right: 20px;
      }
    }
    .v-btn--icon.v-size--default {
      height: 32px;
      width: 32px;
    }
  }
}
#app.v-application
  button.v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
  opacity: 0;
}
body
  button:not(
    .v-expansion-panel-header,
    .no-styling,
    .v-btn--fab,
    .v-icon,
    .v-picker button,
    .v-btn--disabled,
    .stepper button
  ),
body input[type='button'] {
  margin: 0px;
}
.filterLabel {
  color: $color-placeholder;
}
.chip-label {
  min-width: 90px;
  font-weight: bold;
}
.data-table-header {
  align: right;
}

::v-deep .mdi-magnify {
  margin-top: 3px !important;
}
::v-deep .v-data-table.dataTable .searchInput {
  padding: 16px 20px !important;
}
::v-deep
  .dataTable
  table
  thead:not(.v-data-table-header-mobile)
  th:first-child {
  padding-left: 10px !important;
}
::v-deep
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ) {
  background-color: $ads-dark-60 !important;
  color: $ads-white !important;
  border: 3px solid transparent;
  .v-icon {
    color: $ads-white !important;
  }
}
.paddingText {
  width: 100%;
}
.paddingText1 {
  width: 10%;
}
.paddingText2 {
  padding-top: 1px;
  width: 90%;
}
.status-offer-expired,
.status-declined,
.status-withdrawn,
.status-not-required,
.status-inactive,
.status-not-accepted {
  text-decoration-line: line-through;
  color: $ads-dark-60;
}

.status-icon-withdrawn,
.status-icon-declined,
.status-icon-offer-expired,
.status-icon-not-required,
.status-icon-inactive,
.status-icon-not-accepted,
.status-icon-pending {
  color: $ads-dark-60;
}
.status-pending {
  color: $ads-dark-60;
}

[title='Multiple'] {
  color: $ads-dark !important;
  font-weight: bold;
}
.status-icon-active {
  color: $ads-warning-orange;
}
.status-icon-offered {
  color: $ads-primary-blue;
}
.status-icon-submitted {
  color: $ads-blue-02;
}
.status-icon-submitted-by-paper,
.status-icon-sent-to-ern {
  color: $ads-success-green;
}
.row-line {
  border-bottom: 1px solid #e0e0e0;
  margin: 0px 5px 10px 0px;
  padding: 0px;
  width: 100%;
}
.theme--light.v-btn.v-btn--outlined:focus {
  box-shadow: 0 0 0 2px $ads-navy !important;
}
.walkups-list {
  list-style-type: none;
  color: $grey-1;
  margin-top: -20px;
  margin-left: -20px;
  img {
    border-radius: 4px;
  }
  .walkup-description {
    padding-left: 5px;
  }
  .walkup-list-item {
    float: left;
    clear: left;
  }
}
</style>
