function validateAuthConfigObject(options) {
  if (!options.urls) {
    throw (
      "OAuth plugin error. You must pass 'urls' as an option for all Auth Providers." +
      "This must be an object that contains at least an 'authorise' url."
    )
  }
  if (!options.urls.authorise) {
    throw (
      "OAuth plugin error. You must pass 'urls.authorise' as an option for all Auth Providers." +
      ' This must provide the endpoint for authorisation.'
    )
  }
  if (options.params.response_type === 'code' && !options.urls.token) {
    throw "OAuth plugin error. You must pass 'urls.token' as an option for all Auth Providers that are using a response_type of 'code'."
  }
  if (!options.params.client_id) {
    throw "OAuth plugin error. You must pass 'params.client_id' as an option for all Auth Providers."
  }
}

function validateRequiredParameters({ router, authProviderConfig }) {
  if (!router) {
    throw "OAuth plugin error. You must pass the Vue Router as 'router' in options"
  }
  if (!authProviderConfig) {
    throw "OAuth plugin error. You must pass the 'authProviderConfig' in options"
  }
}

function validateOnAuthorisedRedirect(onAuthorisedRedirect) {
  if (onAuthorisedRedirect && typeof onAuthorisedRedirect !== 'function') {
    throw "OAuth plugin error. 'onAuthorisedRedirect' must be a Function if it is provided."
  }
}

function validateAppStateToPersist(appStateToPersist) {
  if (
    appStateToPersist &&
    (typeof appStateToPersist !== 'function' ||
      typeof appStateToPersist() !== 'object')
  ) {
    throw "OAuth plugin error. 'appStateToPersist' must be a Function that returns an Object if it is provided."
  }
}

function validateStoragePrefix(storagePrefix) {
  if (storagePrefix && typeof storagePrefix !== 'string') {
    throw "OAuth plugin error. 'storagePrefix' must be a String if it is provided."
  }
}

function validateParams(params) {
  const {
    authProviderConfig,
    onAuthorisedRedirect,
    appStateToPersist,
    storagePrefix
  } = params

  validateRequiredParameters(params)
  validateOnAuthorisedRedirect(onAuthorisedRedirect)
  validateAppStateToPersist(appStateToPersist)
  validateStoragePrefix(storagePrefix)
  Object.values(authProviderConfig).map((options) =>
    validateAuthConfigObject(options)
  )
}

export default validateParams
