import Vue from 'vue'
import API from '@/store/apiUtils'
import FIELD from '@/helpers/fieldHelper'
import allScholasticYears from '@/store/allScholasticYears'
import ooaHelper from './ooaDataHelper'
import {
  OOA_CHANGE_TYPES,
  STREAM,
  REF_DATA,
  OOA_EDITABLE_STATUSES
} from '@/constants'

const ooaApplicationsModule = {
  state: {
    ooaApplications: [],
    ooaApplicationOwnerApplications: []
  },
  getters: {
    ooaApplications: (state) => (schoolId) => {
      return state.ooaApplications[schoolId]
        ? state.ooaApplications[schoolId]
        : []
    },
    hasMultipleApplications: (state) =>
      state.application.applicationOwnerHasMultipleApplications,
    ooaApplicationOwner: (state, getters) => {
      return (
        getters.application &&
        getters.application.parentCarers &&
        getters.application.parentCarers.find(
          (p) => p.parentCarerEmail === getters.application.email
        )
      )
    },
    ooaApplicationOwnerApplications: (state) =>
      state.ooaApplicationOwnerApplications,
    isOoaApplicationReadOnly: (_, getters) => {
      const application = getters.application
      return (
        application.ooa &&
        OOA_EDITABLE_STATUSES.indexOf(application.applicationStatus) === -1
      )
    }
  },
  mutations: {
    setOoaApplications(state, { schoolId, applications }) {
      if (!state.ooaApplications[schoolId]) {
        // init the object
        // eslint-disable-next-line import/no-named-as-default-member
        Vue.set(state.ooaApplications, schoolId, [])
      }

      // map the data once here to avoid mapping on a computed prop in the view
      const applicationsMapped = applications.map((a) => {
        // flatten application data
        const app = { ...a, ...a.application }
        app.name = app.student.familyName + ', ' + app.student.firstName
        let year = allScholasticYears.find(
          (year) => year.value === app.scholasticYear
        )
        app.scholasticYearFormatted = year ? year.text : ''
        return app
      })
      // eslint-disable-next-line import/no-named-as-default-member
      Vue.set(state.ooaApplications, schoolId, applicationsMapped)
    },
    setOoaApplicationOwnerApplications(state, applications) {
      state.ooaApplicationOwnerApplications = applications
    }
  },
  actions: {
    getOoaApplications({ commit, getters, dispatch }) {
      const school = getters.selectedSchool

      if (!school) {
        return { studentApplications: [] }
      }

      const promises = []
      promises.push(dispatch('getSchoolOoaSettings', school.schoolCode))

      promises.push(
        API.get(
          `${process.env.VUE_APP_API_PI}/v1/ooa/applications/submitted/${school.schoolCode}`,
          false,
          { schoolCode: school.schoolCode }
        ).then((res) => {
          return res.data.studentApplications
        })
      )

      return Promise.all(promises).then((responses) => {
        const applications = responses[1]
        commit('setOoaApplications', {
          schoolId: school.schoolCode,
          applications: applications
        })
      })
    },
    getOutofAreaApplication(store, [id, schoolCode]) {
      let apiUrl = `${process.env.VUE_APP_API_PI}/v1/ooa/applications/submitted/${schoolCode}/${id}`
      const apiHeaders = { schoolCode: schoolCode }
      const currentStream = store.getters.currentStream
      if (currentStream === STREAM.ARCHIVE) {
        apiUrl = `${process.env.VUE_APP_API_PI}/v1/archived/application/${schoolCode}/${id}?applicationType=OOA`
      }

      FIELD.setStore(store)

      return new Promise((done) => {
        store.commit('set', ['isEditing', false])
        store.commit('set', ['ernRecord', null])
        store.commit('set', ['application', null])

        store.dispatch('showSpinner')

        // Gets application record...
        API.get(apiUrl, true, apiHeaders).then((response) => {
          const application = response.data.body.application
          // API Mis-design: Deeper level, different keys
          // API Mis-design: `application` instead of `oesApplication`
          store.commit('set', ['application', application])
          store.commit('set', [
            'application.lastUpdatedDateTime',
            response.data.body.lastUpdatedDateTime
          ])
          store.commit('set', [
            'application.updateEmailSent',
            response.data.body.updateEmailSent
          ])
          store.commit('set', [
            'application.updatedBy',
            response.data.body.updatedBy
          ])
          store.commit('set', [
            'application.updatedUserGroup',
            response.data.body.updatedUserGroup
          ])

          store.commit('set', [
            'application.applicationOwnerHasMultipleApplications',
            response.data.body.applicationOwnerHasMultipleApplications
          ])

          let getReferenceData = store.dispatch('getReferenceData')
          let getScholasticYears = store.dispatch('getScholasticYears')
          let getConsiderations = store.dispatch(
            'getSchoolOoaSettings',
            schoolCode
          )

          // Gets all reference data...
          Promise.all([
            getReferenceData,
            getScholasticYears,
            getConsiderations
          ]).then((data) => {
            let referenceData = data[0]
            referenceData[REF_DATA.SCHOLASTIC_YEAR] = data[1]

            store.commit('set', ['referenceData', referenceData])

            store.dispatch('displayApplication', response.data.body)
            store.dispatch('hideSpinner')

            done()
          })
        })
      })
    },
    saveApplicationStatus(
      { getters, dispatch },
      {
        applicationStatus,
        comment,
        sendEmail,
        considerations,
        declinedReason,
        changeOutcome,
        newDocuments
      }
    ) {
      const { applicationID, schoolCode } = getters.application

      const schoolCapStatus =
        getters.schoolOoaSettings(schoolCode) &&
        getters.schoolOoaSettings(schoolCode).capacity

      Vue.gtm.trackEvent({
        category: STREAM.OUT_OF_AREA,
        action: 'changeApplicationStatus',
        label: schoolCapStatus,
        value: applicationStatus,
        commentStatus: !!comment,
        documentStatus: !!(newDocuments && newDocuments.length),
        considerations,
        declinedReason
      })

      if (sendEmail != null) {
        Vue.gtm.trackEvent({
          category: STREAM.OUT_OF_AREA,
          action: 'changeApplicationStatusSendEmail',
          label: changeOutcome ? 'changeOutcome' : 'initialOutcome',
          value: sendEmail
        })
      }

      dispatch('set', ['application.applicationStatus', applicationStatus])

      const payload = {
        applicationStatus,
        comment,
        sendEmail,
        changeType: [OOA_CHANGE_TYPES.STATUS],
        changeOutcome,
        newDocuments,
        considerations,
        declinedReason
      }

      return API.put(
        `${process.env.VUE_APP_API_PI}/v1/ooa/applications/submitted/${schoolCode}/${applicationID}`,
        payload,
        false,
        { schoolCode }
      )
    },
    async saveOoaApplication(store) {
      const { applicationID, schoolCode } = store.getters.application

      if (store.getters.isSpinner) {
        return // Prevents saving while a save is already in progress
      }

      const putData = ooaHelper.preparePutData(
        store.rootState.application,
        store.rootState.cleanApplication
      )

      Vue.gtm.trackEvent({
        category: 'saveApplication',
        action: STREAM.OOA,
        label: putData.schoolName
      })

      return API.put(
        `${process.env.VUE_APP_API_PI}/v1/ooa/applications/submitted/${schoolCode}/${applicationID}`,
        {
          newApplication: {
            application: putData
          },
          changeType: [OOA_CHANGE_TYPES.APP_DATA]
        },
        false,
        { schoolCode }
      )
        .then((response) => {
          response.data.body.application.updatedBy =
            response.data.body.updatedBy
          response.data.body.application.lastUpdatedDateTime =
            response.data.body.lastUpdatedDateTime

          store.commit('set', ['application', response.data.body.application])
          store.commit('set', ['isEditing', false])
        })
        .catch(() => {
          store.commit('set', ['isEditing', false])
        })
    },
    fetchOoaApplicationHistory(store, { schoolCode, applicationId }) {
      return API.get(
        `${process.env.VUE_APP_API_PI}/v1/ooa/applications/submitted/${schoolCode}/${applicationId}/statusHistory`,
        true,
        { schoolCode }
      ).then((response) => {
        return response.data.body
      })
    },
    getApplicationOwnerApplications(store, { schoolCode, email }) {
      return API.get(
        `${process.env.VUE_APP_API_PI}/v1/ooa/applications/submitted/${schoolCode}/findByEmail?email=${email}`,
        true,
        { schoolCode }
      ).then((response) => {
        const sortedData = response.data.body
          ? response.data.body.sort((app1, app2) => {
              if (app1.dateReceived < app2.dateReceived) {
                return -1
              } else if (app1.dateReceived > app2.dateReceived) {
                return 1
              }

              const concatName = (student) =>
                (student.familyName + student.firstName).toLowerCase()
              return concatName(app1.application.student) <
                concatName(app2.application.student)
                ? -1
                : 1
            })
          : response.data.body
        store.commit('setOoaApplicationOwnerApplications', sortedData)
        return sortedData
      })
    }
  }
}

export default ooaApplicationsModule
