<!--
  Provides a dialog for selecting an address from a lookup. Fields can be entered
  manually if a lookup match cannot be found. Also shows address start & end dates (used by ERN)
-->
<template>
  <AppModal v-if="modelRow" ref="AppModal" @close="cancel()">
    <span slot="header">{{ modelRow.label }}</span>
    <div slot="body">
      <span v-if="isShowCountry" class="fieldLabel">Country</span>
      <select
        v-if="isShowCountry"
        v-model="address.countryCode"
        @change="onCountryChange()"
        placeholder="Country"
        class="country"
        ref="country"
      >
        <option
          v-for="(option, index) in getCountries"
          :key="index"
          :value="option.value"
          :disabled="option.divider"
        >
          {{ getDroplistOptionText(getCountries, option) }}
        </option>
      </select>

      <span class="fieldLabel">Address</span>
      <div class="address form">
        <TypeAhead
          @input="onInput(...arguments)"
          @search="lookupMatches(...arguments)"
          @select="selectAddress(...arguments)"
          :value="typeAheadSetValue"
          :options="getOptions"
          :show-spinner="isAustralianAddress"
          minlength="4"
          maxlength="100"
          uppercase="true"
          placeholder="Address line 1"
          ref="TypeAhead"
        />

        <input
          v-model="address.addressLine2"
          v-if="!isAustralianAddress"
          class="addressLine2"
          type="text"
          placeholder="Address line 2"
          maxlength="100"
          data-testid="address-line2"
        />
        <div
          v-if="isAustralianAddress"
          class="stateSuburb"
          data-testid="state-suburb"
        >
          <div class="suburbColumn">
            <input
              v-model="address.suburbName"
              @input="clearValidationFlag()"
              type="text"
              placeholder="Suburb"
              class="suburb"
              maxlength="100"
            />
          </div>

          <div>
            <select
              v-model="address.stateCode"
              @change="clearValidationFlag()"
              placeholder="State"
              class="state"
            >
              <option value="">Select a state</option>
              <option
                v-for="state in getStates"
                :key="state.value"
                :value="state.value"
              >
                {{ state.text }}
              </option>
            </select>
          </div>

          <div class="postcodeColumn">
            <input
              v-model="address.postCode"
              @input="clearValidationFlag()"
              type="text"
              class="postcode"
              placeholder="Postcode"
              maxlength="4"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAustralianAddress && getValidationStatus"
        :class="'addressStatus ' + getValidationStatus.toLowerCase()"
      >
        <font-awesome-icon
          v-if="getValidationStatus !== 'UNKNOWN'"
          focusable="false"
          class="circleIcon"
          icon="check"
        />
        <font-awesome-icon
          v-if="getValidationStatus === 'UNKNOWN'"
          focusable="false"
          class="circleIcon"
          icon="exclamation"
        />
        <span>{{ getStatusMessage }}</span>
      </div>
      <button
        v-else-if="isAustralianAddress"
        @click="validate"
        type="button"
        class="validate"
      >
        VALIDATE ADDRESS
      </button>
    </div>
    <div slot="footer">
      <button
        @click="ok"
        class="primary"
        type="button"
        :disabled="disableOkButton"
        ref="okButton"
      >
        OK
      </button>
      <button @click="cancel" type="button" class="cancel">Cancel</button>
    </div>
  </AppModal>
</template>

<script>
import Vue from 'vue'
import AppModal from '@/components/app/AppModal.vue'
import TypeAhead from '@/components/app/TypeAhead.vue'
import API from '@/store/apiUtils'
import UTILS from '@/store/utils'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import { STATES } from '@/applicationDefinition/droplistData/states'
import VALIDATION_RULES from '@/store/validation'
import { ADDRESS_VALIDATION, FIELD_TYPE, DATASET } from '@/constants'
import _uniqBy from 'lodash/uniqBy'

const AUSTRALIA = 'AUS'

export default {
  name: 'EditAddress',
  components: {
    AppModal,
    TypeAhead
  },
  mixins: [fieldHelperMixin],

  data() {
    return {
      addressResults: [],
      address: {},
      typeAheadSetValue: '' // Used for setting the value of the type ahead input. It does not store the current value of the input as this would lead to a feedback loop.
    }
  },

  computed: {
    modelRow() {
      return this.$store.state.editAddress
    },
    isShowCountry() {
      return this.modelRow.field.showCountry
    },
    isAustralianAddress() {
      return this.address.countryCode === AUSTRALIA
    },
    hasPoBox() {
      return this.modelRow.field.hasPoBox
    },
    getValidationStatus() {
      // Looks up status text from ADDRESS_VALIDATION json - e.g. "ACCEPTED"
      var status = Object.keys(ADDRESS_VALIDATION).find(
        (key) => ADDRESS_VALIDATION[key] === this.address.validationFlag
      )
      return status || ''
    },
    getStatusMessage() {
      var status = this.address.validationFlag
      if (status === ADDRESS_VALIDATION.UNKNOWN) {
        return `The address cannot be validated. Only continue if it is correct.`
      }
      if (status === ADDRESS_VALIDATION.ACCEPTED) {
        return `Address accepted`
      }
      return `Address validated`
    },
    currentAddress() {
      return this.modelRow.oesValue
    },
    getCountries() {
      return this.$store.getters
        .referenceData(DATASET.COUNTRIES)
        .filter((country) => !country.hidden)
    },
    getStates() {
      return STATES
    },
    disableOkButton() {
      return this.isAustralianAddress && !this.address.validationFlag
    },
    getOptions() {
      // Returns array of text options for address droplist
      return this.addressResults.map((result) => result.address)
    },
    isPostcodeInvalid() {
      var postcode = this.address.postCode
      var validation = VALIDATION_RULES.find(
        (rule) => rule.type === FIELD_TYPE.POSTCODE
      )
      return postcode && validation.isInvalid(postcode)
    },
    getValidationMessage() {
      if (this.isPostcodeInvalid) {
        return 'Please enter a valid postcode'
      }
      if (this.isAustralianAddress) {
        if (
          this.isAddressPartiallyComplete([
            'addressLine1',
            'suburbName',
            'stateCode',
            'postCode',
            'countryCode'
          ])
        ) {
          return 'Please complete all address fields'
        }
      } else if (this.isShowCountry && !this.address.countryCode) {
        return 'Please select a country'
      } else if (!this.address.addressLine1) {
        return 'Please enter an address'
      }
      return null
    }
  },

  methods: {
    onInput(value) {
      this.clearValidationFlag()
      this.addressResults = []
      this.address.addressLine1 = value
    },

    onCountryChange() {
      this.addressResults = []
    },

    lookupMatches(lookupText) {
      if (this.isAustralianAddress) {
        this.getAddressMatches(lookupText).then((results) => {
          this.addressResults = results
        })
      }
    },

    getAddressMatches(lookupText) {
      if (lookupText.length >= 4) {
        let params = {
          address: lookupText,
          maxNumberOfResults: 10,
          addressType: 'physical'
        }
        // For correspondence addresses, remove the 'physical' addressType from the params.
        if (this.hasPoBox) {
          delete params.addressType
        }
        return new Promise((resolve) => {
          API.get(
            `${process.env.VUE_APP_API_PI}/nswpoint/v2/api/predictive1`,
            true,
            {
              'x-preflight': 'force',
              noDefaultHeader: true
            },
            params
          ).then((response) => {
            var matches = []
            try {
              if (UTILS.isArray(response.data)) {
                matches = response.data
              }
            } catch (e) {
              // Nothing to do here
            }
            //NSW Point V2 API does return duplicated addresses strings as multiple datasets are being used. As a result, we need to remove the duplicates and Hemanth is going to ask whether they can be removed at the API level.
            let matchesWithoutDuplicates = _uniqBy(matches, (i) => i.address)
            resolve(matchesWithoutDuplicates)
          })
        })
      }
    },

    lookupAddress(addressId) {
      return new Promise((resolve) => {
        API.post(
          `${process.env.VUE_APP_API_PI}/nswpoint/v2/api/predictive2`,
          { id: addressId },
          false,
          { 'x-preflight': 'force', noDefaultHeader: true }
        ).then((response) => {
          try {
            resolve(response.data.data.addressDetails)
          } catch (e) {
            resolve(null)
          }
        })
      })
    },

    validateSuburbStatePostcode() {
      var suburb = escape(this.address.suburbName)
      var state = escape(this.address.stateCode)
      var postcode = escape(this.address.postCode)
      API.get(
        `/application/partialAddress?suburb=${suburb}&state=${state}&postcode=${postcode}`
      )
        .then(() => {
          this.address.validationFlag = ADDRESS_VALIDATION.ACCEPTED
          this.focusOkButton()
        })
        .catch(() => {
          this.address.validationFlag = ADDRESS_VALIDATION.UNKNOWN
          this.focusOkButton()
        })
    },

    selectAddress(index) {
      // Select an address from the lookup droplist ...
      this.$refs.TypeAhead.$refs.input.blur()
      var addressId = this.addressResults[index].id
      this.addressResults = []
      this.lookupAddress(addressId).then((lookupAddress) => {
        this.setAddressFromLookup(lookupAddress)
      })
    },

    focusOkButton() {
      // Timeout allows state to change and button to enable. Focus will fail
      // if button is still disabled.
      // eslint-disable-next-line import/no-named-as-default-member
      Vue.nextTick(() => this.$refs.okButton.focus())
    },

    focusAddress() {
      this.$refs.TypeAhead.$refs.input.focus()
    },

    getAddressLine1FromLookup(lookupAddressData) {
      // The first part of formattedAddress contains the full street address,
      // e.g. "WATERFRONT TOURIST PARK UNIT 40 18-20 BEACH PARADE"
      return lookupAddressData.formattedAddress.split(',')[0]
    },

    validate() {
      // Validates address against api and states validation status. If not 100% match,
      // validates suburb, state & postcode against api.
      if (this.isSpinner) {
        return // Quit if ajax already in progress (from hitting the button twice)
      }
      if (!this.alertInvalidFields()) {
        var a = this.address
        var lookupText = `${a.addressLine1} ${a.suburbName} ${a.stateCode} ${a.postCode}`
        API.get(
          `${process.env.VUE_APP_API_PI}/nswpoint/v2/api/addressValidation2`,
          true,
          {
            'x-preflight': 'force',
            noDefaultHeader: true
          },
          {
            address: lookupText
          }
        )
          .then((response) => {
            var info = response.data.data.properties
            if (
              info.matchQualityPercentage === '100' &&
              info.matchCertainty === 'full'
            ) {
              this.address.validationFlag = ADDRESS_VALIDATION.VALIDATED
              this.setAddressFromLookup(response.data.data.addressDetails)
            } else {
              this.validateSuburbStatePostcode()
            }
          })
          .catch(() => {
            this.validateSuburbStatePostcode()
          })
      }
    },

    setAddressFromLookup(lookupAddress) {
      this.address.addressLine1 = this.getAddressLine1FromLookup(lookupAddress)
      this.address.addressLine2 = ''
      this.address.suburbName = lookupAddress.localityName
      this.address.stateCode = lookupAddress.stateTerritory
      this.address.postCode = lookupAddress.postcode
      this.address.countryCode = AUSTRALIA
      this.address.validationFlag = ADDRESS_VALIDATION.VALIDATED
      this.setTypeAheadValue(this.address.addressLine1)
      this.focusOkButton()
    },

    setTypeAheadValue(value) {
      // To ensure that the type ahead input is always set correctly, we must first
      // clear it before setting the value. This is because the type ahead component
      // will only watch for changes in typeAheadSetValue, and if the value is the same
      // as the last value set then the component won't respond to the change.
      this.typeAheadSetValue = ''
      // eslint-disable-next-line import/no-named-as-default-member
      Vue.nextTick(() => (this.typeAheadSetValue = value))
    },

    alertInvalidFields() {
      if (this.getValidationMessage) {
        var me = this
        this.$store.dispatch('showMessageBox', {
          message: this.getValidationMessage,
          icon: 'mdi-exclamation-thick',
          onAlways() {
            me.focusAddress()
          }
        })
        return true
      }
    },

    isAddressPartiallyComplete(fieldNames) {
      // Returns true if some but not all fields specified are populated
      var empty = 0
      var complete = 0
      fieldNames.forEach((field) => {
        if (this.address[field]) {
          complete++
        } else {
          empty++
        }
      })
      return empty && complete
    },

    clearValidationFlag() {
      this.address.validationFlag = ''
    },

    ok() {
      if (!this.alertInvalidFields()) {
        var addr = this.address
        addr.addressLine2 = addr.addressLine2
          ? addr.addressLine2.toUpperCase()
          : ''
        addr.suburbName = addr.suburbName ? addr.suburbName.toUpperCase() : ''
        if (!this.isAustralianAddress) {
          addr.suburbName = null
          addr.stateCode = null
          addr.postCode = null
          addr.validationFlag = ADDRESS_VALIDATION.UNKNOWN
        }
        if (!addr.startDate) {
          addr.startDate = this.$moment().format('YYYY-MM-DD')
        }
        if (!addr.endDate) {
          addr.endDate = '2999-12-31'
        }
        this.$store.dispatch('setFieldValue', [this.modelRow.field, addr])
        this.$store.dispatch('refreshModel')
        this.close()
      }
    },

    cancel() {
      this.close()
    },

    close() {
      this.$store.dispatch('set', ['editAddress', null])
    }
  },

  created() {
    if (this.currentAddress && this.currentAddress.addressLine1) {
      // Load values from address record, if it exists...
      this.address = UTILS.clone(this.currentAddress)
    } else {
      // Set initial record state if no record
      this.address = {
        addressLine1: '',
        addressLine2: '',
        suburbName: '',
        stateCode: '',
        postCode: '',
        countryCode: AUSTRALIA,
        validationFlag: null
      }
    }
    this.typeAheadSetValue = this.address.addressLine1
  },

  mounted() {
    // NOTE: IE11 needs nextTick(). Every other browser works without it
    // eslint-disable-next-line import/no-named-as-default-member
    Vue.nextTick(() => this.focusAddress())
  }
}
</script>

<style lang="scss">
.AppModal {
  select {
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg fill='currentColor' width='1.5em' viewBox='0 0 28 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.9 1.1L8 7.2l6.1-6.1L16 2.9l-8 8-8-8 1.9-1.8z'/%3E%3Cpath fill='none' d='M2-5.8h24v24H2v-24z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 2em;
    background-size: 1.5em;
  }

  .country {
    width: 100%;
    margin: 0.25rem 0 1rem;
    background-color: $color-field-background;
    padding: 0.5em 0.75em;
    border: $field-border;
  }

  .address {
    width: 550px;
    margin: 0.25rem 0 1rem;
    border: $field-border;
    border-radius: $field-border-radius;
    background-color: $color-field-background;
    color: black;

    input,
    select {
      border: 0;
      width: 100%;
      background-color: transparent;
      border-radius: 0;
      padding: 0.5em 0.75em;
    }

    .TypeAhead,
    .addressLine2 {
      border-bottom: 1px solid $color-field-border;
    }

    .state {
      border: 1px solid $color-field-border;
      border-width: 0 1px;
    }
  }

  .addressLine2,
  .suburb {
    text-transform: uppercase;
  }
  input::placeholder {
    text-transform: none;
  }

  .stateSuburb {
    display: flex;
  }

  .postcodeColumn {
    width: 6em; // Wide enough for postcode value and "Postcode" placeholder text when empty
    input {
      text-align: center;
    }
  }

  .suburbColumn {
    flex: 1;
  }

  .validate {
    margin: 0;
  }

  .addressStatus {
    font-weight: bold;
    text-align: center;
    padding: 0.5em 0;
    display: inline-block;

    .circleIcon {
      font-size: 1.5rem;
    }
    span {
      vertical-align: middle;
    }

    &.validated {
      color: green;
      .circleIcon {
        background-color: green;
      }
    }
    &.accepted {
      color: $color-orange;
      .circleIcon {
        background-color: $color-orange;
      }
    }
    &.unknown {
      color: $color-red;
      .circleIcon {
        background-color: $color-red;
      }
    }
  }
}
</style>
