<template>
  <div v-show="state.application" :class="isEditing ? 'isEditing' : ''">
    <div class="content">
      <div class="sideContent">
        <EmptyAlerts v-if="isArchived || isShsOffer" />
        <Y67TAlerts v-else-if="isY67TApplication" data-testid="y67t-alerts" />
        <Alerts v-else-if="!isY67TApplication" v-bind="{ hideSendToErn }" />
      </div>
      <div v-if="state.application" class="mainContent">
        <ApplicationViewHeader
          :is-local-area-application="isLocalAreaApplication"
          :is-out-of-area-application="isOutOfAreaApplication"
          :is-y67-t-application="isY67TApplication"
          :is-shs-offer="isShsOffer"
        />
        <NavBar />
        <div v-if="isY67TApplication && !isArchived">
          <div class="mr-4 clear">
            <Y67TSummary
              :focus-button="focusButton"
              @focusReset="focusReset"
              @setStatus="handleY67TSetStatus"
              @withdraw="handleY67TWithdraw"
              @sendToTracking="handleY67TSendToTracking"
            />
          </div>
          <div class="clear">
            <SrnMatchStatusBar
              @setStatus="handleY67TSetStatus"
              :focus-button="focusButton"
              @focusReset="focusReset"
            />
          </div>
        </div>
        <template v-if="isLocalAreaApplication && !isArchived">
          <ApplicationContentTopAlerts
            @withdrawApplication="handleAlternateWithdraw"
            @viewErnRecord="handleViewErnRecord"
          />
          <SrnStatusBar />
          <EhubPrefillNotification v-if="isEhubLinkedStudent || parentId" />
          <SafeSummary
            v-bind="{ hideSendToErn, hideWithdraw, showInternalTransferBtn }"
          />
        </template>
        <div class="clear">
          <ApplicationHistory
            v-if="isY67TApplication"
            data-testid="y67t-app-history"
          />
        </div>
        <div
          class="card"
          :class="
            isArchived || isWithdrawn || isSentToErn || isInvalid ? 'mt-6' : ''
          "
        >
          <template v-if="isOutOfAreaApplication">
            <OoaSummary :school-code="routeSchoolCode" />
            <OoaForm />
          </template>
          <Sections v-if="isLocalAreaApplication" />
          <Y67TForm v-if="isY67TApplication && y67TApplicationType === 'EOI'" />
          <Y67TPnrForm
            v-if="isY67TApplication && y67TApplicationType === 'PNR'"
          />
        </div>
        <ShsSummary v-if="isShsOffer" :is-archived="isArchived" class="mx-12" />
      </div>
    </div>
    <ResolveConflict v-if="state.resolveConflict" />
    <ResolveLinking v-if="state.resolveLinking" />
    <SrnSearch v-if="state.showSrnSearch" />
    <SrnMatchSearch v-if="isY67TApplication && showSrnMatchSearch" />
    <EditAddress v-if="state.editAddress" />
    <SendToErnProgress v-if="state.isSendingToErn" />
    <WithdrawApplication v-if="state.openWithdrawModal" />
    <InternalTransferModal v-if="state.openInternalTransfer" />
    <UnlinkSrn v-if="state.openUnlinkSrnModal" />
    <Y67TWithdrawModal
      v-model="showY67TWithdrawnModal"
      :student-name="studentName"
      :is-pnr-advice="isY67TApplication && y67TApplicationType === 'PNR'"
    />
    <Y67TSendToERNModal v-model="showY67TSendToERNModal" />
    <SetStatusModalY67T
      v-model="showY67TSetStatusModal"
      @focus="setButtonFocus"
      :student-name="studentName"
      :parent-name="parentName"
      :status="selectedStatus"
      :is-change="isChange"
    />
    <ConfirmAddress v-if="isY67TApplication && showConfirmAddress" />
  </div>
</template>

<script>
import ApplicationViewHeader from '@/views/ApplicationViewHeader.vue'
import EmptyAlerts from '@/components/application/EmptyAlerts.vue'
import Alerts from '@/components/application/Alerts.vue'
import EditAddress from '@/components/application/EditAddress.vue'
import NavBar from '@/components/application/NavBar.vue'
import ResolveConflict from '@/components/application/ResolveConflict.vue'
import ResolveLinking from '@/components/application/ResolveLinking.vue'
import Sections from '@/components/application/Sections.vue'
import OoaForm from '@/components/application/ooa/form/Form.vue'
import Y67TForm from '@/components/application/y67t/form/Form.vue'
import Y67TPnrForm from '@/components/application/y67t/form/PnrForm.vue'
import SendToErnProgress from '@/components/application/SendToErnProgress.vue'
import SrnSearch from '@/components/application/SrnSearch.vue'
import SrnStatusBar from '@/components/application/SrnStatusBar.vue'
import EhubPrefillNotification from '@/components/application/EhubPrefillNotification.vue'
import Summary from '@/components/application/Summary.vue'
import OoaSummary from '@/components/application/OoaSummary.vue'
import Y67TSummary from '@/components/application/y67t/Y67TSummary.vue'
import ApplicationHistory from '@/components/application/y67t/ApplicationHistory.vue'
import WithdrawApplication from '@/components/application/WithdrawApplication.vue'
import InternalTransferModal from '@/components/application/InternalTransferModal.vue'
import UnlinkSrn from '@/components/application/UnlinkSrn.vue'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import SrnMatchStatusBar from '@/components/application/y67t/SrnMatchStatusBar.vue'
import SrnMatchSearch from '@/components/application/y67t/SrnMatchSearch.vue'
import Y67TWithdrawModal from '@/components/application/y67t/WithdrawModal'
import Y67TSendToERNModal from '@/components/application/y67t/SendToERNConfirmationModal'
import SetStatusModalY67T from '@/components/application/y67t/SetStatusModalY67T'
import Y67TAlerts from '@/components/application/y67t/Y67TAlerts'
import ConfirmAddress from '@/components/application/y67t/ConfirmAddress'
import ShsSummary from '@/components/application/ShsSummary.vue'
import ApplicationContentTopAlerts from '@/components/application/ApplicationContentTopAlerts.vue'
import {
  STREAM,
  Y67T_STATUS,
  ENROLMENT_TYPE,
  SHS_OFFER_STATUS,
  SHS_STATUS,
  STATUS,
  ERN_REGISTRATION_STATUS,
  SCHOOL_SELECTIVENESS
} from '@/constants'
import { mapGetters, mapState } from 'vuex'

export const SPECIAL_WITHDRAW_ACTION = {
  SHOW_MODAL: 'SHOW_MODAL',
  DONT_SHOW_MODAL: 'DONT_SHOW_MODAL'
}
export function shsRegStatusWithdrawAction({
  ernRecord: { registrationStatusCode }
}) {
  const {
    ATEINC,
    ATERSA,
    ATEVAL,
    WITLAP,
    WITPAR,
    WITSCH,
    WITDEC,
    WITCAN,
    ENRCON,
    ENRLEA,
    ENRSUS,
    LEFEXP,
    LEFDEC,
    LEFLOC,
    LEFMIS
  } = ERN_REGISTRATION_STATUS
  // risk assessed, validated, incomplete
  if ([ATEINC, ATERSA, ATEVAL].includes(registrationStatusCode))
    return SPECIAL_WITHDRAW_ACTION.SHOW_MODAL

  // Lapsed/By Parent/By School/Cancelled/Deceased
  if (
    [WITLAP, WITPAR, WITSCH, WITDEC, WITCAN]
      .concat([
        // “Enrolled” (In attendance/Suspended/Leaving) or “Left” (Expelled/Deceased)
        // A.K.A will return invalid
        ENRCON,
        ENRLEA,
        ENRSUS,
        LEFEXP,
        LEFDEC,
        LEFLOC,
        LEFMIS
      ])
      .includes(registrationStatusCode)
  )
    return SPECIAL_WITHDRAW_ACTION.DONT_SHOW_MODAL

  return
}
export default {
  name: 'ApplicationView',
  components: {
    ApplicationViewHeader,
    ApplicationHistory,
    SrnMatchSearch,
    EmptyAlerts,
    Alerts,
    EditAddress,
    NavBar,
    ResolveConflict,
    ResolveLinking,
    Sections,
    SendToErnProgress,
    SrnSearch,
    SrnStatusBar,
    SrnMatchStatusBar,
    WithdrawApplication,
    InternalTransferModal,
    UnlinkSrn,
    SafeSummary: Summary,
    OoaSummary,
    Y67TSummary,
    Y67TWithdrawModal,
    Y67TSendToERNModal,
    SetStatusModalY67T,
    Y67TAlerts,
    ConfirmAddress,
    OoaForm,
    Y67TForm,
    Y67TPnrForm,
    EhubPrefillNotification,
    ShsSummary,
    ApplicationContentTopAlerts
  },
  mixins: [fieldHelperMixin],
  data() {
    return {
      showY67TWithdrawnModal: false,
      showY67TSendToERNModal: false,
      showY67TSetStatusModal: false,
      selectedStatus: null,
      isChange: false,
      focusButton: '',
      shsOfferData: null
    }
  },
  watch: {
    concatCompareShsStatus(concatString = '') {
      if (
        !this.isArchived &&
        concatString.length > 0 &&
        concatString.includes('|')
      ) {
        const [applicationStatus, shsOfferStatus] = concatString.split('|')
        if (
          applicationStatus === STATUS.WITHDRAWN.toUpperCase() &&
          shsOfferStatus !== SHS_STATUS.WITHDRAWN.toUpperCase()
        ) {
          this.$store.dispatch('updateShsOfferEnrolmentStatus', {
            shsOffer: this.shsOfferData,
            payload: {
              status: SHS_STATUS.WITHDRAWN,
              isFromCore: true
            }
          })
        }
        if (
          applicationStatus?.toUpperCase() === STATUS.INVALID?.toUpperCase() &&
          shsOfferStatus?.toUpperCase() !== SHS_STATUS.INVALID?.toUpperCase()
        ) {
          this.$store.dispatch('updateShsOfferEnrolmentStatus', {
            shsOffer: this.shsOfferData,
            payload: {
              status: SHS_STATUS.INVALID,
              isFromCore: true
            }
          })
        }
        if (
          applicationStatus?.toUpperCase() ===
            STATUS.INTERNAL_TRANSFER?.toUpperCase() &&
          shsOfferStatus?.toUpperCase() !==
            SHS_STATUS.INTERNAL_TRANSFER?.toUpperCase()
        ) {
          this.$store
            .dispatch('updateShsOfferEnrolmentStatus', {
              shsOffer: this.shsOfferData,
              payload: {
                status: SHS_STATUS.INTERNAL_TRANSFER,
                isFromCore: true
              }
            })
            .finally(() => {
              this.$store.dispatch('set', ['openInternalTransfer', false])
            })
        }
      }
    },
    isShsApplication(val) {
      if (val === true) {
        const { shsOfferId: appShsOfferId } = this.application || {}
        const { shsOfferId: shsOfferDataId } = this.shsOfferData || {}
        if (!this.shsOfferData || appShsOfferId !== shsOfferDataId) {
          this.loadShsOfferData()
        }
      }
    }
  },
  provide() {
    return {
      shsOffer: Object.defineProperty({}, 'value', {
        get: () => this.shsOfferData
      }),
      application: Object.defineProperty({}, 'value', {
        get: () => this.application
      })
    }
  },
  computed: {
    ...mapGetters([
      'selectedSchool',
      'isEhubLinkedStudent',
      'parentId',
      'isAddressConflict',
      'showConfirmAddress',
      'showSrnMatchSearch',
      'isAddressMatched',
      'y67tSearchFilter',
      'currentStream'
    ]),
    ...mapState(['ernRecord']),
    state() {
      return this.$store.state
    },
    isDirty() {
      return (
        this.$store.getters.isEditing &&
        JSON.stringify(this.state.application) !==
          JSON.stringify(this.state.cleanApplication)
      )
    },
    application() {
      return this.$store.getters.application || {}
    },
    applicationStatus() {
      return this.application?.applicationStatus || ''
    },
    applicationWithdrawn() {
      return this.$store.state.applicationWithdrawn
    },
    isLocalAreaApplication() {
      return this.$store.state.applicationType === 'LA'
    },
    isOutOfAreaApplication() {
      return this.$store.state.applicationType === 'OOA'
    },
    isY67TApplication() {
      return this.$store.state.applicationType === 'Y67T'
    },
    isShsApplication() {
      const { enrolmentType } = this.application || {}
      return this.isLocalAreaApplication && enrolmentType === ENROLMENT_TYPE.SHS
    },
    concatCompareShsStatus() {
      if (this.application && this.shsOfferData) {
        return `${this.application?.applicationStatus?.toUpperCase()}|${this.shsOfferData?.applicationStatus?.toUpperCase()}`
      }
      return ''
    },
    isShsOffer() {
      return (
        (this.$store.state.applicationType === 'SHS' &&
          this.currentStream === STREAM.SELECTIVE_OFFERS) ||
        (this.currentStream === STREAM.ARCHIVE &&
          this.$route.params?.type === 'SHS')
      )
    },
    hideSendToErn() {
      const { offerStatus = '' } = this.shsOfferData || {}
      return (
        offerStatus.toUpperCase() === SHS_OFFER_STATUS.DECLINED.toUpperCase()
      )
    },
    hideWithdraw() {
      if (this.isShsApplication) {
        const { offerStatus } = this.shsOfferData || {}
        if (!offerStatus) return true
        return (
          offerStatus?.toUpperCase() === SHS_OFFER_STATUS.ACCEPTED.toUpperCase()
        )
      }
      return false
    },
    isSelectedSchoolPartiallySelective() {
      return (
        this.selectedSchool?.selectiveInd === SCHOOL_SELECTIVENESS.PARTIALLY
      )
    },
    showInternalTransferBtn() {
      const { offerStatus = '' } = this.shsOfferData || {}
      return (
        this.isSelectedSchoolPartiallySelective &&
        offerStatus.toUpperCase() === SHS_OFFER_STATUS.ACCEPTED.toUpperCase()
      )
    },
    y67TApplicationType() {
      if (
        this.isY67TApplication &&
        this.application.adviceType &&
        this.application.adviceType === 'PNR'
      ) {
        return 'PNR'
      }
      return 'EOI'
    },
    studentName() {
      if (this.application && this.application.student) {
        return (
          [
            this.application.student.firstName,
            this.application.student.familyName
          ]
            .join(' ')
            .trim() || 'this student'
        )
      }
      return ''
    },
    parentName() {
      if (this.application?.parentCarers) {
        return (
          [
            this.application.parentCarers[0].parentCarerGivenName,
            this.application.parentCarers[0].parentCarerFamilyName
          ]
            .join(' ')
            .trim() || 'this parent'
        )
      }
      return ''
    },
    routeSchoolCode() {
      return this.$route ? this.$route.params.schoolCode : null
    },
    isArchived() {
      return this.currentStream === STREAM.ARCHIVE
    }
  },
  methods: {
    setButtonFocus(value) {
      this.focusButton = value
    },
    focusReset() {
      this.focusButton = ''
    },
    handleViewErnRecord() {
      this.$store.dispatch('openErnWithQueryString')
    },
    handleAlternateWithdraw() {
      if (this.isShsApplication && this.isSentToErn) {
        switch (shsRegStatusWithdrawAction({ ernRecord: this.ernRecord })) {
          case SPECIAL_WITHDRAW_ACTION.SHOW_MODAL:
            this.$store.dispatch('set', ['openWithdrawModal', true])
            this.$store.dispatch('set', ['applicationWithdrawn', 'pending'])
            break
          default:
            this.$store.dispatch('withdrawApplication', false)
            break
        }
      }
    },
    handleY67TWithdraw() {
      this.showY67TWithdrawnModal = true
    },
    handleY67TSendToTracking() {
      localStorage.setItem(
        'y67tSearch',
        this.y67tSearchFilter('PRIMARYEOILIST')
      )
      const otherEOIs = this.application.otherEOIs
      if (otherEOIs && otherEOIs.length > 0) {
        this.showY67TSendToERNModal = true
      } else {
        this.$store
          .dispatch(
            'sendToTracking',
            this.$store.state.application.applicationID
          )
          .then(() => {
            this.$router.push({ path: '/' })
            const studentName = `${this.application.student.firstName} ${this.application.student.familyName}`
            const message = `<strong>${studentName}'s</strong> application has been sent to HS.`
            this.$store.dispatch('showSnackbar', {
              display: true,
              html: message,
              icon: {
                name: 'mdi-send',
                color: 'success'
              }
            })
          })
      }
    },
    handleY67TSetStatus(status) {
      status ? (this.selectedStatus = status) : (this.isChange = true)
      this.showY67TSetStatusModal = true
    },
    loadApplication(id, schoolCode) {
      if (this.isY67TApplication) {
        this.$store.commit('setIsAddressConflict', false)
        this.$store.commit('setReadyToSendErn', false)
        this.$store.commit('setIsAddressMatched', false)
      }
      if (!id || !schoolCode) {
        id = this.$route.params.id
        schoolCode = this.$route.params.schoolCode
      }

      //handle the case where userGroup info will be lost on page refresh.
      if (!this.$store.state.userGroup) {
        this.$store.dispatch('getUserGroupAndSchools')
      }

      if (schoolCode && !this.$store.getters.selectedSchool) {
        // user probably refreshed the screen or navigated via link

        const schools = this.$store.state.schools
        if (schools) {
          const selectedSchool = schools.find(
            (s) => s.schoolCode === schoolCode
          )
          if (selectedSchool) {
            this.$store.commit('set', ['selectedSchool', selectedSchool])
          } else {
            // you don't have access to this school
            this.$router.push('/')
          }
        }
      }
      const isShsOfferApplicationView =
        this.$route.name === 'ShsOfferApplicationView'
      if (id.startsWith('OOA-')) {
        // OOA application
        this.$store.dispatch('set', ['applicationType', 'OOA'])
      } else if (id.startsWith('Y67T-')) {
        // Y67T application
        this.$store.dispatch('set', ['applicationType', 'Y67T'])
        this.$store.commit('setIsAddressConflict', false)
        this.$store.commit('setReadyToSendErn', false)
        this.$store.commit('setIsAddressMatched', false)
      } else if (id.startsWith('SHS-')) {
        // SHS application
        if (
          isShsOfferApplicationView &&
          this.$store.state.application.applicationID
        ) {
          this.shsOfferData = this.$store.state.application
          id = this.shsOfferData.applicationID

          // open application in shs offer path so that we can keep back navigation working
          this.$store.dispatch('set', ['applicationType', 'LA'])
        } else {
          this.shsOfferData = null
          this.$store.dispatch('set', ['applicationType', 'SHS'])
        }
      } else {
        // Local area application
        this.$store.dispatch('set', ['applicationType', 'LA'])
      }
      this.$store.dispatch('set', ['sectionExpandedId', null]) // Collapse sections when opening an application

      this.$store.dispatch('getApplication', [id, schoolCode]).then(() => {
        if (this.isY67TApplication && this.y67TApplicationType === 'EOI') {
          this.addressValidation()
        } else {
          if (
            this.application &&
            this.application.applicationStatus === Y67T_STATUS.IN_PROGRESS &&
            this.application.student.SRN
          ) {
            this.$store.commit('setIsAddressConflict', false)
            this.$store.commit('setReadyToSendErn', true)
          }
        }
      })

      // Get all Australian schools for AuSchoolLookUp
      if (!this.$store.state.auSchools) {
        this.$store.dispatch('getAllAuSchools').then((auSchools) => {
          this.$store.commit('set', ['auSchools', auSchools.data])
        })
      }
    },
    loadShsOfferData() {
      this.$store.dispatch('showSpinner')
      return this.$store
        .dispatch('getShsOffer', [
          this.application.shsOfferId,
          this.application.schoolCode
        ])

        .then(({ data } = {}) => {
          this.shsOfferData = data.body
        })
        .catch(() => {
          this.$store.dispatch('genericApiError')
        })
        .finally(() => {
          this.$store.dispatch('hideSpinner')
        })
    },
    addressValidation() {
      if (
        this.application &&
        this.application.applicationStatus === Y67T_STATUS.IN_PROGRESS &&
        this.application.student.SRN &&
        !(this.application.adviceType && this.application.adviceType === 'PNR')
      ) {
        this.$store.dispatch('getAddressForSRN', this.application.student.SRN)
      }
    }
  },
  mounted() {
    this.loadApplication()

    // Warn if trying to navigate away with unsaved data
    var me = this
    window.onbeforeunload = function (e) {
      if (me.isDirty) {
        e.preventDefault()
        return ''
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (['ShsOfferApplicationView', 'ShsOffersView'].includes(to.name)) {
        vm.loadApplication(to.params.id, to.params.schoolCode)
      }
    })
  },
  // Necessary when switching between different applications
  beforeRouteUpdate(to, from, next) {
    this.shsOfferData = null
    this.hideSendToErn = false
    this.loadApplication(to.params.id, to.params.schoolCode)
    next()
  }
}
</script>

<style scoped lang="scss">
$header-height: 64px;

.card {
  background-color: white;
}
.content,
.sideContent,
.mainContent {
  background-color: #f5f5f5;
}
.sideContent {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background-color: white;
}

::v-deep .theme--light.v-chip[outlined='true'] {
  border: 1px solid !important;
}

// Desktop layout shows Actions as a left sidebar
@include desktop {
  .content {
    height: 100%;
    width: 100%;
  }
  .card {
    margin: 0 3rem;
  }
  .content,
  .mainContent {
    position: absolute;
  }
  .sideContent {
    position: fixed;
    height: calc(100% - #{$header-height});
    overflow-x: hidden;
    overflow-y: auto;
    top: $header-height;
    left: 0;
    width: $desktop-sidebar-width;
    padding: 1rem 1rem 1rem 2rem;
    border-bottom: 0;
  }
  .mainContent {
    top: 0;
    left: $desktop-sidebar-width;
    width: calc(100% - #{$desktop-sidebar-width});
  }

  .isEditing .sideContent {
    padding-bottom: $save-bar-height; // Extra padding for when save bar is visible, so bottom content can be reached
  }

  .isEditing .mainContent {
    padding-bottom: $save-bar-height;
  }
  .clear {
    clear: both;
  }
}
</style>
