import API from '@/store/apiUtils'

const documentUploadModule = {
  actions: {
    getSignedUrl(
      _,
      { schoolCode, applicationId, path, uploadedBy, file, action, previewMode }
    ) {
      const cleanedPath = path ? path.toString().replace(/^\/|\/$/g, '') : null

      return API.post(
        `${process.env.VUE_APP_API_PI}/v1/application/submitted/${schoolCode}/${applicationId}/generateSignedUrl`,
        {
          filename: cleanedPath ? `${cleanedPath}/${file.name}` : file.name,
          filetype: file.type,
          action,
          uploadedBy,
          previewMode
        },
        true,
        { schoolcode: schoolCode }
      ).then((resp) => {
        return resp && resp.data && resp.data.body ? resp.data.body.Url : null
      })
    },
    async uploadSupportingDocument(
      { dispatch },
      { schoolCode, applicationId, file, path, uploadedBy, progressCallback }
    ) {
      const signedUrl = await dispatch('getSignedUrl', {
        schoolCode,
        applicationId,
        path,
        file,
        uploadedBy,
        action: 'UPLOAD'
      })

      const axiosConfig = {
        onUploadProgress: progressCallback
      }
      const headers = {
        noDefaultHeader: true,
        'Content-Type': file.type
      }

      await API.put(
        signedUrl,
        file,
        true, // no loader
        headers,
        true, // ignore error handling
        axiosConfig
      )

      // extract out the path "https://domain.com/path/to/the/file.pdf?params=abc" => "path/to/the/file.pdf"
      // can't use new URL().pathname because IE11 is a baby
      const filePath = signedUrl.match(/https:\/\/[a-zA-Z0-9.\-_]+\/(.*)\?/)[1]
      return decodeURIComponent(filePath)
    },
    async deleteSupportingDocument(
      { dispatch },
      { schoolCode, applicationId, file, path }
    ) {
      const signedUrl = await dispatch('getSignedUrl', {
        schoolCode,
        applicationId,
        path,
        file,
        action: 'REMOVE'
      })

      const headers = {
        noDefaultHeader: true
      }

      return API.delete(
        signedUrl,
        null,
        true, // no loader
        headers,
        true // ignore error handling
      )
    },
    async viewSupportingDocument(
      { dispatch },
      { schoolCode, applicationId, file }
    ) {
      const signedUrl = await dispatch('getSignedUrl', {
        schoolCode,
        applicationId,
        file,
        action: 'DOWNLOAD',
        previewMode: 'INLINE'
      })
      window.open(signedUrl, '_blank')
    }
  }
}

export default documentUploadModule
