<template>
  <v-dialog
    :value="showOfferModal"
    max-width="670"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card class="bulk-offer-modal">
      <v-btn
        class="modal__close-button float-right"
        :ripple="false"
        icon
        aria-label="close"
        @click="close"
        @keydown.esc="close"
        ref="closeBtn"
        height="24"
        width="24"
      >
        <v-icon>close</v-icon>
      </v-btn>

      <template v-if="isOfferContent">
        <div class="d-flex mb-3">
          <div class="icon iconCircleBackground mr-3">
            <v-icon color="primary">mdi-school-outline</v-icon>
          </div>
          <div class="align-self-center">
            <h1
              role="alert"
              class="heading align-self-center"
              data-testid="offer-heading"
            >
              Make enrolment {{ pluralizeWord('offer') }} to
              {{
                singleApp ? studentName : `${localAppsCount} Local students`
              }}?
            </h1>
          </div>
        </div>

        <div class="email-template-container mt-0" data-testid="emailContainer">
          <p class="mb-2">
            The status of
            <strong>{{
              singleApp ? 'this local application' : 'these local applications'
            }}</strong>
            will be set to <strong>&#8220;Offered&#8221;</strong>.
          </p>
          <p class="mb-0">
            The {{ singleApp ? 'parent/carer(s)' : 'parents/carers' }} will be
            emailed instructions for accepting the offer and proceeding with a
            full enrolment application.
            <a :href="y67tEmailLink" target="_blank">View email template(s)</a>
          </p>
        </div>

        <div class="ooa-offers-info mt-4" data-testid="ooa-offers-info">
          <p class="mb-2"><strong>What about out-of-area offers?</strong></p>
          <p class="mb-0">
            Out-of-area offers must be sent individually in order to capture the
            criteria that resulted in the decision to offer a place.
          </p>
        </div>

        <AdsTextArea
          class="mt-10"
          v-model="staffComment"
          label="Comments (school use only - optional)"
          :placeholder="note"
          rows="3"
          maxlength="200"
          counter="200"
          persistent-placeholder
        />

        <SupportingDocumentUploadY67T
          class="mb-10"
          v-model="files"
          ref="supportingDocumentUploady67t"
          :is-bulk-offer-action="true"
          :bulk-offer-id="bulkOfferId"
          :is-busy.sync="isFileOperationInProgress"
        />

        <div class="actions">
          <AdsButton
            class="button"
            tertiary
            button-text="Cancel"
            @click="close"
            data-testid="cancel-btn"
          />
          <AdsButton
            icon="school"
            :ripple="false"
            class="button success"
            :button-text="`Confirm and make ${pluralizeWord('offer')}`"
            data-testid="confirm-btn"
            @click="bulkOfferConfirm"
            :disabled="isFileOperationInProgress"
          />
        </div>
      </template>

      <template v-else-if="isCountsContent">
        <div class="d-flex mb-3">
          <div class="icon iconCircleBackground mr-3">
            <v-icon color="primary">mdi-school-outline</v-icon>
          </div>
          <div class="align-self-center">
            <h1
              role="alert"
              class="heading align-self-center"
              data-testid="count-heading"
            >
              Enrolment {{ pluralizeWord('offer') }} sent
              <span class="sr-only" v-if="counts.success > 0">
                {{ counts.success }}/{{ counts.total }}
                <span v-if="singleApp">
                  {{ studentName }} has been offered a place and email sent to
                  parent(s).
                </span>
                <span v-else>
                  Local students have been offered a place <br />
                  and emails sent to parents.
                </span>
              </span>
              <span class="sr-only" v-if="counts.failure > 0">
                {{ counts.failure }} student offers failed to process. Please
                try again.
              </span>
            </h1>
          </div>
        </div>
        <div class="text-center">
          <div
            class="results-template-container"
            data-testid="resultsContainer"
            v-if="counts.success > 0"
          >
            <span class="success-numbers" data-testid="success-numbers">
              <v-icon class="success-numbers mb-2 mr-1">
                mdi-check-circle-outline
              </v-icon>
              <span class="mx-0">{{ counts.success }}/{{ counts.total }}</span>
            </span>
            <p v-if="singleApp">
              {{ studentName }} has been offered a place and email sent to
              parent(s).
            </p>
            <p v-else>
              Local students have been offered a place <br />
              and emails sent to parents.
            </p>
          </div>
          <div
            class="mt-6"
            v-if="counts.failure > 0"
            data-testid="failure-message"
            :class="{ 'failure-message': counts.success === 0 }"
          >
            <span class="error-numbers">
              <v-icon class="error-icon mb-1 mr-1">error_outline</v-icon>
              {{ counts.failure }} student offer(s) failed to process. Please
              try again.
            </span>
          </div>
        </div>
        <div
          :class="
            'final-action ' +
            (counts.success === 0 ? 'only-failure-button' : '')
          "
        >
          <AdsButton
            class="button"
            tertiary
            button-text="Close window"
            @click="close"
            data-testid="close-btn"
          />
        </div>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { AdsButton, AdsTextArea } from '@nswdoe/doe-ui-core'
import UTILS from '@/store/utils'
import { mapGetters } from 'vuex'
import SupportingDocumentUploadY67T from '@/components/application/y67t/SupportingDocumentUploadY67T'
import moment from 'moment'
export default {
  name: 'Y67TBulkOfferModal',
  components: { AdsButton, SupportingDocumentUploadY67T, AdsTextArea },
  props: {
    applications: {
      type: Array,
      default: () => []
    },
    showBulkOfferModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOfferContent: true,
      isCountsContent: false,
      attachments: [],
      counts: {
        success: 0,
        failure: 0,
        total: 0
      },
      files: [],
      staffComment: '',
      isFileOperationInProgress: false,
      bulkOfferId: null,
      showOfferModal: this.showBulkOfferModal
    }
  },
  watch: {
    showBulkOfferModal(newVal) {
      this.showModal = newVal
    }
  },
  computed: {
    ...mapGetters(['y67tEmailLink', 'selectedSchool']),
    localAppsCount() {
      return this.applications.length
    },
    singleApp() {
      return this.localAppsCount === 1
    },
    studentName() {
      return this.singleApp ? `${this.applications[0]?.studentName}` : ''
    },
    note() {
      if (this.singleApp) {
        return 'You can write the reason for your decision here. This information will not be shared with the parent/carer(s).'
      }
      return `You can write the reason for your decision here. This information will not be shared with the parents/carers.
Note: This information will appear on all ${this.localAppsCount} student records in OES.`
    }
  },
  methods: {
    pluralizeWord(word) {
      return this.singleApp ? word : `${word}s`
    },
    bulkOfferConfirm() {
      const eoiIds = this.applications.map((a) => a.applicationID)
      this.$store.dispatch('showSpinner')
      this.$store
        .dispatch('bulkOfferY67T', {
          comment: UTILS.sanitize(this.staffComment),
          attachments: this.files,
          bulkOfferId: this.bulkOfferId,
          eoiIds: eoiIds
        })
        .then((response) => {
          const responses = response?.data?.body || []
          this.counts.total = eoiIds?.length
          this.counts.success = responses.filter(
            (r) => r.status === 200
          )?.length
          this.counts.failure = responses.filter(
            (r) => r.status !== 200
          )?.length
          this.$store.dispatch('hideSpinner')
          this.isOfferContent = false
          this.isCountsContent = true
        })
    },
    close() {
      this.staffComment = ''
      this.showModal = false
      this.$emit('close', false)
      if (this.counts.total > 0) {
        this.$emit('refresh')
      }
      if (this.files) {
        this.files = []
        this.$refs.supportingDocumentUploady67t?.deleteAll()
      }
      for (
        let i = this.$refs.supportingDocumentUploady67t?._data.alerts?.length;
        i >= 0;
        i--
      ) {
        this.$refs?.supportingDocumentUploady67t?.closeError(i)
      }
    }
  },
  created() {
    this.bulkOfferId =
      this.selectedSchool?.schoolCode +
      '-' +
      moment().format('YYYYMMDDHHmmssSSS')
  }
}
</script>

<style scoped lang="scss">
.heading {
  font-size: 20px;
  padding: 10px 20px 0 0;
  color: $ads-primary-blue;
  line-height: 1.4 !important;
  display: inline;
}
.subheading {
  color: $ads-grey-01;
  font-size: 16px;
  line-height: 24px;
}
.results-template-container {
  background-color: rgba(168, 237, 179, 0.6);
  line-height: 1.5;
  padding: 26px;
  border-radius: 4px;
  margin: 40px 30px 0 30px;
  .success-numbers {
    font-size: 56px;
    font-weight: 700;
    color: $ads-success-green;
  }
}
#radio-point {
  margin-top: 16px;
}
.error-numbers {
  color: $ads-error-red;
  font-size: 16px;
  font-weight: 700;
  .error-icon {
    font-size: 27px;
    color: $ads-error-red;
  }
}
.email-template-container {
  background-color: $ads-primary-teal;
  line-height: 1.5;
  padding: 20px;
  border-radius: 4px;
  a {
    text-decoration: underline;
    color: $ads-primary-blue;
    font-weight: 400;
  }
  a:focus {
    outline: $ads-primary-blue 2px solid;
    border-radius: 2px;
  }
}

.ooa-offers-info {
  background-color: rgba(242, 242, 242, 1);
  line-height: 1.5;
  padding: 20px;
  border-radius: 4px;
}

.divider {
  margin: 28px 0px;
  color: $ads-light-40;
  border-width: 1px;
}
.actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  .theme--light.v-btn {
    padding: 20px 20px !important;
  }
}
.final-action {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.v-text-field.v-textarea {
  margin-left: 0px;
}
.icon {
  width: 45px;
  height: 45px;
  border-radius: 25px;
  background-color: $ads-primary-teal !important;
}
.bulk-offer-modal {
  padding: 1.7rem;
  min-height: 485px;
  position: relative;

  .theme--light.button.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
      .v-btn--text
    ).primary:not(.app-settings__save-button) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .modal__close-button {
    position: inherit;
    border: none;
    &:focus {
      box-shadow: 0 0 0 2px $color-primary;
    }
  }
  .modal__close-button.float-right.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
    color: $grey-darken1 !important;
  }
  ::v-deep {
    .theme--light.v-btn.v-btn--disabled:not(.v-btn--text):not(
        .v-btn--outlined
      ) {
      background-color: $ads-dark-60 !important;
      color: $ads-white !important;
    }
    textarea {
      padding: 10px 3px;
    }
    .subtext {
      font-weight: 500 !important;
    }
    .v-alert__icon.v-icon {
      margin-top: 7px;
      font-size: 30px;
    }
    .offer-note {
      margin: 20px 0px;
      line-height: 1.5;
      background-color: rgba(255, 206, 153, 0.4) !important;
      box-shadow: none !important;
      border: 2px solid #fff;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }
    .offer-note:focus {
      border: 2px solid $ads-primary-blue;
      border-radius: 4px;
    }
  }
  .theme--light.v-btn.v-btn--text:focus {
    border: 2px solid $ads-primary-blue !important;
  }
}

.only-failure-button {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.failure-message {
  position: absolute;
  top: 40%;
  left: 18%;
}

::v-deep .theme--light.v-btn.v-btn--disabled .v-icon {
  color: #fff !important;
}

.v-application .primary--text {
  color: $ads-primary-blue !important;
}
.v-application .success {
  background-color: $ads-success-green !important;
}
.digitise-options {
  width: 100%;
  border-radius: 0.25em;
  position: relative;
  &[disabled] {
    opacity: 1;
    background: transparent;
    cursor: default;
  }
  .selectedRecordPanel {
    background-color: white;
    height: 118px;
    border-radius: 4px;
    margin-bottom: 20px;
    border: 1px solid $ads-grey-03;
    box-sizing: border-box;
    font-size: 18px;
  }
  .isTicked {
    background-color: $ads-primary-teal !important;
    color: $ads-primary-blue;
    border: 2px solid $color-primary;
  }
  ::v-deep .v-radio.v-radio--is-focused .v-input--selection-controls__ripple {
    border: 2px solid $ads-navy;
    background: none;
  }
  ::v-deep .v-input--selection-controls__input {
    .v-icon {
      color: $grey-darken1;
    }
    .v-input--selection-controls__ripple:before {
      opacity: 0;
    }
  }
}
</style>
