<template>
  <div class="mx-10 py-6 grey lighten-6">
    <v-row class="pb-3">
      <v-col class="pt-4">
        <span class="filterLabel">Filter by:</span>
        <span class="pl-3">
          <ListViewFilter
            ref="offerStatusFilter"
            label="Offer status"
            type="checkbox"
            :filter-options="offerStatusFilters"
            @updatefilter="updateOfferStatusFilter"
            @menuOpened="sendFilterAnalytics"
          />
        </span>
        <span class="pl-4">
          <ListViewFilter
            ref="applicationStatusFilter"
            label="Enrolment application status"
            type="checkbox"
            width="300px"
            :filter-options="applicationStatusFilters"
            @updatefilter="updateApplicationStatusFilter"
            @menuOpened="sendFilterAnalytics"
          />
        </span>
        <ClearFilters @click="clearAllFilters()" v-if="isFiltered" />
      </v-col>
    </v-row>
    <v-card>
      <AdsDataTable
        :headers="headers"
        :items="cOffers"
        search-label="Find student"
        :search="internalSearch"
        sort-by="applicationStatusSort"
        :sort-desc="false"
        must-sort
        :items-per-page="pagination.itemsPerPage"
        :footer-props="{
          'items-per-page-options': [10, 15, 50, -1]
        }"
        :custom-filter="filterUpdates"
        @update:page="updatePagination"
        @pagination="totalResults"
        @current-items="setFilteredIds"
        ref="shsOffersList"
        :page.sync="currentPage"
        data-testid="shsOffersList"
      >
        <template #no-data>
          <NoResultsFound
            :filtered="isFiltered || Boolean(internalSearch)"
            :archive-type="ARCHIVE_TYPES.SHS.type"
          />
        </template>
        <template
          v-for="headerData in headers"
          :slot="`header.${headerData.value}`"
        >
          <button class="no-styling" :key="headerData.value">
            {{ headerData.text }}
          </button>
        </template>
        <template #top>
          <v-row class="row-line">
            <v-col>
              <v-text-field
                class="searchInput"
                v-model="internalSearch"
                prepend-inner-icon="mdi-magnify"
                label="Find student"
                single-line
                hide-details
                clearable
              />
            </v-col>
            <v-col cols="auto" class="results-text d-flex align-end pr-5">
              <span>
                {{ resultsText(pagination.itemsLength) }}
              </span>
            </v-col>
          </v-row>
        </template>
        <template slot="item" slot-scope="props">
          <!-- Table template for "out of area applications" -->
          <tr class="grey--text text--lighten-1" @click="openOffer(props.item)">
            <td class="text-xs-right">
              <router-link
                :to="generateRoute(props.item)"
                :aria-label="`${
                  props.item[DT_PROPERTIES.NAME]
                }. Open offer details.`"
              >
                {{ props.item[DT_PROPERTIES.NAME] }}
              </router-link>
            </td>

            <td class="text-sm-center text-md-left">
              <scholastic-year-cell
                :year="props.item[DT_PROPERTIES.SCHOLASTIC_YEAR]"
              ></scholastic-year-cell>
            </td>
            <td class="text-xs-right">
              {{ props.item[DT_PROPERTIES.START_DATE] }}
            </td>

            <td class="text-xs-right">
              {{ props.item[DT_PROPERTIES.OFFER_STATUS] }}
            </td>

            <td class="text-xs-right">
              {{
                props.item[DT_PROPERTIES.ENROLMENT_ISSUED_DATE]
                  | moment('DD MMM YYYY')
              }}
            </td>

            <td class="text-xs-right">
              <Chip
                small
                class="text-center font-weight-bold"
                v-bind="{
                  ...getStatusChipDetails(
                    getOfferAndAppStatus(
                      props.item[DT_PROPERTIES['APPLICATION_STATUS_SORT']]
                    )
                  )
                }"
              ></Chip>
            </td>

            <td class="text-center alert">
              <span
                v-if="
                  props.item[DT_PROPERTIES.APPLICATION_STATUS]
                    .toUpperCase()
                    .includes('DANGER')
                "
              >
                <v-icon color="red" size="30" title="Action required">
                  mdi-alert-circle
                </v-icon>
                <span class="sr-only">Action required</span>
              </span>
            </td>
          </tr>
        </template>
      </AdsDataTable>
    </v-card>
  </div>
</template>

<script>
import ListViewFilter from '@/components/app/ListViewFilter.vue'
import ScholasticYearCell from '@/components/app/ScholasticYear.vue'
import NoResultsFound from '@/components/app/NoResultsFound.vue'
import ClearFilters from './ClearFilters.vue'
import { mapGetters } from 'vuex'
import { searchFilterForStringOnly } from '@/helpers/searchHelper'
import { AdsDataTable } from '@nswdoe/doe-ui-core'
import { default as Chip } from '@/components/app/ChipNonClickable'
import {
  ARCHIVE_TYPES,
  STREAM,
  SHS_STATUS,
  SHS_OFFER_STATUS
} from '@/constants'

const DT_PROPERTIES = {
  NAME: 'name',
  SHS_OFFER_ID: 'shsOfferId',
  SCHOLASTIC_YEAR: 'scholasticYearFormatted',
  SCHOLASTIC_YEAR_RAW: 'scholasticYear',
  START_DATE: 'startDate',
  OFFER_STATUS: 'offerStatus',
  ENROLMENT_ISSUED_DATE: 'enrolmentIssuedDate',
  APPLICATION_STATUS: 'applicationStatus',
  APPLICATION_STATUS_FILTER: 'applicationStatusFilter',
  APPLICATION_STATUS_SORT: 'applicationStatusSort'
}

import { mixStatuses } from '@/mixins/shsMixins'
import listCommon from '../../mixins/listCommon'

const headers = [
  {
    text: 'Student name',
    value: DT_PROPERTIES.NAME,
    align: 'start'
  },
  {
    text: 'Scholastic year',
    value: DT_PROPERTIES.SCHOLASTIC_YEAR,
    align: '',
    sort: (a, b) => {
      return (
        (a.toUpperCase() === 'K' ? 0 : a) - (b.toUpperCase() === 'K' ? 0 : b)
      )
    }
  },
  {
    text: 'Start date',
    value: DT_PROPERTIES.START_DATE,
    align: 'start',
    class: ''
  },
  {
    text: 'Offer status',
    value: DT_PROPERTIES.OFFER_STATUS,
    align: 'start'
  },
  {
    text: 'Enrolment application issued',
    value: DT_PROPERTIES.ENROLMENT_ISSUED_DATE,
    align: 'left'
  },
  {
    text: 'Enrolment application status',
    value: DT_PROPERTIES.APPLICATION_STATUS_SORT,
    class: 'status',
    align: 'start',
    width: '260px'
  },
  {
    align: 'start',
    width: '60px',
    sortable: false
  }
]

export default {
  name: 'SelectiveOffersList',
  components: {
    Chip,
    ListViewFilter,
    AdsDataTable,
    ClearFilters,
    ScholasticYearCell,
    NoResultsFound
  },
  mixins: [mixStatuses, listCommon],
  data() {
    return {
      headers,
      internalSearch: '',
      DT_PROPERTIES,
      currentPage: 1,
      pagination: { itemsLength: 0, itemsPerPage: 50 },
      selectedOfferStatuses: [],
      selectedApplicationStatuses: [],
      currentFilteredItems: [],
      statusSortOrder: [
        `${SHS_OFFER_STATUS.DECLINED}|${SHS_STATUS.SUBMITTED}`,
        `${SHS_OFFER_STATUS.DECLINED}|${SHS_STATUS.SUBMITTED_BY_PAPER}`,
        `${SHS_OFFER_STATUS.DECLINED}|${SHS_STATUS.INTERNAL_TRANSFER}`,
        `${SHS_OFFER_STATUS.ACCEPTED}|${SHS_STATUS.ISSUED}`,
        `${SHS_OFFER_STATUS.ACCEPTED}|${SHS_STATUS.SUBMITTED}`,
        `${SHS_OFFER_STATUS.ACCEPTED}|${SHS_STATUS.SUBMITTED_BY_PAPER}`,
        `${SHS_OFFER_STATUS.ACCEPTED}|${SHS_STATUS.INTERNAL_TRANSFER}`,
        `${SHS_OFFER_STATUS.DECLINED}|${SHS_STATUS.WITHDRAWN}`,
        `${SHS_OFFER_STATUS.DECLINED}|${SHS_STATUS.INVALID}`,
        `${SHS_OFFER_STATUS.DECLINED}|${SHS_STATUS.AUTO_RETRACTED}`
      ],
      ARCHIVE_TYPES
    }
  },
  watch: {
    schoolCode() {
      this.$store.dispatch('loadShsOffers')
      this.clearAllFilters()
      this.internalSearch = ''
    },
    vxShsOffers() {
      this.shsOffers = this.vxShsOffers(this.schoolCode)
    }
  },
  methods: {
    openUpdate(id) {
      this.$router.push({
        name: 'ShsOffersView',
        params: { id, schoolCode: this.schoolCode }
      })
    },
    totalResults(pagination) {
      this.pagination = pagination
    },
    resultsText(itemsLength) {
      return itemsLength === 1
        ? itemsLength + ' result'
        : itemsLength + ' results'
    },
    updatePagination() {
      document.querySelector('tr td:first-child .v-input input').focus()
    },
    clearAllFilters() {
      this.$refs.offerStatusFilter.clearFilter()
      this.$refs.applicationStatusFilter.clearFilter()
    },
    filterUpdates(value, search, item) {
      this.currentPage = 1
      return searchFilterForStringOnly(value, search, item)
    },
    getSortOrderIndexPrepend({ applicationStatus, offerStatus }) {
      return (
        this.statusSortOrder.indexOf(`${offerStatus}|${applicationStatus}`) +
        `|${offerStatus}|${applicationStatus}`
      )
    },
    getOfferAndAppStatus(sortString) {
      return this.calculateApplicationStatus({
        applicationStatus: sortString.split('|')[2],
        offerStatus: sortString.split('|')[1]
      }).toUpperCase()
    },
    calculateApplicationStatus(offer) {
      const { applicationStatus, offerStatus } = offer
      if (
        applicationStatus === SHS_STATUS.SUBMITTED &&
        offerStatus === SHS_OFFER_STATUS.DECLINED
      ) {
        return `${applicationStatus} DANGER`
      }

      if (
        applicationStatus === SHS_STATUS.SUBMITTED_BY_PAPER &&
        offerStatus === SHS_OFFER_STATUS.DECLINED
      ) {
        return `${applicationStatus} DANGER`
      }

      if (
        applicationStatus === SHS_STATUS.INTERNAL_TRANSFER &&
        offerStatus === SHS_OFFER_STATUS.DECLINED
      ) {
        return `${applicationStatus} DANGER`
      }

      return applicationStatus
    },
    openOffer(offer) {
      this.resetFilteredList()
      this.$router.push(this.generateRoute(offer)).catch(() => {})
    },
    resetFilteredList() {
      this.$store.commit(
        'setFilteredShsOffers',
        this.currentFilteredItems.map((i) => i.id)
      )
    },
    generateRoute({ shsOfferId }) {
      return {
        name: 'ShsOffersView',
        params: { id: shsOfferId, schoolCode: this.schoolCode }
      }
    },
    updateOfferStatusFilter(selectedOptions) {
      this.selectedOfferStatuses = selectedOptions?.length
        ? selectedOptions
        : []
    },
    updateApplicationStatusFilter(selectedOptions) {
      this.selectedApplicationStatuses = selectedOptions?.length
        ? selectedOptions
        : []
    },
    matchesOfferStatusFilter(offer) {
      return (
        !this.selectedOfferStatuses.length ||
        this.selectedOfferStatuses.includes(offer[DT_PROPERTIES.OFFER_STATUS])
      )
    },
    matchesApplicationStatusFilter(offer) {
      return (
        !this.selectedApplicationStatuses.length ||
        this.selectedApplicationStatuses.includes(
          offer[DT_PROPERTIES.APPLICATION_STATUS_FILTER]
        )
      )
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    },
    sendFilterAnalytics(opened) {
      // send analytics data when each filter is closed
      if (!opened) {
        const offerStatusesData =
          this.selectedOfferStatuses.length > 0
            ? JSON.stringify(this.selectedOfferStatuses)
            : null
        const applicationStatusesData =
          this.selectedApplicationStatuses.length > 0
            ? JSON.stringify(this.selectedApplicationStatuses)
            : null
        const consolidatedData =
          `${offerStatusesData},${applicationStatusesData}`.replaceAll('"', '')
        this.$gtm.trackEvent({
          category: 'updateFilter',
          action: STREAM.SELECTIVE_OFFERS,
          label: consolidatedData
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      schoolCode: 'selectedSchoolCode',
      vxShsOffers: 'shsOffers'
    }),
    mappedOffers() {
      return (
        this.vxShsOffers(this.schoolCode)?.map((offer) => ({
          [DT_PROPERTIES.NAME]: `${offer.familyName}, ${offer.firstName}`,
          [DT_PROPERTIES.SHS_OFFER_ID]: offer.shsOfferId,
          [DT_PROPERTIES.SCHOLASTIC_YEAR]: offer.scholasticYear,
          [DT_PROPERTIES.SCHOLASTIC_YEAR_RAW]: offer.scholasticYear,
          [DT_PROPERTIES.START_DATE]: offer.startDate,
          [DT_PROPERTIES.OFFER_STATUS]: offer.offerStatus,
          [DT_PROPERTIES.ENROLMENT_ISSUED_DATE]: offer.issuedDate,
          [DT_PROPERTIES.APPLICATION_STATUS_FILTER]: this.capitalizeFirstLetter(
            offer.applicationStatus
          ),
          [DT_PROPERTIES.APPLICATION_STATUS]:
            this.calculateApplicationStatus(offer),
          [DT_PROPERTIES.APPLICATION_STATUS_SORT]:
            this.getSortOrderIndexPrepend(offer)
        })) || []
      )
    },
    cOffers() {
      return this.mappedOffers.filter(
        (offer) =>
          this.matchesOfferStatusFilter(offer) &&
          this.matchesApplicationStatusFilter(offer) &&
          (!this.internalSearch ||
            searchFilterForStringOnly(offer.name, this.internalSearch, offer))
      )
    },
    offerStatusFilters() {
      return [
        ...new Set(this.mappedOffers.map((o) => o[DT_PROPERTIES.OFFER_STATUS]))
      ].sort()
    },
    applicationStatusFilters() {
      return [
        ...new Set(
          this.mappedOffers.map(
            (o) => o[DT_PROPERTIES.APPLICATION_STATUS_FILTER]
          )
        )
      ].sort()
    },
    isFiltered() {
      return (
        Boolean(this.selectedOfferStatuses.length) ||
        Boolean(this.selectedApplicationStatuses.length)
      )
    }
  },
  created() {
    // load the list on first load
    this.$store.dispatch('loadShsOffers')
  },
  activated() {
    // refresh the list in case the status changed in the details window
    this.$store.dispatch('loadShsOffers')
  }
}
</script>

<style scoped lang="scss">
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th
  > button,
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: $ads-navy;
  font-weight: 400;
  font-size: 16px;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th.sortable.active
  button {
  font-weight: bold;
}

::v-deep .v-input.searchTxtField {
  background-color: transparent;
  input {
    border: 0;
    outline: none;
    background-color: transparent;
  }
  input::placeholder {
    color: $color-placeholder;
  }
  input:-ms-input-placeholder,
  input::-ms-input-placeholder {
    color: $color-placeholder;
  }
  //overridding clear button styles after updating to vuetify 3.2.8
  .v-input__icon--clear button {
    padding: 0;
    border: none;
  }
  .v-input__icon--clear .theme--light.v-icon:before {
    color: white;
    background-color: $color-text-light;
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
    padding: 2px;
  }
  .v-input__icon.v-input__icon--append .theme--light.v-icon {
    color: $color-secondary;
  }
}
.alert {
  color: $color-red;
}
// data table
::v-deep .v-data-table {
  padding: 0;
  border: 0;

  .v-chip__content span {
    display: inline-block;
    text-align: center;
    min-width: 135px;
    font-weight: bold;
  }
  .v-data-table__wrapper {
    overflow-x: hidden !important;
  }
  table {
    table-layout: fixed;
    .v-data-table__empty-wrapper {
      background-color: white !important; // Prevents hover highlight on "No data available" row
      td {
        padding: 62px 0 !important; // Adds extra tall row height to "No data available" row
        color: $ads-dark-60;
      }
    }
  }
  thead.v-data-table-header {
    th[scope='col'] {
      color: $color-primary;
      font-size: 16px;
      font-weight: normal;
      padding: 20px 8px;
      vertical-align: top;
      &:first-child {
        padding-left: 20px !important;
      }
      .v-btn:not(.v-btn--round).v-size--small {
        min-width: 0;
      }
      .v-data-table-header__icon {
        color: $color-primary-lighten-1;
        margin-left: 3px;
      }
    }
  }

  div.v-data-table__wrapper table tbody {
    tr:not(:last-child) td {
      border-bottom: 1px solid white;
      padding: 25px 8px;
    }
    tr:last-child td {
      border-bottom: 1px solid white;
      padding: 10px;
    }
    td {
      font-size: 14px;
      // overriding vuetify's data table styling
      border-bottom: 0 !important;
      &:last-child {
        padding-right: 20px !important;
      }
    }
  }

  div.v-data-footer {
    font-size: 14px;
  }

  @include desktop {
    td {
      cursor: default;
    }

    div.v-data-table__wrapper table tbody {
      tr:not(:last-child) td {
        padding: 20px 10px;
      }
      tr:last-child td {
        padding: 20px 10px;
      }
      tr td:first-child {
        padding-left: 20px;
      }
    }
    .v-btn--icon.v-size--default {
      height: 32px;
      width: 32px;
    }
  }
}
::v-deep
  .dataTable
  table
  thead:not(.v-data-table-header-mobile)
  th:first-child {
  padding-left: 10px !important;
}
::v-deep
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ) {
  background-color: $ads-dark-60 !important;
  color: $ads-white !important;
  border: 3px solid transparent;
  .v-icon {
    color: $ads-white !important;
  }
}
.filterLabel {
  color: $color-placeholder;
}
.row-line {
  border-bottom: 1px solid #e0e0e0;
  margin: 0px 5px 10px 0px;
  padding: 0px;
  width: 100%;
}
.chip-label {
  min-width: 90px;
  font-weight: bold;
}
.search-results-number {
  float: right;
  white-space: nowrap;
}
</style>
