import base64url from 'base64url'
import { TextEncoder } from 'text-encoding-polyfill' //for IE

export default {
  ////https://auth0.com/docs/api-auth/tutorials/nonce
  randomString(length) {
    const charset =
      '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._'
    let result = ''

    while (length > 0) {
      let bytes = new Uint8Array(16)
      const crypto = window.crypto || window.msCrypto //IE11 support
      const random = crypto.getRandomValues(bytes)

      random.forEach(function (c) {
        if (length === 0) {
          return
        }
        if (c < charset.length) {
          result += charset[c]
          length--
        }
      })
    }
    return result
  },

  //https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
  async generateHashArray(message) {
    const crypto = window.crypto || window.msCrypto //IE11 support
    const msgUint8 = new TextEncoder().encode(message) // encode as (utf-8) Uint8Array
    const hashBuffer = crypto.subtle.digest('SHA-256', msgUint8) // hash the message

    // Microsoft's implementation is not to spec, so subtle.digest uses events instead of promises and needs to be handled specially.
    if (typeof hashBuffer.then === 'function') {
      // Good browser, follows spec
      return Array.from(new Uint8Array(await hashBuffer)) // convert buffer to byte array
    } else {
      // Bad browser, does not follow spec
      return new Promise((resolve) => {
        hashBuffer.oncomplete = () => {
          resolve(Array.from(new Uint8Array(hashBuffer.result))) // convert buffer to byte array
        }
      })
    }
  },

  async generateHashHex(message) {
    const hashArray = await this.generateHashArray(message)
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string
  },

  async generateChallenge(message) {
    const hashArray = await this.generateHashArray(message)
    return base64url(hashArray)
  },

  async verifyHash(message, hash) {
    const hashedMessage = await this.generateHashHex(message)
    return hashedMessage === hash
  }
}
