<!--
  Displays a single alert within the Actions panel
-->
<template>
  <button
    class="ApplicationAlert unstyled"
    type="button"
    @click.prevent="gotoField()"
    data-test-id="application-alert"
  >
    <font-awesome-icon
      focusable="false"
      icon="exclamation-triangle"
      class="icon"
    />
    <div v-if="isCheckForSrnAlert" class="field" data-testid="is-check-for-srn">
      <span>Check for an existing SRN</span>
    </div>
    <div v-else class="field" data-testid="is-check-for-srn-else">
      <span>{{ modelRow.section.label }}{{ alertText ? ' > ' : '' }}</span
      ><span v-if="alertText" data-testid="alert-text">{{ alertText }}</span>
    </div>
  </button>
</template>

<script>
import { FIELD_TYPE } from '@/constants'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
export default {
  name: 'ApplicationAlert',
  props: {
    modelRow: {
      type: Object
    },
    isCheckForSrnAlert: {
      type: Boolean
    }
  },
  mixins: [fieldHelperMixin],
  computed: {
    alertText() {
      // Usually our alert will simply point us to a field, so we would use
      // 'modelRow.label' to show field name. However in some cases an alert might
      // refer to something that has no label, e.g. "Doctor details should be
      // present", in which case we should use 'modelRow.warningMessage'.
      return this.modelRow.label || this.modelRow.warningMessage
    }
  },
  methods: {
    gotoField() {
      if (this.isCheckForSrnAlert) {
        if (this.isEditing) {
          this.$store.dispatch('showMessageBox', {
            message: 'Please finish editing before checking for an SRN'
          })
        } else {
          this.$store.dispatch('set', ['showSrnSearch', true])
        }
        return
      }
      var modelRow = this.modelRow

      // If focusing a group or collection when editing, there will be no
      // interactive controls to focus on ("Resolve" options disappear during
      // edit mode). Instead find the first child field.
      if (
        this.isEditing &&
        (modelRow.type === FIELD_TYPE.COLLECTION ||
          modelRow.type === FIELD_TYPE.RECORD ||
          modelRow.type === FIELD_TYPE.GROUP)
      ) {
        this.$store.dispatch('setFocus', this.getFirstField(modelRow))
      } else {
        this.$store.dispatch('setFocus', modelRow)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ApplicationAlert {
  display: flex;
  width: 100%;
  font-size: 15px;
  .icon {
    margin: 0.1em 0.5em 0 0;
    color: $color-red;
  }
  .field {
    flex: 1;
  }
  .field span:last-of-type {
    text-decoration: underline;
    font-style: normal;
  }
  padding: 0.5rem 0 !important;
}
</style>
