import { ADS_Colors } from '@nswdoe/doe-ui-core'

// Global format for all dates
export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_DISPLAY_FORMAT = 'DD MMM YYYY'

// Application statuses
export const STATUS = {
  RECEIVED: 'Received',
  IN_PROGRESS: 'In Progress',
  SENT: 'Sent to ERN',
  WITHDRAWN: 'Withdrawn',
  INVALID: 'Invalid',
  INTERNAL_TRANSFER: 'Internal Transfer'
}

// This is from ADS 3 and we can use it after the Vue 3 and ADS 3 upgrade
export const ADS_BLUE_02 = '#146CFD'

export const STATUS_CHIPS = {
  [STATUS.IN_PROGRESS]: {
    text: STATUS.IN_PROGRESS.toUpperCase(),
    outlined: true,
    'text-color': ADS_Colors.PRIMARY_BLUE
  },
  [STATUS.SENT]: {
    text: STATUS.SENT.toUpperCase(),
    color: ADS_Colors.Success_Green,
    'text-color': ADS_Colors.White
  },
  [STATUS.INVALID]: {
    text: STATUS.INVALID.toUpperCase(),
    outlined: false,
    color: ADS_Colors.SECONDARY_GREY,
    'text-color': ADS_Colors.GREY_02
  },
  [STATUS.WITHDRAWN]: {
    text: STATUS.WITHDRAWN.toUpperCase(),
    outlined: false,
    color: ADS_Colors.SECONDARY_GREY,
    'text-color': ADS_Colors.GREY_02
  },
  [STATUS.RECEIVED]: {
    text: STATUS.RECEIVED.toUpperCase(),
    color: ADS_Colors.Warning_Orange,
    'text-color': ADS_Colors.White
  },
  [STATUS.INTERNAL_TRANSFER]: {
    text: STATUS.INTERNAL_TRANSFER.toUpperCase(),
    color: ADS_Colors.Success_Green,
    'text-color': ADS_Colors.White
  }
}

export const ERN_REGISTRATION_STATUS = {
  ATEINC: 'ATEINC',
  ATERSA: 'ATERSA',
  ATEVAL: 'ATEVAL',
  ENRCON: 'ENRCON',
  ENRLEA: 'ENRLEA',
  ENRSUS: 'ENRSUS',
  XFR: 'XFR',
  LEF: 'LEF',
  LEFDEC: 'LEFDEC',
  LEFMIS: 'LEFMIS',
  LEFEXP: 'LEFEXP',
  LEFLOC: 'LEFLOC',
  REGINT: 'REGINT',
  REGOFF: 'REGOFF',
  REGOTP: 'REGOTP',
  REGSTB: 'REGSTB',
  WITCAN: 'WITCAN',
  WITDEC: 'WITDEC',
  WITNOC: 'WITNOC',
  WITPAR: 'WITPAR',
  WITSCH: 'WITSCH',
  WITLAP: 'WITLAP'
}

export const OOA_CHANGE_TYPES = {
  STATUS: 'STATUS',
  APP_DATA: 'APP_DATA'
}

export const OOA_STATUS = {
  NEW: 'New',
  VIEWED: 'Viewed',
  IN_REVIEW: 'In Review',
  SHORTLISTED: 'Shortlisted',
  INTERVIEW_REQUIRED: 'Interview Required',
  WAITLISTED: 'Waitlisted',
  OFFERED: 'Offered',
  DECLINED: 'Declined',
  WITHDRAWN: 'Withdrawn',
  ACCEPTED: 'Accepted',
  NOT_ACCEPTED: 'Not Accepted',
  OFFER_EXPIRED: 'Offer Expired',
  OFFER_POST_APPEAL: 'Offer Post Appeal',
  OFFER_OTHER: 'Offer (other)',
  DECLINED_POST_APPEAL: 'Declined Post Appeal'
}

// used to shrink analytics data to keep it under 150 bytes
export const OOA_STATUS_ABBREV = {
  [OOA_STATUS.NEW]: 'N',
  [OOA_STATUS.VIEWED]: 'V',
  [OOA_STATUS.IN_REVIEW]: 'IREV',
  [OOA_STATUS.SHORTLISTED]: 'S',
  [OOA_STATUS.INTERVIEW_REQUIRED]: 'IREQ',
  [OOA_STATUS.WAITLISTED]: 'WA',
  [OOA_STATUS.OFFERED]: 'O',
  [OOA_STATUS.DECLINED]: 'D',
  [OOA_STATUS.WITHDRAWN]: 'WI',
  [OOA_STATUS.ACCEPTED]: 'A',
  [OOA_STATUS.NOT_ACCEPTED]: 'NA'
}

export const OOA_EDITABLE_STATUSES = [
  OOA_STATUS.NEW,
  OOA_STATUS.VIEWED,
  OOA_STATUS.IN_REVIEW,
  OOA_STATUS.SHORTLISTED,
  OOA_STATUS.INTERVIEW_REQUIRED,
  OOA_STATUS.WAITLISTED
]

// Creating separate statuses to allow edit application
// as they are required to save the 'sendPCEmail' field
export const OOA_STATUSES_FOR_OPT_OUT_EMAIL = [
  OOA_STATUS.DECLINED,
  OOA_STATUS.OFFERED,
  OOA_STATUS.OFFER_POST_APPEAL,
  OOA_STATUS.OFFER_OTHER,
  OOA_STATUS.DECLINED_POST_APPEAL
]

// statuses that are treated as offered
export const OOA_OFFERED_STATUSES = [
  OOA_STATUS.OFFERED,
  OOA_STATUS.OFFER_POST_APPEAL,
  OOA_STATUS.OFFER_OTHER
]

// icons to display in snackbar on update
export const OOA_STATUS_CHANGED_ICONS = {
  [OOA_STATUS.OFFERED]: 'mdi-checkbox-marked-circle',
  [OOA_STATUS.DECLINED]: 'mdi-close-circle-outline',
  [OOA_STATUS.WITHDRAWN]: 'mdi-delete-outline',
  [OOA_STATUS.ACCEPTED]: 'mdi-checkbox-marked-circle',
  [OOA_STATUS.NOT_ACCEPTED]: 'mdi-close-circle-outline',
  [OOA_STATUS.OFFER_EXPIRED]: 'mdi-close-circle-outline',
  [OOA_STATUS.OFFER_POST_APPEAL]: 'mdi-checkbox-marked-circle',
  [OOA_STATUS.OFFER_OTHER]: 'mdi-checkbox-marked-circle'
}

const OOA_OFFERED_STATUS_CHIP = {
  color: ADS_Colors.PRIMARY_BLUE,
  text: OOA_STATUS.OFFERED.toUpperCase(),
  textColor: ADS_Colors.White
}

// props to pass into ADS Chip component
export const OOA_STATUS_CHIPS = {
  [OOA_STATUS.NEW]: {
    color: ADS_Colors.Warning_Orange,
    text: OOA_STATUS.NEW.toUpperCase(),
    textColor: ADS_Colors.White
  },
  [OOA_STATUS.VIEWED]: {
    text: OOA_STATUS.VIEWED.toUpperCase(),
    outlined: true,
    textColor: ADS_Colors.PRIMARY_BLUE
  },
  [OOA_STATUS.IN_REVIEW]: {
    text: OOA_STATUS.IN_REVIEW.toUpperCase(),
    outlined: true,
    textColor: ADS_Colors.PRIMARY_BLUE
  },
  [OOA_STATUS.SHORTLISTED]: {
    text: OOA_STATUS.SHORTLISTED.toUpperCase(),
    outlined: true,
    textColor: ADS_Colors.PRIMARY_BLUE
  },
  [OOA_STATUS.INTERVIEW_REQUIRED]: {
    text: 'INTERVIEW REQ.',
    outlined: true,
    textColor: ADS_Colors.PRIMARY_BLUE
  },
  [OOA_STATUS.WAITLISTED]: {
    text: OOA_STATUS.WAITLISTED.toUpperCase(),
    outlined: true,
    textColor: ADS_Colors.PRIMARY_BLUE
  },
  [OOA_STATUS.OFFERED]: OOA_OFFERED_STATUS_CHIP,
  [OOA_STATUS.OFFER_POST_APPEAL]: OOA_OFFERED_STATUS_CHIP,
  [OOA_STATUS.OFFER_OTHER]: OOA_OFFERED_STATUS_CHIP,
  [OOA_STATUS.DECLINED]: {
    color: ADS_Colors.SECONDARY_GREY,
    text: OOA_STATUS.DECLINED.toUpperCase(),
    textColor: ADS_Colors.GREY_02
  },
  [OOA_STATUS.DECLINED_POST_APPEAL]: {
    color: ADS_Colors.SECONDARY_GREY,
    text: OOA_STATUS.DECLINED.toUpperCase(),
    textColor: ADS_Colors.GREY_02
  },
  [OOA_STATUS.WITHDRAWN]: {
    color: ADS_Colors.SECONDARY_GREY,
    text: OOA_STATUS.WITHDRAWN.toUpperCase(),
    textColor: ADS_Colors.GREY_02
  },
  [OOA_STATUS.ACCEPTED]: {
    color: ADS_Colors.Success_Green,
    text: OOA_STATUS.ACCEPTED.toUpperCase(),
    textColor: ADS_Colors.White
  },
  [OOA_STATUS.NOT_ACCEPTED]: {
    color: ADS_Colors.SECONDARY_GREY,
    text: OOA_STATUS.NOT_ACCEPTED.toUpperCase(),
    textColor: ADS_Colors.GREY_02
  },
  [OOA_STATUS.OFFER_EXPIRED]: {
    color: ADS_Colors.SECONDARY_GREY,
    text: OOA_STATUS.OFFER_EXPIRED.toUpperCase(),
    textColor: ADS_Colors.GREY_02
  },
  default: {
    color: ADS_Colors.White,
    outlined: true
  }
}

export const OOA_STATUS_CHIPS_DETAILS_PAGE = {
  [OOA_STATUS.NEW]: {
    color: ADS_Colors.Warning_Orange,
    text: OOA_STATUS.NEW.toUpperCase(),
    textColor: ADS_Colors.White
  },
  [OOA_STATUS.VIEWED]: {
    text: OOA_STATUS.VIEWED.toUpperCase(),
    color: ADS_Colors.White,
    textColor: ADS_Colors.PRIMARY_BLUE
  },
  [OOA_STATUS.IN_REVIEW]: {
    text: OOA_STATUS.IN_REVIEW.toUpperCase(),
    color: ADS_Colors.White,
    textColor: ADS_Colors.PRIMARY_BLUE
  },
  [OOA_STATUS.SHORTLISTED]: {
    text: OOA_STATUS.SHORTLISTED.toUpperCase(),
    color: ADS_Colors.White,
    textColor: ADS_Colors.PRIMARY_BLUE
  },
  [OOA_STATUS.INTERVIEW_REQUIRED]: {
    text: 'INTERVIEW REQ.',
    textColor: ADS_Colors.PRIMARY_BLUE,
    color: ADS_Colors.White
  },
  [OOA_STATUS.WAITLISTED]: {
    text: OOA_STATUS.WAITLISTED.toUpperCase(),
    color: ADS_Colors.White,
    textColor: ADS_Colors.PRIMARY_BLUE
  },
  [OOA_STATUS.OFFERED]: OOA_OFFERED_STATUS_CHIP,
  [OOA_STATUS.OFFER_POST_APPEAL]: OOA_OFFERED_STATUS_CHIP,
  [OOA_STATUS.OFFER_OTHER]: OOA_OFFERED_STATUS_CHIP,
  [OOA_STATUS.DECLINED]: {
    color: ADS_Colors.SECONDARY_GREY,
    text: OOA_STATUS.DECLINED.toUpperCase(),
    textColor: ADS_Colors.GREY_02
  },
  [OOA_STATUS.DECLINED_POST_APPEAL]: {
    color: ADS_Colors.SECONDARY_GREY,
    text: OOA_STATUS.DECLINED.toUpperCase(),
    textColor: ADS_Colors.GREY_02
  },
  [OOA_STATUS.WITHDRAWN]: {
    color: ADS_Colors.SECONDARY_GREY,
    text: OOA_STATUS.WITHDRAWN.toUpperCase(),
    textColor: ADS_Colors.GREY_02
  },
  [OOA_STATUS.ACCEPTED]: {
    color: ADS_Colors.Success_Green,
    text: OOA_STATUS.ACCEPTED.toUpperCase(),
    textColor: ADS_Colors.White
  },
  [OOA_STATUS.NOT_ACCEPTED]: {
    color: ADS_Colors.SECONDARY_GREY,
    text: OOA_STATUS.NOT_ACCEPTED.toUpperCase(),
    textColor: ADS_Colors.GREY_02
  },
  [OOA_STATUS.OFFER_EXPIRED]: {
    color: ADS_Colors.SECONDARY_GREY,
    text: OOA_STATUS.OFFER_EXPIRED.toUpperCase(),
    textColor: ADS_Colors.GREY_02
  },
  default: {
    color: ADS_Colors.White,
    outlined: true
  }
}

const OOA_OFFERED_STATUS_CHIP_DARK = {
  text: OOA_STATUS.OFFERED.toUpperCase(),
  outlined: true,
  color: ADS_Colors.White
}

export const OOA_STATUS_CHIPS_DARK_BACKGROUND = {
  [OOA_STATUS.OFFERED]: OOA_OFFERED_STATUS_CHIP_DARK,
  [OOA_STATUS.OFFER_POST_APPEAL]: OOA_OFFERED_STATUS_CHIP_DARK,
  [OOA_STATUS.OFFER_OTHER]: OOA_OFFERED_STATUS_CHIP_DARK
}

export const OOA_LEGEND_STATUS = [
  {
    text: 'Application specifies one or more priority criteria for consideration.',
    icon: 'mdi-chevron-double-up',
    color: ADS_Colors.PRIMARY_RED
  }
]

// Y67T
export const Y67T_FLAGS_TAGS = {
  flags: {
    BLUE: 'Multiple enrolments',
    GREEN: 'Current risk assessment dossier',
    GREEN_STRIKE_THROUGH: 'Historical risk assessment dossier',
    GREY: 'Past behaviour',
    RED: 'Current behaviour',
    YELLOW: 'Current PL&SP dossier',
    YELLOW_STRIKE_THROUGH: 'Historical PL&SP dossier',
    ORANGE: 'Health condition',
    ORANGE_STOP: 'Health condition',
    ORANGE_EXCLAMATION_MARK: 'Health condition',
    PINK: 'Existing parental dossier'
  },
  tags: {
    TMP: 'Temporary resident',
    NA: 'Non-Australian citizens',
    NO: 'Placement not required',
    SC: 'Year 6 support class student',
    SH: 'Selective High school applicant',
    VA: 'Verbal Advice',
    PF: 'From paper form',
    SIB: 'Sibling at', // adding school name
    CPS: 'Primary school'
  }
}

export const Y67T_EVENT_ACTIONS = {
  SEND_TO_TRACKING: 'Send to Tracking',
  CORE_SENT_TO_ERN_HS: 'Core Sent To Ern HS'
}
// This is the BE status
export const Y67T_BE_STATUS = {
  SENT: 'Sent to ERN',
  SENT_TO_ERN_HS: 'Sent To ERN HS'
}

export const Y67T_STATUS = {
  NEW: 'New',
  VIEWED: 'Viewed',
  IN_PROGRESS: 'In Progress',
  SENT_TO_HS: 'Sent To HS', // This status from BE is Send to ERN
  SENT_TO_ERN: 'Sent To ERN', // This status from BE is Send To ERN HS
  ACCEPTED: 'Accepted',
  OFFERED: 'Offered',
  NOT_REQUIRED: 'Not Required',
  DECLINED: 'Declined',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  NOT_ACCEPTED: 'Not Accepted',
  PENDING: 'Pending',
  SUBMITTED: 'Submitted',
  SUBMITTED_BY_PAPER: 'Submitted By Paper',
  OFFER_EXPIRED: 'Offer Expired',
  WITHDRAWN: 'Withdrawn'
}
// BE status is different from FE
export const Y67T_STATUS_MAPPING = {
  [Y67T_BE_STATUS.SENT_TO_ERN_HS]: Y67T_STATUS.SENT_TO_ERN,
  [Y67T_BE_STATUS.SENT]: Y67T_STATUS.SENT_TO_HS
}

export const Y67T_STATUS_SORTING_ORDER = [
  Y67T_STATUS.ACTIVE,
  Y67T_STATUS.PENDING,
  Y67T_STATUS.OFFERED,
  Y67T_STATUS.SUBMITTED,
  Y67T_STATUS.SUBMITTED_BY_PAPER,
  Y67T_STATUS.SENT_TO_ERN,
  Y67T_STATUS.NOT_ACCEPTED,
  Y67T_STATUS.OFFER_EXPIRED,
  Y67T_STATUS.INACTIVE,
  Y67T_STATUS.DECLINED,
  Y67T_STATUS.NOT_REQUIRED,
  Y67T_STATUS.WITHDRAWN
]

export const Y67T_STATUS_ICONS = {
  [Y67T_STATUS.ACTIVE]: 'mdi-play-circle',
  [Y67T_STATUS.PENDING]: 'hourglass_empty',
  [Y67T_STATUS.SUBMITTED]: 'mdi-check-circle',
  [Y67T_STATUS.SUBMITTED_BY_PAPER]: 'mdi-send-circle',
  [Y67T_STATUS.SENT_TO_ERN]: 'mdi-send-circle',
  [Y67T_STATUS.OFFERED]: 'outbound',
  [Y67T_STATUS.WITHDRAWN]: 'not_interested',
  [Y67T_STATUS.OFFER_EXPIRED]: 'not_interested',
  [Y67T_STATUS.INACTIVE]: 'not_interested',
  [Y67T_STATUS.DECLINED]: 'mdi-close-circle-outline',
  [Y67T_STATUS.NOT_ACCEPTED]: 'mdi-close-circle-outline',
  [Y67T_STATUS.NOT_REQUIRED]: 'mdi-minus-circle-outline',

  default: ''
}

export const Y67T_STATUS_CHIPS = {
  [Y67T_STATUS.NEW]: {
    text: 'NEW',
    color: ADS_Colors.Warning_Orange,
    textColor: ADS_Colors.White
  },
  [Y67T_STATUS.VIEWED]: {
    text: 'VIEWED',
    color: ADS_Colors.Light_Blue,
    textColor: ADS_Colors.Navy
  },
  [Y67T_STATUS.IN_PROGRESS]: {
    text: 'IN PROGRESS',
    outlined: true,
    color: ADS_Colors.PRIMARY_BLUE
  },
  [Y67T_STATUS.WITHDRAWN]: {
    text: 'WITHDRAWN',
    color: ADS_Colors.GREY_04,
    textColor: ADS_Colors.GREY_02
  },
  [Y67T_STATUS.SENT_TO_HS]: {
    text: 'SENT TO HS',
    color: ADS_Colors.Success_Green,
    outlined: false,
    textColor: ADS_Colors.White
  },
  [Y67T_STATUS.SENT_TO_ERN]: {
    text: 'SENT TO ERN',
    color: ADS_Colors.Success_Green,
    outlined: false,
    textColor: ADS_Colors.White
  },
  [Y67T_STATUS.ACCEPTED]: {
    text: 'ACCEPTED',
    color: ADS_Colors.Success_Green,
    textColor: ADS_Colors.White
  },
  [Y67T_STATUS.OFFERED]: {
    text: 'OFFERED',
    color: ADS_Colors.PRIMARY_BLUE,
    textColor: ADS_Colors.White
  },
  [Y67T_STATUS.NOT_REQUIRED]: {
    text: 'NOT REQUIRED',
    color: ADS_Colors.GREY_04,
    textColor: ADS_Colors.GREY_02
  },
  [Y67T_STATUS.DECLINED]: {
    text: 'DECLINED',
    color: ADS_Colors.GREY_04,
    textColor: ADS_Colors.GREY_02
  },
  [Y67T_STATUS.ACTIVE]: {
    text: 'ACTIVE',
    color: ADS_Colors.Warning_Orange,
    textColor: ADS_Colors.White
  },
  [Y67T_STATUS.INACTIVE]: {
    text: 'INACTIVE',
    color: ADS_Colors.GREY_04,
    textColor: ADS_Colors.GREY_02
  },
  [Y67T_STATUS.NOT_ACCEPTED]: {
    text: 'NOT ACCEPTED',
    color: ADS_Colors.GREY_04,
    textColor: ADS_Colors.GREY_02
  },
  [Y67T_STATUS.PENDING]: {
    text: 'PENDING',
    color: ADS_Colors.PRIMARY_BLUE,
    outlined: true
  },
  [Y67T_STATUS.SUBMITTED]: {
    text: 'SUBMITTED',
    color: ADS_BLUE_02,
    textColor: ADS_Colors.White
  },
  [Y67T_STATUS.SUBMITTED_BY_PAPER]: {
    text: 'SUBMITTED BY PAPER',
    color: ADS_Colors.Success_Green,
    textColor: ADS_Colors.White
  },
  [Y67T_STATUS.OFFER_EXPIRED]: {
    text: 'OFFER EXPIRED',
    color: ADS_Colors.GREY_04,
    textColor: ADS_Colors.GREY_02
  },
  default: {
    color: ADS_Colors.Navy,
    outlined: true
  }
}

export const Y67T_STATUS_CHIPS_DARK_BACKGROUND = {
  [Y67T_STATUS.NEW]: {
    text: 'NEW',
    color: ADS_Colors.Warning_Orange,
    textColor: ADS_Colors.White
  },
  [Y67T_STATUS.PENDING]: {
    text: 'PENDING',
    color: ADS_Colors.White,
    textColor: ADS_Colors.PRIMARY_BLUE
  },
  [Y67T_STATUS.OFFERED]: {
    text: 'OFFERED',
    color: ADS_Colors.White,
    outlined: true
  },
  [Y67T_STATUS.IN_PROGRESS]: {
    text: 'IN PROGRESS',
    outlined: false,
    color: ADS_Colors.White,
    textColor: ADS_Colors.PRIMARY_BLUE
  }
}

export const Y67T_EDITABLE_STATUSES = [Y67T_STATUS.NEW]

export const Y67T_STATUS_HEADER_COUNT = [
  {
    // To break the text into two lines without using v-html
    text: ['Active and', 'requiring action'],
    color: ADS_Colors.Warning_Orange,
    icon: 'mdi-play-circle',
    status: Y67T_STATUS.ACTIVE
  },
  {
    text: ['Offered and', 'awaiting reply'],
    color: ADS_Colors.PRIMARY_BLUE,
    icon: 'outbound',
    status: Y67T_STATUS.OFFERED
  },
  {
    text: ['Submitted and', 'to be processed'],
    color: ADS_BLUE_02,
    icon: 'mdi-check-circle',
    status: Y67T_STATUS.SUBMITTED
  },
  {
    text: ['In ERN and', 'not withdrawn'],
    color: ADS_Colors.Success_Green,
    icon: 'mdi-send-circle',
    // InERN included the SUBMITTED_BY_PAPER and SENT_TO_ERN
    status: 'InERN'
  }
]

export const Y67T_LEGEND_STATUS = [
  {
    text: 'Active',
    icon: 'mdi-play-circle',
    color: ADS_Colors.Warning_Orange
  },
  {
    text: 'Offered',
    icon: 'outbound',
    color: ADS_Colors.PRIMARY_BLUE
  },
  {
    text: 'Submitted',
    icon: 'mdi-check-circle',
    color: ADS_BLUE_02
  },
  {
    text: 'Sent to ERN or Submitted by paper',
    icon: 'mdi-send-circle',
    color: ADS_Colors.Success_Green
  },
  {
    text: 'Placement not required',
    icon: 'mdi-minus-circle-outline',
    color: ADS_Colors.Dark_60
  },
  {
    text: 'Pending',
    icon: 'hourglass_empty',
    color: ADS_Colors.Dark_60
  },
  {
    text: 'Declined or Not accepted',
    icon: 'mdi-close-circle-outline',
    color: ADS_Colors.Dark_60
  },
  {
    text: 'Inactive or Withdrawn or Offer expired',
    icon: 'not_interested',
    color: ADS_Colors.Dark_60
  }
]
// Student detail update (SDU) constants
export const SDU_STATUS = {
  NEW: 'NEW',
  IN_PROGRESS: 'IN PROGRESS',
  SENT: 'SENT TO ERN',
  DECLINED: 'DECLINED'
}

export const SDU_STATUS_SORTING_ORDER = [
  SDU_STATUS.NEW,
  SDU_STATUS.IN_PROGRESS,
  SDU_STATUS.SENT,
  SDU_STATUS.DECLINED
]

export const SDU_TABS = {
  PENDING: 'PENDING',
  PROCESSED: 'PROCESSED'
}

export const SDU_TAB_FILTERING = {
  PENDING: [SDU_STATUS.NEW, SDU_STATUS.IN_PROGRESS],
  PROCESSED: [SDU_STATUS.SENT, SDU_STATUS.DECLINED]
}

export const SDU_STATUS_ICONS = {
  default: ''
}

export const SDU_STATUS_CHIPS = {
  [SDU_STATUS.NEW]: {
    text: 'NEW',
    color: ADS_Colors.Warning_Orange,
    textColor: ADS_Colors.White
  },
  [SDU_STATUS.IN_PROGRESS]: STATUS_CHIPS[STATUS.IN_PROGRESS],
  [SDU_STATUS.SENT]: {
    text: 'SENT TO ERN',
    color: ADS_Colors.Success_Green,
    textColor: ADS_Colors.White
  },
  [SDU_STATUS.DECLINED]: {
    text: 'DECLINED',
    color: ADS_Colors.GREY_04,
    textColor: ADS_Colors.GREY_02
  }
}

export const STATUS_CHIPS_DARK_BACKGROUND = {
  [STATUS.IN_PROGRESS]: {
    text: STATUS.IN_PROGRESS.toUpperCase(),
    outlined: false,
    color: ADS_Colors.White,
    textColor: ADS_Colors.PRIMARY_BLUE
  },
  [SDU_STATUS.IN_PROGRESS]: {
    text: STATUS.IN_PROGRESS.toUpperCase(),
    outlined: false,
    color: ADS_Colors.White,
    textColor: ADS_Colors.PRIMARY_BLUE
  }
}

// Enrolment statuses to filter search srn records for SRE/SEE
export const SDU_ERN_FILTER_STATUSES = [
  'LEF',
  'LEFDEC',
  'LEFEXP',
  'LEFLOC',
  'LEFMIS',
  'WITCAN',
  'WITDEC',
  'WITLAP',
  'WITNOC',
  'WITPAR',
  'WITSCH'
]

// SHS Statuses
export const SHS_STATUS = {
  SUBMITTED_DANGER: 'SUBMITTED DANGER',
  SUBMITTED_IN_ERN_DANGER: 'SUBMITTED IN ERN DANGER',
  ISSUED: 'ISSUED',
  SUBMITTED: 'SUBMITTED',
  SUBMITTED_IN_ERN: 'SUBMITTED IN ERN',
  WITHDRAWN: 'WITHDRAWN',
  WITHDRAWN_IN_ERN: 'WITHDRAWN IN ERN',
  AUTO_RETRACTED: 'AUTO RETRACTED',
  SUBMITTED_BY_PAPER: 'SUBMITTED BY PAPER',
  SUBMITTED_BY_PAPER_DANGER: 'SUBMITTED BY PAPER DANGER',
  INTERNAL_TRANSFER_DANGER: 'INTERNAL TRANSFER DANGER',
  INTERNAL_TRANSFER: 'INTERNAL TRANSFER',
  INVALID: 'INVALID'
}
export const SHS_OFFER_STATUS = {
  ACCEPTED: 'Accepted',
  DECLINED: 'Declined'
}

export const SHS_APPLICATION_STATUS_CHIPS = {
  ISSUED: {
    text: 'ISSUED',
    color: ADS_Colors.PRIMARY_BLUE,
    outlined: true,
    textColor: ADS_Colors.PRIMARY_BLUE
  },
  SUBMITTED: {
    text: 'SUBMITTED',
    color: ADS_Colors.Success_Green,
    textColor: ADS_Colors.White
  },
  SUBMITTED_BY_PAPER: {
    text: 'SUBMITTED BY PAPER',
    color: ADS_Colors.Success_Green,
    textColor: ADS_Colors.White
  },
  SUBMITTED_BY_PAPER_DANGER: {
    text: 'SUBMITTED BY PAPER',
    color: 'red',
    textColor: ADS_Colors.White
  },
  INTERNAL_TRANSFER_DANGER: {
    text: 'INTERNAL TRANSFER',
    color: 'red',
    textColor: ADS_Colors.White
  },
  SUBMITTED_DANGER: {
    text: 'SUBMITTED',
    color: 'red',
    textColor: ADS_Colors.White
  },
  SUBMITTED_IN_ERN: {
    text: 'SUBMITTED IN ERN',
    color: ADS_Colors.Success_Green,
    textColor: ADS_Colors.White
  },
  SUBMITTED_IN_ERN_DANGER: {
    text: 'SUBMITTED IN ERN',
    color: ADS_Colors.PRIMARY_RED,
    textColor: ADS_Colors.White
  },
  WITHDRAWN: {
    text: 'WITHDRAWN',
    color: ADS_Colors.GREY_04,
    textColor: ADS_Colors.GREY_02
  },
  AUTO_RETRACTED: {
    text: 'AUTO RETRACTED',
    color: ADS_Colors.GREY_04,
    textColor: ADS_Colors.GREY_02
  },
  INTERNAL_TRANSFER: {
    text: 'INTERNAL TRANSFER',
    color: ADS_Colors.Success_Green,
    textColor: ADS_Colors.White
  },
  INVALID: {
    text: 'INVALID',
    color: ADS_Colors.GREY_04,
    textColor: ADS_Colors.GREY_02
  }
}

// Application field types (see README.md)
export const FIELD_TYPE = {
  HEADING: 'HEADING',
  LINEBREAK: 'LINEBREAK',
  COLLECTION: 'COLLECTION',
  GROUP: 'GROUP',
  TEXTAREA: 'TEXTAREA',
  DROPLIST: 'DROPLIST',
  DATE: 'DATE',
  MONTH_YEAR: 'MONTH_YEAR',
  MM_YYYY: 'MM_YYYY',
  RADIO: 'RADIO',
  POSTCODE: 'POSTCODE',
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  NAME: 'NAME',
  ADDRESS: 'ADDRESS',
  CUSTOM: 'CUSTOM',
  RECORD: 'RECORD', // Special type set automatically for collection records,
  SCHOOL_LOOKUP: 'SCHOOL_LOOKUP',
  FILE_EDITOR: 'FILE_EDITOR',
  MESSAGE: 'MESSAGE'
}

// Categories of alert. Each category is shown in a separate section of the Actions list.
export const ALERT_TYPE = {
  CHECK_SRN: 'CHECK_SRN', // A special alert category which can only ever contain a single "Check for existing SRN" alert
  MISSING: 'MISSING', // Required field is empty
  INVALID: 'INVALID', // Field failed validation (prevents user from saving)
  CONFLICT: 'CONFLICT', // Field conflicts with ERN value
  ALERT: 'ALERT', // Custom alert criteria has returned a message to display (user can still save and also ignore alert)
  LINKING_FAIL: 'LINKING_FAIL' // A record could not be auto-linked, so should be linked manually. Only alerted if unlinked ERN records are present.
}

// ERN conflict resolution types:
export const RESOLUTION_TYPE = {
  AB_OES: 'AB_OES', // Resolved A/B conflict with OES value
  AB_ERN: 'AB_ERN', // Resolved A/B conflict with ERN value
  IGNORE: 'IGNORE', // Resovled optional alert by clicking 'Ignore'
  LIST: 'LIST', // Resolved list-type conflict by selecting items to keep
  MERGE: 'MERGE' // Resolved A/B conflict in TEXTAREA by manually merging OES and ERN values
}

// ERN conflict alerting modes for collections:
export const CONFLICT_ALERTING = {
  AB: 'AB', // a-b tests each field within the collection against the equivalent field in a linked ERN record
  AB_GROUP: 'AB_GROUP', // a-b tests each field within the collection against the equivalent field in a linked ERN record, and highlights entire record if a conflict is found
  LIST: 'LIST' // shows the whole collection as conflicting if any ERN record exists. The user resolves the conflict by selecting which OES and ERN records to keep.
}

// String matching modes
export const MATCH = {
  EXACT: 'EXACT', // Apply exact matching
  INSENSITIVE: 'INSENSITIVE', // Apply case insensitive matching
  FUZZY: 'FUZZY' // Apply fuzzy matching
}

// Values for address validationFlag
export const ADDRESS_VALIDATION = {
  VALIDATED: 'V',
  ACCEPTED: 'A',
  UNKNOWN: 'N'
}

// When resolving record linking, any record marked as 'new' by the user will be assigned this id as the linked value. It indicates that there is no linked ERN record, and that this linking is now resolved.
export const NEW_RECORD = -1

// Values for address validationFlag
export const USER_GROUP = {
  ERN_ADMIN: 'ERNADMIN',
  OES_SUPPORT: 'OESSupport',
  // When the parent make the changes, the user group will be parent
  PARENT: 'PARENT'
}

// Dataset names for API reference data
export const DATASET = {
  ABORIGINALITY: 'Aboriginality',
  ADMINISTERED_BY: 'AdministeredBy',
  CONTACT_TYPE: 'ContactType',
  COUNTRIES: 'Countries',
  DISABILITY: 'Disability',
  EMERGENCY_CONTACT_TYPE: 'EmergencyContactType',
  LANGUAGES: 'Languages',
  NON_ENGLISH_LANGUAGES: 'NON_ENGLISH_LANGUAGES', // Auto-generated from "Languages", not from api
  LEARNING_ADJUSTMENTS: 'LearningAdjustments',
  ALLERGY: 'MedicalConditionAllergies',
  MEDICAL_CONDITION: 'MedicationConditionName',
  MEDICATION_TYPE: 'MedicationType',
  OCCUPATION_GROUP: 'OccupationGroup',
  SCHOOL_EDUCATION: 'ParentSchoolEducation',
  TERTIARY_EDUCATION: 'ParentTertiaryEducation',
  PRESCHOOL_HOURS_TYPE: 'PreschoolHoursType',
  PRESCHOOL_TYPE: 'PreschoolType',
  RELATIONSHIP: 'Relationship',
  RESIDENCY_STATUS: 'ResidencyStatus',
  STATES: 'States',
  TITLE: 'Title',
  TEMP_VISA_CLASS: 'TempVisaClass',
  TEMP_VISA_SUBCLASS: 'TempVisaSubClass',
  VISA_SUBCLASS: 'VisaSubClass',
  TEMP_VISA_CLASS_AND_SUBCLASS_MAP: 'TempVisaClassAndSubClass',
  STATEMENT_OF_ACCOUNT: 'StatementOfAccountType'
}

// other ref data keys
export const REF_DATA = {
  SCHOLASTIC_YEAR: 'scholasticYear'
}

// Options for Yes/No radio buttons
export const YES_NO = [
  { text: 'Yes', value: true },
  { text: 'No', value: false }
]

// Values used to differentiate between different application streams
export const STREAM = {
  LOCAL_AREA: 'LA',
  OUT_OF_AREA: 'OOA',
  Y67T_PRIMARY: 'Y67T_PRIMARY',
  Y67T_SECONDARY: 'Y67T_SECONDARY',
  STUDENT_DETAIL_UPDATES: 'STUDENT_DETAIL_UPDATES',
  ARCHIVE: 'ARCHIVE',
  SELECTIVE_OFFERS: 'SELECTIVE_OFFERS'
}

export const NODE_ENVIRONMENTS = {
  LOCAL: 'LOCAL',
  DEV: 'DEV',
  TEST: 'TEST',
  PREPROD: 'PRE',
  PROD: 'PROD',
  TRAIN: 'TRAIN'
}

export const SUPPORTING_DOCS = [
  { category: 'studentIdentity', label: 'Proof of student’s identity' },
  {
    category: 'travel',
    label: "Student's passport, visa and travel documents"
  },
  { category: 'familyLaw', label: 'Family law or other relevant court orders' },
  {
    category: 'residentialAddress',
    label: 'Proof of student’s residential address'
  },
  {
    category: 'disability',
    label: 'Evidence of any disability or other support needs'
  },
  {
    category: 'immunisation',
    label: 'Student’s immunisation history statement'
  },
  {
    category: 'medical',
    label: "Student's medical/healthcare or emergency action plans"
  }
]

export const FILE_STATUSES = {
  SAFE: 'SAFE',
  INFECTED: 'INFECTED-REPLACED',
  TO_BE_SCANNED: 'UNSCANNED',
  MISSING: 'MISSING'
}

export const APPLICATION_TYPE = {
  LOCAL_AREA: 'LA',
  OUT_OF_AREA: 'OOA',
  Y67T: 'Y67T'
}

export const ENROLMENT_TYPE = {
  SHS: 'CORE_SHS',
  SCT: 'CORE_SCT'
}

export const APPLICATION_OFFER_TYPE_NAME = {
  LOCAL_AREA_OFFER: 'Local',
  OUT_OF_AREA_OFFER: 'Out-of-area offer',
  Y67T_OFFER: 'Year 6-7 offer',
  SHS_OFFER: 'Selective high school',
  SCT_OFFER: 'Collegiate transition'
}

export const SCHOOL_CAPS = {
  UNDER: 'Under',
  APPROACHING: 'Approaching',
  OVER: 'Over'
}

export const CATCHMENT_LEVEL = {
  INFANTS: 'infants',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  CENTRAL: 'central',
  SSP: 'SSP'
}

// Statement of accounts types:
export const STATEMENT_OF_ACCOUNT_TYPE = {
  FIR: 'FIR', // Financially responsible
  FII: 'FII', // Financially interested
  FNO: 'FNO' // Financially not interested
}

// address abbreviations
export const ADDRESS_MAPPINGS = [
  ['STREET', 'ST'],
  ['ROAD', 'RD'],
  ['CRESCENT', 'CR'],
  ['CLOSE', 'CL'],
  ['AVENUE', 'AV'],
  ['PLACE', 'PL'],
  ['DRIVE', 'DR'],
  ['GROVE', 'GR'],
  ['CIRCUIT', 'CCT'],
  ['HIGHWAY', 'HWY'],
  ['BOULEVARD', 'BVD'],
  ['PARADE', 'PDE'],
  ['COURT', 'CT'],
  ['GREEN', 'GRN'],
  ['PARKWAY', 'PWY'],
  ['ALLEY', 'ALLY'],
  ['APPROACH', 'APP'],
  ['ARCADE', 'ARC'],
  ['BOARDWALK', 'BWLK'],
  ['BREAK', 'BRK'],
  ['BYPASS', 'BYPA'],
  ['CHASE', 'CH'],
  ['CONCOURSE', 'CON'],
  ['CREST', 'CRST'],
  ['ENTRANCE', 'ENT'],
  ['ESPLANADE', 'ESP'],
  ['FREEWAY', 'FWY'],
  ['GLADE', 'GLDE'],
  ['GRANGE', 'GRA'],
  ['PASSAGE', 'PSGE'],
  ['PROMENADE', 'PROM'],
  ['QUAYS', 'QYS'],
  ['RETREAT', 'RTT'],
  ['RIDGE', 'RDGE'],
  ['SUBWAY', 'SBWY'],
  ['TERRACE', 'TCE'],
  ['TRACK', 'TRK'],
  ['TRAIL', 'TRL'],
  ['VISTA', 'VSTA'],
  ['WHARF', 'WHRF']
]

//Local or OoA students
export const STUDENT_TYPE = {
  LOCAL: 'Local students',
  OOA: 'Out-of-area students'
}

export const FILE_UPLOAD_ERROR_TYPES = {
  FILE_SIZE: 'EXCEED_MAX_FILE_SIZE',
  FILE_TYPE: 'INVALID_FILE_TYPE',
  FILE_NAME: 'INVALID_FILE_NAME',
  EXCEED_MAX_FILES: 'EXCEED_MAX_FILES'
}

export const GENDER_OPTIONS = [
  {
    label: 'Male',
    value: 'M'
  },
  {
    label: 'Female',
    value: 'F'
  }
]

export const YES_NO_OPTIONS = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
]

export const WHO_WILL_CONTACT_OPTIONS = [
  {
    label: 'School will contact parent',
    value: 'school'
  },
  {
    label: 'Parent will contact school',
    value: 'parent'
  }
]

export const APPLICATION_ORIGIN = [
  {
    label: 'Paper form',
    value: 'paper'
  },
  {
    label: 'Verbal advice',
    value: 'verbal'
  }
]

export const CONTACT_PHONE_TYPE = {
  PHMOB: 'Mobile',
  PHHOM: 'Home',
  PHWRK: 'Business/Work'
}

export const SCHOOL_SELECTIVENESS = {
  NOT: 'Not',
  PARTIALLY: 'Partially',
  FULLY: 'Fully'
}

export const MORE_INFO =
  '<p>Your cap status recommended by Scout may change over time based on your school’s current enrolment numbers or changes to your school’s permanent classroom count.</p><p>Where the new recommended Scout cap status is different to your configured Cap status, it is recommended that you consult with your principal to decide whether to update your ‘current status’ to match what is recommended by Scout.</p><p>It is important to accurately configure your school’s current cap status so that the correct messaging is shown to parents about the likelihood of an out-of-area place being offered at your school.</p><p>When there are concerns that the cap status that is recommended by Scout is incorrect, your principal should speak with the DEL to ensure that the cap information in the Scout report is correct.</p>'

export const DATE_OF_BIRTH_MINIMUM_YEAR = 20

export const FILE_UPLOAD_ERROR = {
  display: true,
  type: 'error',
  html: '<p class="mb-2">Files are currently being uploaded</p><p class="mb-2 font-weight-normal">Please wait until your current uploads are finished before moving to a different page.</p>'
}

export const FILE_REMOVE_ERROR = {
  display: true,
  type: 'error',
  html: '<p class="mb-2">Files are currently being removed</p><p class="mb-2 font-weight-normal">Please wait until files are removed before moving to a different page.</p>'
}

export const GENERIC_SERVER_ERROR =
  'A connection error has occurred. Please try again later. If this problem persists, please contact our support team.'

export const MERGE_LOCAL_APP_EVENTNAME = 'MergeLocalAppOnSendToHS'

export const ARCHIVE_TYPES = {
  ALL: {
    type: 'ALL',
    label: 'All application types',
    columnLabel: '',
    catchmentLevels: [
      CATCHMENT_LEVEL.INFANTS,
      CATCHMENT_LEVEL.PRIMARY,
      CATCHMENT_LEVEL.SECONDARY,
      CATCHMENT_LEVEL.CENTRAL
    ],
    statusChips: STATUS_CHIPS
  },
  CORE_SCT: {
    type: 'CORE_SCT',
    label: 'Collegiate transition enrolments',
    columnLabel: 'Collegiate transition',
    catchmentLevels: [CATCHMENT_LEVEL.SECONDARY, CATCHMENT_LEVEL.CENTRAL],
    statusChips: STATUS_CHIPS
  },
  CORE: {
    type: 'CORE',
    label: 'Local enrolments',
    columnLabel: 'Local enrolment',
    catchmentLevels: [
      CATCHMENT_LEVEL.INFANTS,
      CATCHMENT_LEVEL.PRIMARY,
      CATCHMENT_LEVEL.SECONDARY,
      CATCHMENT_LEVEL.CENTRAL
    ],
    statusChips: STATUS_CHIPS
  },
  OOA: {
    type: 'OOA',
    label: 'Out-of-area applications',
    columnLabel: 'Out-of-area application',
    catchmentLevels: [
      CATCHMENT_LEVEL.INFANTS,
      CATCHMENT_LEVEL.PRIMARY,
      CATCHMENT_LEVEL.SECONDARY,
      CATCHMENT_LEVEL.CENTRAL
    ],
    statusChips: OOA_STATUS_CHIPS
  },
  CORE_OOA: {
    type: 'CORE_OOA',
    label: 'Out-of-area enrolments',
    columnLabel: 'Out-of-area enrolment',
    catchmentLevels: [
      CATCHMENT_LEVEL.INFANTS,
      CATCHMENT_LEVEL.PRIMARY,
      CATCHMENT_LEVEL.SECONDARY,
      CATCHMENT_LEVEL.CENTRAL
    ],
    statusChips: STATUS_CHIPS
  },
  Y67T_EOIS: {
    type: 'Y67T_EOIS',
    label: 'Moving into Y7 EOIs',
    columnLabel: 'Moving into Y7 EOI',
    catchmentLevels: [
      CATCHMENT_LEVEL.PRIMARY,
      CATCHMENT_LEVEL.SECONDARY,
      CATCHMENT_LEVEL.CENTRAL
    ],
    statusChips: Y67T_STATUS_CHIPS
  },
  Y67T_PNRS: {
    type: 'Y67T_PNRS',
    label: 'Moving into Y7 PNRs',
    columnLabel: 'Moving into Y7 PNR',
    catchmentLevels: [
      CATCHMENT_LEVEL.PRIMARY,
      CATCHMENT_LEVEL.SECONDARY,
      CATCHMENT_LEVEL.CENTRAL
    ],
    statusChips: Y67T_STATUS_CHIPS
  },
  CORE_Y67T: {
    type: 'CORE_Y67T',
    label: 'Moving into Y7 enrolments',
    columnLabel: 'Moving into Y7 enrolment',
    catchmentLevels: [CATCHMENT_LEVEL.SECONDARY, CATCHMENT_LEVEL.CENTRAL],
    statusChips: STATUS_CHIPS
  },
  SHS: {
    type: 'SHS',
    label: 'Selective high school offers',
    columnLabel: 'Selective high school offer',
    catchmentLevels: [CATCHMENT_LEVEL.SECONDARY, CATCHMENT_LEVEL.CENTRAL],
    statusChips: SHS_APPLICATION_STATUS_CHIPS
  },
  CORE_SHS: {
    type: 'CORE_SHS',
    label: 'Selective high school enrolments',
    columnLabel: 'Selective high school enrolments',
    catchmentLevels: [CATCHMENT_LEVEL.SECONDARY, CATCHMENT_LEVEL.CENTRAL],
    statusChips: STATUS_CHIPS
  },
  SRESEE: {
    type: 'SRESEE',
    label: 'Student detail updates',
    columnLabel: 'Student detail update',
    catchmentLevels: [
      CATCHMENT_LEVEL.INFANTS,
      CATCHMENT_LEVEL.PRIMARY,
      CATCHMENT_LEVEL.SECONDARY,
      CATCHMENT_LEVEL.CENTRAL
    ],
    statusChips: SDU_STATUS_CHIPS
  }
}

export const SCHOOL_SUBTYPE = [
  'Kindergarten to Year 12',
  // Keep the old one in case some schools still use this.
  'Kinder to Year 12',
  'Year 7 to Year 10',
  'Year 7 to Year 12',
  'Year 7 to Year 9'
]

export const OOA_CRITERIA = {
  LAW: 'LAW',
  SIB: 'SIB',
  MED: 'MED'
}
