<template>
  <div>
    <div class="mx-6 mx-md-12 pt-4">
      <div class="d-flex align-start">
        <div
          class="app-settings__circle-icon d-flex align-center justify-center"
        >
          <v-icon size="30">bar_chart</v-icon>
        </div>
        <div class="ml-3 mt-3">
          <h3 class="app-settings-heading">School cap</h3>
        </div>
      </div>
      <div
        v-if="getCapacityStatus !== 'NA'"
        class="d-flex align-start pt-3"
        :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
      >
        <AdsSelect
          @change="$emit('updatedCapacity', $event)"
          :value="capacity"
          :items="validCapacities"
          item-text="value"
          item-value="code"
          label="Current status"
          placeholder="Current status"
          :outlined="true"
          :readonly="isSuperAdmin"
          class="app-settings__school-caps-input pt-4 mr-12"
          aria-labelledby="capacityTitle"
          persistent-placeholder
        />
      </div>
      <div v-if="capacity">
        <p class="capacity-status">
          Status indicated through Scout
          <span v-if="getCapacityStatus === 'NA'">:</span>
          <span
            v-else
            class="pips mx-4"
            :class="getCapacityStatus.toLowerCase()"
          >
            <span class="pip ml-1"></span>
            <span class="pip ml-1"></span>
            <span class="pip ml-1"></span>
            <span class="pip ml-1"></span>
          </span>

          <span
            class="app-settings__capacity-text"
            data-testid="app-settings__capacity-text"
          >
            {{ getCapacityStatus.toUpperCase() }}
          </span>
        </p>
      </div>
    </div>
    <Alert
      v-if="showScoutNotification"
      text="NOTE: Your Scout recommendation does not match your current school cap status"
      subtext="Please ensure your cap status accurately represents your school's enrolment cap."
      type="warning"
      action-text="More Information"
      :action-handler="openMoreInfoModal"
      class="schoolSettingsAlert"
      in-page
    />
    <Alert
      v-if="showCapStatusNotification"
      text="Please check with the principal"
      subtext="Please ensure your principal is aware and approves any changes to the cap details in Online Enrolment."
      type="warning"
      class="schoolSettingsAlert"
      in-page
    />
    <v-divider />
    <AdsDialog
      title="About your cap status"
      max-width="582px"
      :open-dialog="displayMoreInfoDialog"
      display-close-btn
      :message="message"
      @close="displayMoreInfoDialog = false"
    />
  </div>
</template>

<script>
import { AdsSelect, Alert, Dialog } from '@nswdoe/doe-ui-core'
import settingsUtils from '@/helpers/settingsUtils'
import { MORE_INFO } from '@/constants'

export default {
  name: 'AppSettingsSchoolCap',
  components: { AdsSelect, Alert, AdsDialog: Dialog },
  data() {
    return {
      updatedValue: '',
      displayMoreInfoDialog: false,
      message: MORE_INFO
    }
  },
  props: {
    capacity: {
      type: String,
      default: null
    },
    capacities: {
      type: Array,
      default: () => []
    },
    scoutCapacity: {
      type: String,
      default: null
    }
  },
  computed: {
    isSuperAdmin() {
      return settingsUtils.isSuperAdmin()
    },
    validCapacities() {
      return this.capacities.filter((capacity) => capacity.value !== 'NA')
    },
    getCapacityStatus() {
      const result = this.capacities.find((obj) => {
        return obj.code === this.scoutCapacity
      })
      return result ? result.value : ''
    },
    showScoutNotification() {
      return this.scoutCapacity !== 'NA' && this.capacity !== this.scoutCapacity
    },
    showCapStatusNotification() {
      return this.capacity !== this.updatedValue
    }
  },
  methods: {
    openMoreInfoModal() {
      this.displayMoreInfoDialog = true
    }
  },
  mounted() {
    this.updatedValue = this.capacity
  }
}
</script>

<style lang="scss" scope>
.app-settings__school-caps-input {
  width: 280px;
  max-width: 415px;
  .v-label {
    font-weight: 700;
    color: $ads-navy;
  }
}

.alert--clickable-action {
  text-transform: none !important;
  border: none !important;
  padding: 0 !important;
}

.capacity-status {
  .pips {
    display: inline-block;
    .pip {
      display: inherit;
      width: 14px;
      height: 14px;
      border-radius: 7px;
      background-color: $ads-light-20;
    }
  }
  .pips .pip {
    &:nth-child(1),
    &:nth-child(2) {
      background-color: $color-primary-lighten-1;
    }
  }
  .pips.approaching,
  .pips.over {
    .pip {
      &:nth-child(3) {
        background-color: $color-primary-lighten-1;
      }
    }
  }
  .pips.over {
    .pip {
      &:nth-child(4) {
        background-color: $color-primary-lighten-1;
      }
    }
  }
  .app-settings__capacity-text {
    font-weight: 700;
  }
}

.app-settings__capture-siblings {
  .v-label {
    color: $grey-dark;
  }
}

.app-settings__scout-notif {
  background: $ads-light-10;
  padding: 16px;
  border-radius: 4px;
  width: fit-content;
}

.schoolSettingsAlert {
  max-width: 806px;
  margin-left: 24px;

  @media (min-width: 769px) {
    margin-left: 48px;
  }
}
</style>
