<template>
  <v-dialog max-width="630" v-bind="$attrs" v-on="$listeners">
    <v-form ref="changeStatusOoaForm" v-model="ooaStatusChangeFormValid">
      <v-card class="change-status-modal">
        <v-btn
          class="close-button"
          icon
          aria-label="close"
          @click="closeModal"
          @keydown.esc="closeModal"
        >
          <v-icon> close </v-icon>
        </v-btn>
        <div class="content">
          <h2 class="heading mr-6">
            <span v-if="changeStatus === OOA_STATUS.OFFERED"
              >Make an offer of enrolment to {{ studentName }}?</span
            >
            <span v-else-if="changeStatus === OOA_STATUS.DECLINED"
              ><span class="redText">Decline</span> out-of-area application for
              {{ studentName }}?</span
            >
            <span v-else-if="changeStatus === OOA_STATUS.WITHDRAWN"
              ><span class="redText">Withdraw</span> out-of-area application for
              {{ studentName }}?</span
            >
            <span v-else
              >Change the status of this application for
              {{ studentName }}?</span
            >
          </h2>
          <div class="mt-6" v-if="!changeStatus">
            <AdsSelect
              v-model="status"
              :items="items"
              class="mb-0"
              outlined
              :rules="[(val) => val != null || 'Required.']"
              label="Change status to"
              placeholder="Please select..."
              @change="resetDefaultSendEmailFlag"
              persistent-placeholder
              hint="Required."
              persistent-hint
            />
          </div>
          <div v-if="!!changeStatus" class="mt-3 mb-3">
            The status of the application will be set to
            <strong>"{{ changeStatus }}"</strong>.
          </div>
          <div class="approvalAlert mt-3 mb-3" v-if="isOverCap && isOffered">
            <v-icon size="22" class="pr-3 alertIcon">mdi-alert-outline</v-icon>
            <strong>Note: </strong>DEL approval is required before offering a
            place as your school is currently over cap.
          </div>
          <v-scroll-y-transition v-if="status">
            <div class="email-checkbox-container px-6 py-5 my-6">
              <div
                v-if="checkboxNotRequired"
                class="mb-4"
                data-testid="sendEmailText"
              >
                <div v-if="status === OOA_STATUS.WITHDRAWN">
                  <p>
                    Please ensure you have spoken with the parent/carer(s)
                    before withdrawing.
                  </p>
                  Parent/carer <strong>{{ parentCarerName }}</strong> will be
                  emailed with a confirmation that their application has been
                  withdrawn.
                </div>
                <div v-else-if="status === OOA_STATUS.NOT_ACCEPTED">
                  <p>
                    Parent/carer <strong>{{ parentCarerName }}</strong> will be
                    emailed with a confirmation that their application has been
                    withdrawn.
                  </p>
                </div>
                <div v-else>
                  <p>
                    Parent/carer <strong>{{ parentCarerName }}</strong> will be
                    emailed.
                  </p>
                </div>
              </div>
              <OesCheckbox
                v-model="sendEmail"
                v-if="!checkboxNotRequired"
                data-testid="sendEmailCheckbox"
              >
                <template #label>
                  <div class="email-checkbox-label">
                    <div v-if="isOffering">
                      Email parent/carer
                      <strong>{{ parentCarerName }}</strong> instructions for
                      accepting the offer and proceeding with a full enrolment
                      application.
                    </div>
                    <div v-else-if="status === OOA_STATUS.OFFER_EXPIRED">
                      Email parent/carer
                      <strong>{{ parentCarerName }}</strong> to confirm their
                      offer has expired
                    </div>
                    <div
                      v-else-if="
                        !!changeStatus && status === OOA_STATUS.DECLINED
                      "
                    >
                      Email parent/carer
                      <strong>{{ parentCarerName }}</strong> to advise the
                      application has been declined.
                    </div>
                    <div v-else-if="status === OOA_STATUS.DECLINED">
                      Email parent/carer
                      <strong>{{ parentCarerName }}</strong> to confirm their
                      offer of enrolment has now been declined by the school
                    </div>
                    <div v-else-if="status === OOA_STATUS.DECLINED_POST_APPEAL">
                      Email parent/carer
                      <strong>{{ parentCarerName }}</strong> to confirm their
                      appeal has been unsuccessful
                    </div>
                    <div v-else>
                      Email parent/carer
                      <strong>{{ parentCarerName }}</strong> will be emailed
                    </div>
                  </div>
                </template>
              </OesCheckbox>
              <div :class="{ 'ml-8': !checkboxNotRequired }">
                <a :href="emailTemplateUri" target="_blank">
                  <strong>View the email template</strong>
                </a>
              </div>
            </div>
          </v-scroll-y-transition>
          <v-divider class="mt-5 mb-7" />
          <div data-testid="capture-criteria" v-if="isOffering">
            <p class="my-7">
              Which criteria supported you to make your decision about this
              out-of-area application?
            </p>

            <v-select
              v-model="considerations.primary"
              :items="primaryCriteriaList"
              item-text="value"
              item-value="code"
              label="Primary criteria *"
              placeholder="Select criteria..."
              :outlined="true"
              class="mt-3"
              persistent-placeholder
              :rules="[(val) => !!val || 'Required.']"
              max-width="556px"
              hint="Required."
              persistent-hint
              :menu-props="{
                contentClass: 'v-select--wrap-list-items'
              }"
            >
              <template #item="data">
                <v-list-item-title
                  :class="`${data.item.isBold ? 'font-weight-bold' : ''}`"
                >
                  {{ data.item.value }}
                </v-list-item-title>
              </template>
            </v-select>

            <v-select
              v-model="considerations.secondary"
              :items="secondaryCriteriaList"
              item-text="value"
              item-value="code"
              label="Secondary criteria"
              placeholder="Select criteria..."
              outlined
              class="mt-3"
              persistent-placeholder
              :disabled="!considerations.primary"
              hint="Select a primary criteria first."
              persistent-hint
              :menu-props="{
                contentClass: 'v-select--wrap-list-items'
              }"
            >
              <template #item="data">
                <v-list-item-title
                  :class="`${data.item.isBold ? 'font-weight-bold' : ''}`"
                >
                  {{ data.item.value }}
                </v-list-item-title>
              </template>
            </v-select>

            <v-select
              v-model="considerations.tertiary"
              :items="tertiaryCriteriaList"
              item-text="value"
              item-value="code"
              label="Tertiary criteria"
              placeholder="Select criteria..."
              :outlined="true"
              class="mt-3"
              persistent-placeholder
              :disabled="!considerations.secondary"
              hint="Select a secondary criteria first."
              persistent-hint
              :menu-props="{
                contentClass: 'v-select--wrap-list-items'
              }"
            >
              <template #item="data">
                <v-list-item-title
                  :class="`${data.item.isBold ? 'font-weight-bold' : ''}`"
                >
                  {{ data.item.value }}
                </v-list-item-title>
              </template>
            </v-select>
          </div>

          <div data-testid="capture-reason" v-if="isDeclining">
            <p class="my-7">Why was this out-of-area application declined?</p>

            <v-select
              v-model="declinedReason"
              :items="activeDeclinedReasons"
              item-text="value"
              item-value="code"
              label="Reason *"
              placeholder="Select reason..."
              :outlined="true"
              class="mt-3"
              persistent-placeholder
              :rules="[(val) => !!val || 'Required.']"
              max-width="556px"
              hint="Required."
              persistent-hint
              :menu-props="{
                contentClass: 'v-select--wrap-list-items'
              }"
            />
          </div>
          <div>
            <AdsTextArea
              class="mt-3"
              ref="comment"
              v-model="comment"
              :label="`Comments (school use only${
                hasSelectedOtherConsiderations ? ') *' : ' - optional)'
              }`"
              placeholder=" "
              rows="5"
              :counter="maxCommentLength"
              :maxlength="maxCommentLength"
              persistent-placeholder
              :rules="
                hasSelectedOtherConsiderations
                  ? [(val) => !!val || 'Required.']
                  : []
              "
            />
          </div>
          <SupportingDocumentUpload
            v-model="files"
            ref="supportingDocumentUpload"
            :is-busy.sync="isFileOperationInProgress"
          />
        </div>
        <div class="d-flex justify-end pt-3">
          <v-btn
            :label="btnLabel"
            class="px-6 py-4 confirmButton"
            @click="confirmChange"
            :color="confirmButtonColour"
            min-width="50%"
            :disabled="isFileOperationInProgress || !ooaStatusChangeFormValid"
            data-testid="confirmButton"
          >
            {{ btnLabel }}
          </v-btn>
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import { AdsSelect, AdsTextArea } from '@nswdoe/doe-ui-core'
import { OOA_STATUS, OOA_OFFERED_STATUSES, OOA_CRITERIA } from '@/constants'
import OesCheckbox from '@/components/app/OesCheckbox'
import SupportingDocumentUpload from '@/components/app/SupportingDocumentUpload'
import settingsUtils from '@/helpers/settingsUtils'
import { mapGetters } from 'vuex'

const optionsByCurrentStatus = {
  ...OOA_OFFERED_STATUSES.reduce((acc, s) => {
    acc[s] = {
      items: [
        OOA_STATUS.NOT_ACCEPTED,
        OOA_STATUS.OFFER_EXPIRED,
        OOA_STATUS.DECLINED
      ]
    }
    return acc
  }, {}),
  [OOA_STATUS.DECLINED]: {
    items: [
      OOA_STATUS.OFFER_POST_APPEAL,
      OOA_STATUS.OFFER_OTHER,
      OOA_STATUS.DECLINED_POST_APPEAL
    ]
  },
  [OOA_STATUS.DECLINED_POST_APPEAL]: {
    items: [
      OOA_STATUS.OFFER_POST_APPEAL,
      OOA_STATUS.OFFER_OTHER,
      OOA_STATUS.DECLINED_POST_APPEAL
    ]
  }
}

export default {
  name: 'ChangeStatusModal',
  mixins: [fieldHelperMixin],
  components: {
    AdsSelect,
    AdsTextArea,
    OesCheckbox,
    SupportingDocumentUpload
  },
  props: {
    studentName: {
      type: String
    },
    parentCarerName: {
      type: String,
      required: true
    },
    currentStatus: {
      type: String,
      required: true
    },
    changeStatus: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      comment: '',
      status: this.changeStatus,
      sendEmail: this.getDefaultSendEmailFlag(),
      maxCommentLength: 200,
      files: [],
      isFileOperationInProgress: false,
      ooaStatusChangeFormValid: true,
      considerations: {
        primary: null,
        secondary: null,
        tertiary: null
      },
      declinedReason: null,
      OOA_STATUS
    }
  },
  computed: {
    ...mapGetters(['schoolOoaSettings', 'activeDeclinedReasons']),
    options() {
      return optionsByCurrentStatus[this.currentStatus]
    },
    items() {
      return this.options ? this.options.items : []
    },
    checkboxNotRequired() {
      return (
        this.status === OOA_STATUS.NOT_ACCEPTED ||
        this.status === OOA_STATUS.WITHDRAWN
      )
    },
    isOffered() {
      return this.status === OOA_STATUS.OFFERED
    },
    isOffering() {
      return (
        this.status === OOA_STATUS.OFFERED ||
        this.status === OOA_STATUS.OFFER_POST_APPEAL ||
        this.status === OOA_STATUS.OFFER_OTHER
      )
    },
    isDeclining() {
      return this.status === OOA_STATUS.DECLINED
    },
    btnLabel() {
      let label = ''
      if (this.changeStatus === OOA_STATUS.OFFERED) {
        label = 'Make offer of enrolment'
      } else if (this.changeStatus === OOA_STATUS.DECLINED) {
        label = 'Confirm decline of enrolment'
      } else if (this.changeStatus === OOA_STATUS.WITHDRAWN) {
        label = 'Confirm application withdrawal'
      } else {
        label = `Confirm status change`
      }
      return label
    },
    confirmButtonColour() {
      return OOA_OFFERED_STATUSES.includes(this.status)
        ? 'green darken-1'
        : 'red'
    },
    emailTemplateUri() {
      return this.$store.getters.emailTemplateLink
    },
    schoolSettings() {
      return this.schoolOoaSettings(this.$store.state.application.schoolCode)
    },
    isOverCap() {
      return settingsUtils.isOverCap(this.schoolSettings?.capacity)
    },
    otherConsiderations() {
      return this.schoolSettings?.enableOtherCriteria
    },
    hasSelectedOtherConsiderations() {
      return Object.values(this.considerations).includes('OTH')
    },
    parentSelectedCriteria() {
      const applicationCriteria =
        this.application?.criteria.map((c) => c.code) || []
      // Mapping MED to LAW and siblings to SIB start
      if (this.application?.siblings?.length > 0) {
        applicationCriteria.push(OOA_CRITERIA.SIB)
      }
      const parentSelectedCriteria = applicationCriteria?.map((code) =>
        code == OOA_CRITERIA.MED ? OOA_CRITERIA.LAW : code
      )
      // Mapping MED to LAW and siblings to SIB end
      return parentSelectedCriteria
    },
    schoolCriteriaList() {
      const schoolCriteria = this.schoolSettings?.criteria.map((c) => ({
        code: c.code,
        value: c.value,
        disabled: false
      }))
      const hasOtherConsiderations = !!this.application?.otherConsiderations
      const criteriaList = []
      criteriaList.push({
        code: 'CSBPC',
        header: 'Criteria selected by the parent/carer'
      })
      if (this.parentSelectedCriteria.length > 0) {
        criteriaList.push(
          ...schoolCriteria
            .filter((d) => this.parentSelectedCriteria.includes(d.code))
            .map((finalResult) => {
              return {
                ...finalResult,
                isBold: true
              }
            })
        )
      }
      if (hasOtherConsiderations && this.otherConsiderations) {
        criteriaList.push({
          code: 'OTH',
          value: 'Other considerations',
          disabled: false,
          isBold: true
        })
      }
      if (
        this.parentSelectedCriteria.length === 0 &&
        !(hasOtherConsiderations && this.otherConsiderations)
      ) {
        criteriaList.push({
          code: 'NONE',
          value: '(None)',
          disabled: true
        })
      }
      criteriaList.push({
        code: 'AOC',
        header: 'All other criteria'
      })
      criteriaList.push(
        ...schoolCriteria.filter(
          (d) => !this.parentSelectedCriteria.includes(d.code)
        )
      )
      criteriaList.push({
        code: 'SBC',
        value: 'School is under buffer or cap and has capacity',
        disabled: false
      })
      if (!hasOtherConsiderations && this.otherConsiderations) {
        criteriaList.push({
          code: 'OTH',
          value: 'Other considerations',
          disabled: false
        })
      }
      return criteriaList
    },
    primaryCriteriaList() {
      return this.schoolCriteriaList.map((c) => ({
        ...c,
        disabled:
          c.disabled ||
          c.code === this.considerations.secondary ||
          c.code === this.considerations.tertiary
      }))
    },
    secondaryCriteriaList() {
      return this.schoolCriteriaList.map((c) => ({
        ...c,
        disabled:
          c.disabled ||
          c.code === this.considerations.primary ||
          c.code === this.considerations.tertiary
      }))
    },
    tertiaryCriteriaList() {
      return this.schoolCriteriaList.map((c) => ({
        ...c,
        disabled:
          c.disabled ||
          c.code === this.considerations.primary ||
          c.code === this.considerations.secondary
      }))
    }
  },
  methods: {
    confirmChange() {
      const params = {
        status: this.status,
        sendEmail: this.sendEmail,
        comment: this.comment.trim(),
        documents: this.files
      }
      if (!this.changeStatus) {
        params.changeOutcome = true
      }
      if (this.isOffering) {
        params.considerations = this.considerations
      }
      if (this.isDeclining) {
        params.declinedReason = this.declinedReason
      }
      this.$emit('confirm', params)
      this.$emit('input', false)
      this.$eventHub.$emit('handleTabChange', 0)
    },
    getDefaultSendEmailFlag() {
      return (
        this.changeStatus === OOA_STATUS.OFFERED ||
        this.status === OOA_STATUS.NOT_ACCEPTED ||
        (this.currentStatus === OOA_STATUS.DECLINED &&
          this.status !== OOA_STATUS.DECLINED_POST_APPEAL)
      )
    },
    resetDefaultSendEmailFlag() {
      this.sendEmail = this.getDefaultSendEmailFlag()
    },
    closeModal() {
      if (this.files) {
        this.$refs.supportingDocumentUpload.deleteAll()
      }
      this.$emit('input', false)
    }
  }
}
</script>

<style scoped lang="scss">
.change-status-modal {
  padding: 2rem;
  .v-btn {
    &.red:focus {
      box-shadow: 0 0 0 4px $color-red-border;
    }
    &.green:focus {
      box-shadow: 0 0 0 4px #ccefce;
    }
    &.confirmButton {
      color: #ffffff;
    }
  }
  .v-text-field.v-textarea {
    margin-left: 0;
  }
}

.heading {
  color: $color-primary;
  font-weight: 700;
}

.body {
  margin: 3.5rem 0;
}

.close-button {
  position: absolute;
  top: 0.15rem;
  right: 0.15rem;
  border: none;
  &:focus {
    box-shadow: 0 0 0 2px $color-primary;
  }
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ) {
  background-color: $ads-dark-60 !important;
  color: $ads-white !important;
}
.approvalAlert {
  line-height: 24px;
  .alertIcon {
    color: $color-warning-dark;
  }
}
::v-deep .redText {
  color: #bb1237 !important;
}
::v-deep .email-checkbox-container {
  background-color: $ads-light-10;
  border-radius: 4px;
  .theme--light.v-label {
    color: $ads-dark;
  }
  .v-input__slot {
    align-items: start;
  }
  .v-input--selection-controls__input {
    height: 22px;
  }
  .v-input--selection-controls.v-input {
    margin-top: 0;
  }
}
::v-deep {
  .v-select:not(.ads-select) {
    & .v-input__control input[readonly] {
      position: unset !important;
      top: unset !important;
    }
  }
}
::v-deep .theme--light.v-input--is-disabled input::placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
}
</style>
<style type="text/css">
.v-select--wrap-list-items {
  max-height: none !important;
  .v-list-item__title {
    white-space: normal;
    max-width: 525px;
    margin: 16px 0;
  }
  .v-list-item__content {
    padding: 0;
  }
}
</style>
