<template>
  <div>
    <NumbersSkeleton
      v-if="isLoadingBar"
      title="Numbers tracker"
      data-testid="numbersSkeleton"
    />
    <AdsChart
      data-testid="appChart"
      v-if="targetAndData"
      title="Numbers tracker"
      type="bar"
      :series="barData"
      :seg-colours="barColours"
      :xlabel="false"
      :seg-label-colours="dataLabelColour"
      :target-text="`Forecast: ${targetText}`"
      :target-position="numbersTarget"
      :inverse-legend="true"
      :key="componentKey"
      :target-offset-x="targetOffsetX"
      :label-offset-x="labelOffsetX"
    />
    <NoEOI
      v-if="noTarget"
      title="Numbers tracker"
      msg="The number tracker helps you visualise the status of student numbers
as they progress through the year. Set your forecast for year 7 enrolments to begin."
      icon="mdi-chart-box-outline"
      :show-target-btn="true"
      @open="$emit('open')"
      data-testid="noTarget"
    />
    <NoEOI
      v-if="noTargetOrData"
      title="Numbers Tracker"
      subtitle="No EOIs have been submitted yet for this year."
      msg="Once you start receiving EOIs the numbers tracker helps you visualise the
status of student numbers as they progress through the year."
      icon="mdi-chart-line-variant"
      data-testid="noData"
    />
  </div>
</template>

<script>
import { AdsChart, ADS_Colors } from '@nswdoe/doe-ui-core'
import NoEOI from './NoEOI.vue'
import NumbersSkeleton from './NumbersSkeleton.vue'
import { mapGetters } from 'vuex'
import { ADS_BLUE_02 } from '@/constants'

export default {
  name: 'NumbersTracker',
  components: { AdsChart, NoEOI, NumbersSkeleton },
  computed: {
    ...mapGetters([
      'selectedSchool',
      'numbersTarget',
      'eoiApplications',
      'targetText',
      'numbersTracker',
      'remainingSpots',
      'barData',
      'isLoadingBar'
    ]),
    targetAndData() {
      return (
        this.numbersTarget !== null &&
        !this.isLoadingBar &&
        this.eoiApplications.length > 0
      )
    },
    noTarget() {
      return !this.isLoadingBar && this.numbersTarget === null
    },
    noTargetOrData() {
      return (
        this.eoiApplications.length === 0 &&
        this.numbersTarget !== null &&
        !this.isLoadingBar
      )
    }
  },
  data() {
    return {
      componentKey: 0,
      dataLabelColour: ['#000', '#fff', '#fff'],
      barColours: [ADS_Colors.Light_20, ADS_Colors.Navy, ADS_BLUE_02],
      targetOffsetX: 0,
      labelOffsetX: 5
    }
  },
  methods: {
    forceRerender() {
      this.componentKey += 1
    },
    setTargetOffset() {
      if (
        this.numbersTracker.submitted / 2 === this.targetText ||
        this.numbersTracker.offered / 2 + this.numbersTracker.submitted ===
          this.targetText
      ) {
        return (this.labelOffsetX = -15), (this.targetOffsetX = -20)
      } else {
        return (this.labelOffsetX = 5), (this.targetOffsetX = 0)
      }
    }
  },
  created() {
    this.setTargetOffset()
    this.$store.dispatch('getNumbersTracker')
    this.forceRerender()
  },
  watch: {
    numbersTarget() {
      this.setTargetOffset()
      this.forceRerender()
    },
    numbersTracker() {
      this.forceRerender()
    },
    selectedSchool() {
      this.$store.dispatch('setIsLoadingNumberTracker', true)
      this.setTargetOffset()
      this.$store.dispatch('getNumbersTracker')
    }
  }
}
</script>
